import { Controller, CSSStyles, StyleHandler } from "Bent";
import ToastComponent from "lib/components/ToastComponent";
import DeleteProfileCommunication from "lib/communication/DeleteProfileCommunication";
import DeleteProfileForm from "./DeleteProfileForm";
import DeleteProfileView from "./DeleteProfileView";


export default class DeleteProfileController extends Controller<DeleteProfileView, DeleteProfileForm> {

	public constructor() {
		super(null, DeleteProfileView, DeleteProfileForm);
		this.active = true;
		this.doActivate();

		this.listen(this.view.form.deleteButton.onActivate, this.doDelete);
	}


	public refresh() {
		super.refresh();

	}


	private doDelete(){
		let email = this.view.form.email.value.content;
		let phoneNumber = this.view.form.phoneNumber.value.content;
		let pinCode = this.view.form.pinCode.value.content;

		if(!email || !phoneNumber || !pinCode ){
			new ToastComponent(this.view.form,'Please fill out the form','');
			return;
		}

		let communication = new DeleteProfileCommunication(email, phoneNumber, pinCode);
		this.listen(communication.onSucces, this.doDeleteSuccess);
		this.listen(communication.onError, this.doDeleteError);

	}

	public doDeleteSuccess(evt){
		new ToastComponent(this.view.form,evt.response.result,'');
	}

	public doDeleteError(evt){
		new ToastComponent(this.view.form,'An unexpected error occurred','');
	}

}