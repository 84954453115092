import { ComponentStyle } from "Bent";
import FadeInOutForm from "./FadeInOutForm";

export default class AdminPageForm extends FadeInOutForm {

	public static readonly style: ComponentStyle = {
		default: {
			backgroundColor: '#fcfcfc',
		}
	}


}