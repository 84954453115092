export default class Vector {

	public x: number;

	public y: number;


	public get length(): number {
		return Math.sqrt(this.x * this.x + this.y * this.y);
	}


	public constructor(x?: number, y?: number) {
		this.setValues(x ? x : 0, y ? y : 0);

	}


	public setValues(x: number, y: number) {
		this.x = x;
		this.y = y;
	}


	public static add(a: Vector, b: Vector): Vector {
		return new Vector(a.x + b.x, a.y + b.y);

	}


	public static sub(a: Vector, b: Vector): Vector {
		return new Vector(a.x - b.x, a.y - b.y);

	}


	public static mul(a: Vector, f: number): Vector {
		return new Vector(a.x * f, a.y * f);

	}


	public static div(a: Vector, f: number): Vector {
		return new Vector(a.x / f, a.y / f);

	}


	public static distance(a: Vector, b: Vector): number {
		return Vector.sub(a, b).length;
	}


}