import { Ability, AbilityEventHandler, DOMListener } from "Bent";


export default class StickyTableHeaderRowAblility extends Ability {


	private _scrollHandler: DOMListener;


	public constructor(element: HTMLElement) {
		super(element);
		this._scrollHandler = this.createDOMListener("scroll", this.doScroll);

	}


	protected doEnable() {
		super.doEnable();
		this._scrollHandler.enabled = true;

	}



	protected doScroll(e: Event) {
		let table = this.firstChildByTagName(this.element, 'table');
		let headerRow = this.firstChildByTagName(table, 'tr') as HTMLElement;
		headerRow.style.transform = 'translateY(' + this.element.scrollTop + 'px)';

	}


	private firstChildByTagName(e: Element, tagName: string): Element {
		return !e ? null : (e.tagName.toLowerCase() == tagName.toLowerCase() ? e : this.firstChildByTagName(e.firstElementChild, tagName));

	}


}