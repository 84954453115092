export default class DateUtils {

	public static addDays(d: Date, days: number): Date {
		d.setDate(d.getDate() + days);
		return d;

	}


	public static addMonth(d: Date, months: number): Date {
		d.setMonth(d.getMonth() + months);
		return d;

	}


	public static clone(date: Date): Date {
		return new Date(date.getTime());

	}


	public static createDateFromMySQLDate(mySQLDateStr: string): Date {
		if (mySQLDateStr.length == 10) {
			return new Date(
				parseInt(mySQLDateStr.substring(0, 4)), // year
				parseInt(mySQLDateStr.substring(5, 7)) - 1, // month - zero based in javascript
				parseInt(mySQLDateStr.substring(8, 11)), // date
			);
		} else {
			return new Date(mySQLDateStr.substring(0, 10) + 'T' + mySQLDateStr.substring(11, 19) + '.000Z');
		}

	}

	public static CreateISODate(d: Date): string {
		function pad(n) { return n < 10 ? '0' + n : n }
		return d.getFullYear() + '-' + pad(d.getMonth() + 1) + '-' + pad(d.getDate());

	}

	public static formatPeopletrustDate(date: Date): string {
		let m = (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1));
		let d = (date.getDate() > 9 ? date.getDate() : "0" + (date.getDate()));
		return d + "." + m + "." + date.getFullYear();

	}


	public static formatPeopletrustTime(date: Date): string {
		let h = (date.getHours() > 9 ? date.getHours() : "0" + (date.getHours()));
		let m = (date.getMinutes() > 9 ? date.getMinutes() : "0" + (date.getMinutes()));
		return h + ":" + m;

	}


	public static dayName(dayIndex: number, short: boolean = false): string { // Monday = 0
		const DAY_NAMES_SHORT = ['Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør', 'Søn'];
		const DAY_NAMES_LONG = ['Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag', 'Søndag'];
		return short ? DAY_NAMES_SHORT[dayIndex] : DAY_NAMES_LONG[dayIndex];

	}


	public static monthName(monthIndex: number): string {
		const MONTH_NAMES = ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December'];
		return MONTH_NAMES[monthIndex];

	}


	public static timeFloat(d: Date): number {
		return d.getHours() + (d.getMinutes() / 60.0) + (d.getSeconds() / 3600.0); // 07:30:00 = 7.5

	}


	public static toPrevMonday(d: Date): Date {
		return DateUtils.addDays(d, -[6, 0 /* If already Monday, do nothing */, 1, 2, 3, 4, 5][d.getDay()]);

	}


	public static weekNumber(d: Date) {	// https://stackoverflow.com/questions/6117814/get-week-of-year-in-javascript-like-in-php
		d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
		d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
		let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
		// @ts-ignore
		let weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
		return weekNo;
		// return [d.getUTCFullYear(), weekNo];

	}

	public static isToday(date: Date) {
		return DateUtils.compareDate(date, new Date()) == 0;

	}


	public static compareDate(d1: Date, d2: Date): number {
		return (d1.getFullYear() * 10000 + d1.getMonth() * 100 + d1.getDate()) - (d2.getFullYear() * 10000 + d2.getMonth() * 100 + d2.getDate());

	}


}