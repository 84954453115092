import ListCommunication from "./staffz/ListCommunication";

export default class ProfileListCommunication extends ListCommunication {

	public constructor() {
		super();
		this.request = { contract: 'ProfileList' }

	}


}