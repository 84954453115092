import { ComponentStyle } from "Bent";
import { IHint } from "lib/abilities/HintAbility";
import HtmlContentAbility from "lib/abilities/HtmlContentAbility";
import TextComponent from "lib/components/TextComponent";
import DialogForm from "lib/forms/DialogForm";

export default class HintControl extends TextComponent implements IHint {

	public static readonly style: ComponentStyle = {
		default: {
			position: 'relative',
			padding: '2px 4px',
			display: 'inline-block',
			minHeight: '12px',
			minWidth: '24px',
			maxWidth: '350px',
			backgroundColor: '#FFFFD0',
			border: '1px solid black',
			borderRadius: '2px',
			fontWeight: '',
		}
	}



	private _id: string;
	public get id(): string {
		return this._id;
	}
	public set id(v: string) {
		this._id = v;
	}


	private _htmlContentAbility: HtmlContentAbility;
	public get htmlText(): string {
		return this._htmlContentAbility ? this._htmlContentAbility.htmlContent : '';
	}
	public set htmlText(v: string) {
		if (!this._htmlContentAbility) {
			this._htmlContentAbility = this.createAbility(HtmlContentAbility);
		}
		this._htmlContentAbility.htmlContent = v;
	}


	constructor(id: string, x: number, y: number) {
		super(DialogForm.defaultParent);
		this.id = id;
		this.setStyle({
			top: y + 'px',
			left: x + 'px',
		})

	}


}