import { Component, ComponentStyle } from "./Component";


export abstract class Form extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			display: 'block',
			pointerEvents: 'all',
		}
	};


	public constructor(parent: Component) {
		super(parent, 'div', true);

	}


	public destroy() {
		super.destroy();
	}



	// Form modality

	private static _modalStack: Form[] = [];

	protected static modalPush(c: Form) {
		if (this._modalStack.length>0) {
			this._modalStack[this._modalStack.length-1].enabled = false;
		}
		this._modalStack.push(c);
	}


	protected static modalPop() {
		this._modalStack.pop();
		if (this._modalStack.length>0) {
			this._modalStack[this._modalStack.length-1].enabled = true;
		}
	}



}