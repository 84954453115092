import StaffzPortal, { ServerLocation } from "./staffz/StaffzPortal";

export default class  AuthentificationCommunication extends StaffzPortal {

	private _loginLocation: ServerLocation;


	public constructor(loginLocation: ServerLocation, login: string, passwordHash: number) {
		super(loginLocation);
		this._loginLocation = loginLocation;
		this.request = {
			contract: 'Login',
			login: login,
			passwordHash: passwordHash
		}
		this.listen(this.onSucces, this.doLoginSucceded);
		this.listen(this.onError, this.doLoginError);
		this.call();
	}



	private doLoginSucceded() {
		this.location = this._loginLocation;
		this.destroy();
	}


	private doLoginError() {
		this.destroy();
	}


}