import { Controller, CSSStyles, StyleHandler } from "Bent";
import ConfirmDeleteProfileCommunication from "lib/communication/ConfirmDeleteProfileCommunication";
import ConfirmDeleteProfileForm from "./ConfirmDeleteProfileForm";
import ConfirmDeleteProfileView from "./ConfirmDeleteProfileView";
import ToastComponent from "lib/components/ToastComponent";


export default class ConfirmDeleteProfileController extends Controller<ConfirmDeleteProfileView, ConfirmDeleteProfileForm> {

	public constructor() {
		super(null, ConfirmDeleteProfileView, ConfirmDeleteProfileForm);
		this.active = true;
		this.doActivate();

		let urlSearchParamter = new URLSearchParams(window.location.search);
		let key = urlSearchParamter.get('key');
		if(key){
			let communication = new ConfirmDeleteProfileCommunication(key);
			this.listen(communication.onSucces, this.doDeleteSuccess);
			this.listen(communication.onError, this.doDeleteError);
		}
	}


	public refresh() {
		super.refresh();

	}


	public doDeleteSuccess(evt){
		new ToastComponent(this.view.form,evt.response.result,'');
	}

	public doDeleteError(evt){
		new ToastComponent(this.view.form,'An unexpected error occurred','');
	}

}