import { Component, ComponentStyle } from "Bent";
import ActionComponent from "lib/components/ActionComponent";
import LabelComponent from "lib/components/LabelComponent";
import ValueComponent from "lib/components/ValueComponent";

export default class PropertyGridControl extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			display: 'grid',
			gridAutoFlow: 'column',
			gridTemplateColumns: 'auto',

		}
	}

	private _rowCount: number;

	private _rowNumber: number = 0;



	private _defaultAction: ActionComponent;
	public get defaultAction(): ActionComponent {
		return this._defaultAction;
	}
	public set defaultAction(v: ActionComponent) {
		this._defaultAction = v;
	}




	public constructor(parent: Component, rowCount: number) {
		super(parent);
		this._rowCount = rowCount;
		this.setStyle({
			gridTemplateRows: 'repeat(' + rowCount + ', auto)'
		});

	}


	public destroy() {
		delete this._defaultAction;
		super.destroy();
	}


	public reset(rowCount: number) {
		this.clear();
		this._rowCount = rowCount;
		this.setStyle({
			gridTemplateRows: 'repeat(' + rowCount + ', auto)'
		});
	}


	public newCell(): PropertyCellComponent {
		let result = this._rowNumber % 2 == 0 ? new PropertyCellEvenRowComponent(this) : new PropertyCellOddRowComponent(this);
		this._rowNumber = (this._rowNumber + 1) % this._rowCount;
		this.listen(result.value.onDefault, this.doDefault);
		return result;

	}


	public doDefault() {
		if (this.defaultAction) {
			this.defaultAction.activate();
		}

	}


}


export class PropertyCellComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			display: 'grid',
			gridTemplateColumns: 'minmax(20px, 1fr) minmax(20px, 2fr)',
			alignItems: 'center',
			height: '24px',
		}
	}


	private _label: PropertyCellLabelComponent;
	public get label(): PropertyCellLabelComponent {
		return this._label;
	}


	public get caption(): string {
		return this._label.text;
	}
	public set caption(v: string) {
		this._label.text = v;
	}


	private _valueComponent: ValueComponent;
	public get value(): ValueComponent {
		return this._valueComponent;
	}


	public constructor(parent: Component) {
		super(parent);
		this._label = new PropertyCellLabelComponent(this);
		this._valueComponent = new ValueComponent(this);
	}


}


class PropertyCellEvenRowComponent extends PropertyCellComponent {

	public static readonly style: ComponentStyle = {
		default: {
			backgroundColor: '#eef4f8',
		}
	}


}


class PropertyCellOddRowComponent extends PropertyCellComponent {

	// public static readonly style: ComponentStyle = { default: {
	// 	backgroundColor: '#fcfcfc',

	// }


}


export class PropertyCellLabelComponent extends LabelComponent {

	public static readonly style: ComponentStyle = {
		default: {
			fontSize: '9px',
			color: '#99A5AC',
			textShadow: '#ffffff 0 1px',
			textIndent: "8px",
		}
	}


}


