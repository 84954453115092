import { Component, ComponentStyle, Form } from "Bent";
import CenterCoordAbility from "lib/abilities/CenterCoordAbility";
import DragAbility from "lib/abilities/DragAbility";
import EscapableAbility from "lib/abilities/EscapableAbility";
import ActionComponent, { ActionComponentStyle, ActionIconComponent } from "lib/components/ActionComponent";
import LabelComponent from "lib/components/LabelComponent";
import Vector from "lib/utilities/Vector";


export default class DialogForm extends Form {

	public static readonly style: ComponentStyle = {
		default: {
			position: 'absolute',
			minWidth: '150px',
			minHeight: '50px',
			backgroundColor: '#0c5783',
			borderRadius: '2px',
			boxShadow: '2px 2px rgba(0,0,0,.1)',
			display: 'grid',
			gridTemplateColumns: 'auto minmax(0px, min-content)',
			transition: 'opacity .6s',
			opacity: '1',
		},
		'.hidden': {
			display: '',
			opacity: '0',
		}

	}


	private static _defaultParent: Component;
	public static get defaultParent(): Component {
		return this._defaultParent;
	}
	public static set defaultParent(v: Component) {
		this._defaultParent = v;
	}


	private _centerCoord: CenterCoordAbility;

	private _escapeAbility: EscapableAbility;

	private _startPostion: Vector;


	private _titleComponent: DialogTitleComponent;
	public get title(): string {
		return this._titleComponent.text;
	}
	public set title(v: string) {
		this._titleComponent.text = v;
	}


	private _dialogCloseButton: DialogButton;
	public get dialogCloseButton(): DialogButton {
		return this._dialogCloseButton;
	}


	private _position: Vector;
	public get position(): Vector {
		return this._position;
	}
	public set position(v: Vector) {
		this._position = v;
		this.setStyle({
			transform: 'translate(' + v.x.toFixed() + 'px, ' + v.y.toFixed() + 'px)',
		});
	}


	public constructor(parent: Component) {
		super(DialogForm._defaultParent ? DialogForm._defaultParent : parent);

		Form.modalPush(this);

		this._centerCoord = this.createAbility(CenterCoordAbility);

		this._escapeAbility = this.createAbility(EscapableAbility);
		this.listen(this._escapeAbility.onEscape, this.doEscape);

		this._titleComponent = new DialogTitleComponent(this);
		let buttonArea = new DialogButtonsComponent(this);
		this.client = new DialogClientComponent(this);

		this._dialogCloseButton = new DialogButton(buttonArea);
		this._dialogCloseButton.setContent('close', null, 'close');

		this.listen(this._titleComponent.dragAbility.onStartDrag, this.doStartDrag);
		this.listen(this._titleComponent.dragAbility.onDrag, this.doDrag);

		let v = this._centerCoord.toCenterTranslation;
		v.y /= 2;
		this.position = v;

	}


	public destroy() {
		setTimeout(() => {
			Form.modalPop();
			super.destroy();
		}, 1)

	}


	private doStartDrag(ability: DragAbility) {
		this._startPostion = this._position;

	}


	private doDrag(ability: DragAbility) {
		this.position = Vector.add(this._startPostion, ability.dragDelta);

	}


	private doEscape() {
		this._dialogCloseButton.onActivate.fire(this);

	}

}


class DialogTitleComponent extends LabelComponent {

	public static readonly style: ComponentStyle = {
		default: {
			display: 'flex',
			alignItems: 'center',
			color: 'white',
			textIndent: '8px',
		}
	}


	private _dragAbility: DragAbility;
	public get dragAbility(): DragAbility {
		return this._dragAbility;
	}


	public constructor(parent: Component) {
		super(parent);
		this._dragAbility = this.createAbility(DragAbility);

	}


}


class DialogButtonsComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			whiteSpace: 'nowrap',
		}
	}


}


class DialogClientComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			gridColumn: '1 / 3',
			padding: '4px',
			minHeight: '20px',
			backgroundColor: '#fcfcfc',
		}
	}

}


class DialogButton extends ActionComponent {

	public static readonly style: ActionComponentStyle = {
		default: {
			display: 'flex',
			width: "30px",
			height: "30px",
			transition: 'background-color 0.3s',
			alignItems: 'center',
		},
		'.hover': {
			backgroundColor: '#e72536',

		},
		'.pressed': {
			backgroundColor: '#f1707a',
		},

	}


	protected createIcon(): ActionIconComponent {
		return new DialogCloseIcon(this);

	}

}


class DialogCloseIcon extends ActionIconComponent {

	public static readonly style: ComponentStyle = {
		default: {
			fill: '#fff',
			transform: 'scale(.4)',
		}
	}

}

