import { Component, ComponentEventHandler, ComponentStyle, CSSStyles } from "Bent";
import TextComponent from "./TextComponent";
import InputAbility from "lib/abilities/InputAbility";


export interface InputComponentStyle extends ComponentStyle {
	focused?: CSSStyles;
}


export default class InputComponent extends TextComponent {

	public static readonly style: InputComponentStyle = {
		default: {
			border: 'none',
			background: 'none',
			minWidth: '0px',
		},
		'input': {
			outline: 'none',
			border: 'none',
			width: '666px',
		},
		':focus': {
			outline: 'none',
			border: 'none',
		}
	};


	private _inputAbility: InputAbility;
	protected get inputAbility(): InputAbility {
		return this._inputAbility;
	}


	public get content(): string {
		return this._inputAbility.content;
	}
	public set content(v: string) {
		this._inputAbility.content = v;
	}


	public get readOnly(): boolean {
		return this._inputAbility.readOnly;
	}
	public set readOnly(v: boolean) {
		this._inputAbility.readOnly = v;
	}


	public get password(): boolean {
		return this._inputAbility.password;
	}
	public set password(v: boolean) {
		this._inputAbility.password = v;
	}


	public get focused(): boolean {
		return this.inputAbility.focused;
	}
	public set focused(v: boolean) {
		this.inputAbility.focused = v;
	}

	private _onFocusChanged: ComponentEventHandler;
	public get onFocusChanged(): ComponentEventHandler {
		return this._onFocusChanged;
	}


	private _onDefault: ComponentEventHandler;
	public get onDefault(): ComponentEventHandler {
		return this._onDefault;
	}


	public constructor(parent: Component) {
		super(parent, 'input');

		this._inputAbility = this.createAbility(InputAbility);
		this._onFocusChanged = this.createEventHandler();
		this._onDefault = this.createEventHandler();

		this.listen(this.inputAbility.onFocusChanged, this.doFocusChanged);
		this.listen(this.inputAbility.onKeyDown, this.doKeyDown);
		this.listen(this.inputAbility.onKeyUp, this.doKeyUp);
		this.listen(this.inputAbility.onDefault, this.doDefault);

	}


	protected doFocusChanged() {
		this.onFocusChanged.fire(this);

	}


	protected doKeyDown() {
	}


	protected doKeyUp() {
	}


	protected doDefault() {
		this.onDefault.fire(this);

	}


}