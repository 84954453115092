import TRANSLATION_DK from "./da";

export default class TranslationControl {

	static get languages() {
		return {
			'en': 'English',
			'da': 'Dansk',
		};

	}

	static _onSetActiveLang: Function;

	static get onSetActiveLang() {
		return this._onSetActiveLang;
	}


	static set onSetActiveLang(func) {
		this._onSetActiveLang = func;

	}


	static _activeLang: string = 'da';

	static get activeLang() {
		return TranslationControl._activeLang ? TranslationControl._activeLang : 'en';

	}

	static set activeLang(value) {
		TranslationControl._activeLang = TranslationControl.languages[value] ? value : 'en';
		if (TranslationControl.onSetActiveLang) {
			TranslationControl.onSetActiveLang(TranslationControl._activeLang);
		}

	}

	static translate(englishText: string, lang: string = '') {
		let result = '';
		if (!lang) {
			lang = TranslationControl.activeLang;
		}

		switch (lang) {
			case 'da':
				result = TRANSLATION_DK[englishText];
				break;
		}
		result = result ? result : englishText;

		return result;
	}

	static init() {
		TranslationControl.activeLang = 'da';

	}


}

export function __(englishText: string): string {
	return TranslationControl.translate(englishText);
};
