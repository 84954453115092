import { ControllerClass } from "Bent";
import LanguageOverviewForm from "./LanguageOverviewForm";
import LanguageOverviewView from "./LanguageOverviewView";
import LanguageListCommunication from "lib/communication/LanguageListCommunication";
import ListPageController from "lib/pages/ListPageController";


export default class LanguageOverviewController extends ListPageController<LanguageOverviewView, LanguageOverviewForm> {

	public constructor(parent: ControllerClass) {
		super(parent, LanguageOverviewView, LanguageOverviewForm, new LanguageListCommunication());

	}

}