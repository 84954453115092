import { Controller, ControllerClass } from "Bent";
import PublicForm from "./PublicForm";
import PublicView from "./PublicView";
import LoginController from "./login/LoginController";


export default class PublicController extends Controller<PublicView, PublicForm> {

	private _loginController: LoginController;


	public constructor(parent: ControllerClass) {
		super(parent, PublicView, PublicForm);
		this.listen(this.view.form.onLoginTrigger, this.doShowLoginDialog);

	}


	private doShowLoginDialog() {
		if (!this._loginController) {
			this._loginController = new LoginController(this);
			this._loginController.active = true;
			this.listen(this._loginController.onDestroy, this.doOnLoginClose);
		}

	}


	private doOnLoginClose() {
		delete this._loginController;

	}


}