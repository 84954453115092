import { ControllerClass } from "Bent";
import EminiPiForm from "./EminiPiForm";
import EminiPiView from "./EminiPiView";
import EminiPiCommunication from "lib/communication/EminiPiCommunication";
import ListPageController from "lib/pages/ListPageController";
import ListCommunication from "lib/communication/staffz/ListCommunication";


export default class EminiPiController extends ListPageController<EminiPiView, EminiPiForm> {

	public constructor(parent: ControllerClass) {
		super(parent, EminiPiView, EminiPiForm, new EminiPiCommunication());

	}

	protected handleNewContext(sender: ListCommunication) {
		super.handleNewContext(sender);
	}

	public doActivate() {
		super.doActivate();

	}

	protected doRefresh() {
		super.doRefresh();

	}
}