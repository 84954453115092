import { View } from "Bent";
import { ValueType } from "lib/communication/staffz/ValueType";
import ConfirmDeleteProfileForm from "./ConfirmDeleteProfileForm";

export default class ConfirmDeleteProfileView extends View<ConfirmDeleteProfileForm> {


	public populate(): void {
				
	}


}