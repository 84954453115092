import { Component, ComponentEventHandler, ComponentStyle } from "Bent";
import StickyTableHeaderRowAblility from "lib/abilities/StickyTableHeaderRowAblility";
import ButtonComponent from "lib/components/ButtonComponent";
import { ActionIconComponent } from "lib/components/ActionComponent";
import LabelComponent from "lib/components/LabelComponent";
import ValueComponent from "lib/components/ValueComponent";

export interface ListPopulator {
	populateHeader: (fieldName: string, headerCell: ListHeaderCell) => void;
	populateCell: (fieldName: string, rowIndex: number, cell: ListCell) => void;
}

export default class ListControl extends Component {

	public static readonly style: ComponentStyle = {
		' table': {
			width: '100%',
			borderCollapse: 'collapse',
			backgroundColor: '#dbe1e6',
		},
		' tr': {
			height: '21px'
		},
		' tr:nth-child(even)': {
			backgroundColor: '#eef4f8',
		},
		' tr:nth-child(odd)': {
			backgroundColor: 'white',
		},
		' tr:nth-child(even):hover': {
			backgroundColor: '#FFFFCC',
		},
		' tr:nth-child(odd):hover': {
			backgroundColor: '#FFFFCC',
		},
		' th:not(:last-child)': {
			borderRight: '1px solid #d0d6dc'
		},
		' td:not(:last-child)': {
			borderRight: '1px solid #dae5ec'
		},

	}



	private _table: Component;
	protected get table(): Component {
		if (!this._table) {
			this._table = new Component(this, 'table');
		}
		return this._table;
	}


	private _headerRow: ListHeaderRow;
	public get headerRow(): ListHeaderRow {
		if (!this._headerRow) {
			this._headerRow = new ListHeaderRow(this.table);
		}
		return this._headerRow;
	}


	private _onHeaderClick: ComponentEventHandler;
	public get onHeaderClick(): ComponentEventHandler {
		return this._onHeaderClick;
	}


	public constructor(parent: Component) {
		super(parent);
		this.createAbility(StickyTableHeaderRowAblility);
		this._onHeaderClick = this.createEventHandler();

	}


	public clear() {
		if (this._table) {
			this._table.destroy();
			delete this._table;
			delete this._headerRow;
		}

	}


	public addDataRow(): ListDataRow {
		return new ListDataRow(this.table);

	}


	public populate(viewColumns: string[], rowCount: number, populator: ListPopulator) {
		this.clear();
		for (let fieldName of viewColumns) {
			let header = this.headerRow.addHeader();
			header.id = fieldName;
			populator.populateHeader(fieldName, header);
			this.listen(header.onActivate, this.doHeaderClick);
		}
		for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
			let row = this.addDataRow();
			for (let fieldName of viewColumns) {
				populator.populateCell(fieldName, rowIndex, row.addCell());
			}
		}

	}


	private doHeaderClick(header: ListHeaderCell) {
		this.onHeaderClick.fire(header);

	}

}


class ListHeaderRow extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			position: 'relative',
			zIndex: '10'
		}
	}

	constructor(parent: Component) {
		super(parent, 'tr');

	}

	public addHeader() {
		return new ListHeaderCell(this);
	}

}


export class ListHeaderCell extends ButtonComponent {

	public static readonly style: ComponentStyle = {
		default: {
			position: 'relative',
			backgroundColor: '#dbe1e6',
			textAlign: 'left',
		}
	}


	private _labelComponent: ListHeaderLabelComponent;

	private _sort: ListHeaderSortComponent;


	private _id: string;
	public get id(): string {
		return this._id;
	}
	public set id(v: string) {
		this._id = v;
	}


	private _sortable: boolean;
	public get sortable(): boolean {
		return this._sortable == true;
	}
	public set sortable(v: boolean) {
		this._sortable = v;
		this.update();
	}


	private _sortIndex: number;
	public get sortIndex(): number {
		return this._sortIndex == undefined ? -1 : this._sortIndex;
	}
	public set sortIndex(v: number) {
		this._sortIndex = v;
		this.update();
	}


	private _descending: boolean;
	public get descending(): boolean {
		return this._descending == true;
	}
	public set descending(v: boolean) {
		this._descending = v;
		this.update();
	}



	public get text(): string {
		return this._labelComponent.text;
	}
	public set text(v: string) {
		this._labelComponent.text = v;
	}


	public get ctrlDown(): boolean {
		return this.buttonAbility.ctrlDown;
	}


	constructor(parent: Component) {
		super(parent, 'th');
		this._labelComponent = new ListHeaderLabelComponent(this);

	}


	private update() {
		this.enabled = this._sortable;

		if (this.sortable) {
			if (!this._sort) {
				this._sort = new ListHeaderSortComponent(this);
			}
			this._sort.update(this.sortIndex, this.descending);
		} else {
			if (this._sort) {
				this._sort.destroy();
				delete this._sort;
			}
		}

	}


}


class ListHeaderSortComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			position: 'absolute',
			right: '0px'
		}
	}


	private _sortIcon: ListHeaderSortIcon;

	private _sortLabel: ListHeaderSortLabel;


	constructor(parent: Component) {
		super(parent, 'span');
		this._sortIcon = new ListHeaderSortIcon(this);
		this._sortLabel = new ListHeaderSortLabel(this);

	}


	public update(sortIndex: number, descending: boolean) {
		this._sortIcon.iconId = (sortIndex == -1 ? 'sort' : (descending ? 'sortDesc' : 'sortAsc'));
		this._sortLabel.visible = sortIndex > 0;
		if (this._sortLabel.visible) {
			this._sortLabel.text = (sortIndex + 1).toString();
		}

	}


}



class ListHeaderSortIcon extends ActionIconComponent {

	public static readonly style: ComponentStyle = {
		default: {
			verticalAlign: 'middle',
			width: '14px',
			height: '19px'
		}
	}

}


class ListHeaderSortLabel extends LabelComponent {

	public static readonly style: ComponentStyle = {
		default: {
			verticalAlign: 'super',
			fontSize: '8px',
			color: '#99A5AD',
		}
	}

}


class ListHeaderLabelComponent extends LabelComponent {

	public static readonly style: ComponentStyle = {
		default: {
			textAlign: 'left',
			marginLeft: '6px',
			marginRight: '12px',
			color: '#99A5AD',
			fontSize: '9px',
		}
	}


}


export class ListDataRow extends Component {

	constructor(parent: Component) {
		super(parent, 'tr');

	}


	public addCell(): ListCell {
		return new ListCell(this);

	}


}


export class ListCell extends ValueComponent {

	public static readonly style: ComponentStyle = {
		default: {
			padding: '0px 8px',
			textIndent: '',
			display: '',
			borderRadius: '',
		}
	}


	private _id: string;
	public get id(): string {
		return this._id;
	}
	public set id(v: string) {
		this._id = v;
	}


	constructor(parent: Component, tagName: string = 'td') {
		super(parent, tagName);
		this.readonly = true;

	}


}

