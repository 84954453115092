import { Ability, AbilityEventHandler, DOMListener } from "Bent";

export default class KeyboardClickAbility extends Ability {

	private _keyUpHandler: DOMListener;

	private _key: string;
	public get key(): string {
		return this._key;
	}

	private _onClick: AbilityEventHandler;
	public get onClick(): AbilityEventHandler {
		return this._onClick;
	}


	public constructor(element: HTMLElement) {
		super(element);
		this._onClick = this.createEventHandler();
		this._keyUpHandler = this.createGlobalDOMListener("keyup", this.doKeyUp);

	}


	protected doEnable(): void {
		this._keyUpHandler.enabled = true;

	};


	private doKeyUp(e: KeyboardEvent) {
		this._key = e.key;
		this._onClick.fire(this);
	}


}