import { Component, ComponentStyle } from "Bent";
import FlagIconComponent from "lib/components/FlagIconComponent";
import EditControl from "lib/controls/EditControl";
import NavigationControl, { NavigationButtonComponent } from "lib/controls/NavigationControl";
import DialogForm from "lib/forms/DialogForm";


export default class SkillEditForm extends DialogForm {

	public static readonly style: ComponentStyle = {
		default: {
			width: '300px',
		}
	}


	private _editFields: EditControl;
	public get editFields(): EditControl {
		return this._editFields;
	}


	private _saveButton: NavigationButtonComponent;
	public get saveButton(): NavigationButtonComponent {
		return this._saveButton;
	}



	private _saveCloseButton: NavigationButtonComponent;
	public get saveCloseButton(): NavigationButtonComponent {
		return this._saveCloseButton;
	}



	public constructor(parent: Component) {
		super(parent);
		this._editFields = new EditControl(this);

		let nc = new NavigationControl(this);
		this._saveButton = nc.addButton();
		this._saveCloseButton = nc.addButton();


	}


	public makeFlag(parent: Component, langId: string): LabelFlagIconComponent {
		let result = new LabelFlagIconComponent(parent);
		result.iconId = langId;
		return result;
	}

}


export class LabelFlagIconComponent extends FlagIconComponent {

	public static readonly style: ComponentStyle = {
		default: {
			width: '',
			height: '18px',
		}
	}



}
