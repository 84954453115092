import { ComponentStyle, Form } from "Bent";


export default class FadeInOutForm extends Form {

	public static readonly style: ComponentStyle = {
		default: {
			position: 'absolute',
			top: '0px',
			left: '0px',
			right: '0px',
			bottom: '0px',
			opacity: '1',
			transition: 'opacity .6s',
		},
		'.hidden': {
			display: '',
			opacity: '0',
		}

	}


	public destroy() {
		this.visible = false;
		setTimeout(()=>{
			super.destroy();
		}, 600);
	}

}