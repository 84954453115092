import { View } from "Bent";
import DeleteProfileForm from "./DeleteProfileForm";
import { ValueType } from "lib/communication/staffz/ValueType";

export default class DeleteProfileView extends View<DeleteProfileForm> {


	public populate(): void {
		this.form.deleteProfileText = 'Please enter your email, phonenumber and pincode for the staffz account you want deleted. <br> <br> Afterwards a mail will be sent to the email with a link. Press the link to confirm the deletion of the account.';
		
		this.form.email.caption = 'Email';
		this.form.email.value.type = ValueType.string;
		this.form.email.value.placeholder = 'example@email.com';
		this.form.email.value.focus();
		this.form.email.value.input.setStyle({
			height: '65px',
			fontSize: '45px'
		});

		this.form.phoneNumber.caption = 'Phone ';
		this.form.phoneNumber.value.type = ValueType.string;
		this.form.phoneNumber.value.placeholder = '99999999';
		this.form.phoneNumber.value.input.setStyle({
			height: '65px',
			fontSize: '45px'
		});

		this.form.pinCode.caption = 'Pin code';
		this.form.pinCode.value.type = ValueType.string;
		this.form.pinCode.value.placeholder = '1234';
		this.form.pinCode.value.input.setStyle({
			height: '65px',
			fontSize: '45px'
		});

		this.form.deleteButton.setContent(null, 'Delete profile');
		this.form.deleteButton.labelComponent.setStyle({
			fontSize: '45px'
		})
	}


}