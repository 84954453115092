import { Controller, ControllerClass } from "Bent";
import ProfileListCommunication from "lib/communication/ProfileListCommunication";
import UserSearchForm from "./UserSearchForm";
import UserSearchView from "./UserSearchView";
import AdminController from "module/admin/AdminController";
import { IValueFields } from "lib/communication/staffz/IValueField";
import SearchPageController from "lib/pages/SearchPageController";


export default class UserSearchController extends SearchPageController<UserSearchView, UserSearchForm> {

	private static _filter: IValueFields;

	public static get searchFilter(): IValueFields {
		return UserSearchController._filter;
	}

	protected get filter() {
		return UserSearchController._filter;
	}
	protected set filter(v: IValueFields) {
		UserSearchController._filter = v;
	}


	public constructor(parent: ControllerClass) {
		super(parent, UserSearchView, UserSearchForm, new ProfileListCommunication());

	}


	protected doSearch() {
		super.doSearch();
		(this.parent as AdminController).menuPath = 'users/search/result';

	}


}