import StaffzPortal from "./StaffzPortal";
import { IEntityContractRequest, IEntityContractResponse } from "../staffz/IEntityContract";


export default class EntityCommunication extends StaffzPortal {


	public get request(): IEntityContractRequest {
		return super.request;
	}
	public set request(v: IEntityContractRequest) {
		super.request = v;
	}

	public get response(): IEntityContractResponse {
		return super.response;
	}


}