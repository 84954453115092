import { Destructable } from "./Destructable";

export class DOMListener extends Destructable {

	private _type: string;

	private _listener: EventListenerOrEventListenerObject;

	private _element: HTMLElement;

	private _options: boolean | AddEventListenerOptions;


	private _enabled: boolean;
	public get enabled(): boolean {
		return this._enabled == true;
	}
	public set enabled(v: boolean) {
		if (v != this.enabled) {
			if (v) {
				this._enabled = true;
				this.doEnable();
			} else {
				delete this._enabled;
				this.doDisable();
			}
		}
	}


	public constructor(type: string, listener: () => any, element: HTMLElement, options?: boolean | AddEventListenerOptions) {
		super();
		this._type = type;
		if (element) {
			this._element = element;
		}
		this._listener = listener;
		if (options) {
			this._options = options;
		}

	}


	public destroy() {
		this.enabled = false;
		super.destroy();

	}


	// private static _listenerCount: number = 0;


	private doEnable() {
		// console.log('addEventListener: ' + this._type + ' (' + ++DOMListener._listenerCount + ')');
		if (this._element) {
			this._element.addEventListener(this._type, this._listener, this._options);
		} else {
			addEventListener(this._type, this._listener, this._options);
		}

	}


	private doDisable() {
		// console.log('removeEventListener: ' + this._type + ' (' + --DOMListener._listenerCount + ')');
		if (this._element) {
			this._element.removeEventListener(this._type, this._listener, this._options);
		} else {
			removeEventListener(this._type, this._listener, this._options);
		}
	}


}