import { IListContractRequest } from "./staffz/IListContract";
import ListCommunication from "./staffz/ListCommunication";

export interface IEminiPiContractRequest extends IListContractRequest {
}


export default class EminiPiCommunication extends ListCommunication {

	public get request(): IEminiPiContractRequest {
		return super.request as IEminiPiContractRequest;
	}
	public set request(v: IEminiPiContractRequest) {
		super.request = v;
	}

	public constructor() {
		super();
		this.request = { contract: 'EminiPiList' }

	}


}