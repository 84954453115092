import { View } from "Bent";
import { IValueFields } from "lib/communication/staffz/IValueField";
import { EditPopulator } from "lib/controls/EditControl";
import { PropertyCellLabelComponent } from "lib/controls/PropertyGridControl";
import SearchPageForm from "./SearchPageForm";

export default abstract class SearchPageView<FormClass extends SearchPageForm> extends View<FormClass> implements EditPopulator {
	
	private _rowCount : number = 1;
	public get rowCount() : number {
		return this._rowCount;
	}
	public set rowCount(v : number) {
		this._rowCount = v;
	}
	
	private _filter: IValueFields;
	public get filter(): IValueFields {
		return this._filter;
	}
	public set filter(v: IValueFields) {
		this._filter = v;
		this.form.filterEdit.populate(v, this._rowCount, this);
		this.form.filterEdit.focusFirst();

	}


	public populateFieldCaption(fieldName: string, labelCell: PropertyCellLabelComponent) {

	}

	public destroy() {
		delete this._filter;
		super.destroy();

	}


}