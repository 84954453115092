import { ValueType } from "lib/communication/staffz/ValueType";
import { ListCell, ListHeaderCell } from "lib/controls/ListControl";
import ListPageView from "lib/pages/ListPageView";
import SkillListForm from "./SkillListForm";


export default class SkillListView extends ListPageView<SkillListForm> {

	private _onEditSkill: (id: string) => void;
	public get onEditSkill(): (id: string) => void {
		return this._onEditSkill;
	}
	public set onEditSkill(v: (id: string) => void) {
		this._onEditSkill = v;
	}


	private _rowCheckboxes: ListCell[] = [];


	public destroy() {
		delete this._rowCheckboxes;
		super.destroy();
	}

	public populate(): void {
		this._rowCheckboxes = [];
		super.populate();

		this.form.header.caption.text = 'kvalifikationsliste';
		this.form.btnMerge.setContent(null, 'Sammenlæg');
		this.form.btnDelete.setContent(null, 'Slet');
		this.form.statusChangeTo.type = ValueType.select;
		this.form.statusChangeTo.choises = {
			'0': 'Afventer',
			'1': 'Almen',
			'2': 'Individuel',
		};
		this.form.statusChangeTo.content = '1';
		this.form.btnChangeStatus.setContent(null, 'Skift status');

	}


	public populateHeader(fieldName: string, headerCell: ListHeaderCell) {
		let t = {
			'id': ' Nummer',
			'danishName': 'Navn DK',
			'lastEditedBy': 'Sidst ændret af',
			'created': 'Oprettet den',
			'status': 'status',
			'otherNames': 'Sprog',
			'usedCount': 'Antal Brugere',
			'skillCategoryId': 'Kategori',
			'chk': ''
		}
		headerCell.text = t[fieldName];

		let w = {
			'id': '5%',
			'danishName': '10%',
			'lastEditedBy': '10%',
			'created': '10%',
			'status': '10%',
			'otherNames': '',
			'usedCount': '50px',
			'skillCategoryId': '50px',
			'chk': '30px',
		}
		headerCell.setStyle({
			width: w[fieldName],
		});

		super.populateHeader(fieldName, headerCell);

	}


	protected getColumns(): string[] {
		let columns = super.getColumns();
		if (columns.indexOf('chk') < 0) {
			columns.push('chk');
		}
		return columns;

	}


	public populateCell(fieldName: string, rowIndex: number, cell: ListCell) {
		if (fieldName == 'danishName') {
			let row = this.context.content[rowIndex];
			cell.id = row[this.context.fields['id'].contentIndex];
			cell.readonlyIsButton = true;
			this.listen(cell.onReadOnlyClick, this.doSkillEdit);
		}

		if (fieldName == 'otherNames') {
			let field = this.context.fields[fieldName];
			let row = this.context.content[rowIndex];
			let value = row[field.contentIndex];
			if (value != '') {
				let translations = value.split('&&&');
				for (let translation of translations) {
					let ar = translation.split(':');
					let langId = ar[0];
					let text = ar[1];
					this.form.showTranslation(cell, langId, text);
				}
			}
			return;
		}


		if (fieldName == 'chk') {
			let row = this.context.content[rowIndex];
			cell.id = row[this.context.fields['id'].contentIndex];
			cell.type = ValueType.check;
			cell.readonly = false;
			this._rowCheckboxes.push(cell);
			return;
		}

		super.populateCell(fieldName, rowIndex, cell);

	}


	public getCheckedIds(): string[] {
		let result = [];
		for (let chk of this._rowCheckboxes) {
			if (chk.content == true) {
				result.push(chk.id);
			}
		}
		return result;

	}



	private doSkillEdit(cell: ListCell) {
		if (this.onEditSkill) {
			this.onEditSkill(cell.id);
		}

	}


}