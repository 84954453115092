import { Component, ComponentStyle } from "Bent";
import AdminPageForm from "lib/forms/AdminPageForm";
import ListControl from "lib/controls/ListControl";
import HeaderControl, { HeaderButtonComponent } from "lib/controls/HeaderControl";
import NavigationControl from "lib/controls/NavigationControl";


export default class OverviewForm extends AdminPageForm {

	public static readonly style: ComponentStyle = {
		default: {
			width: '100%',
			height: '100%',
		}
	}


	private _header : HeaderControl;
	public get header() : HeaderControl {
		return this._header;
	}


	private _helpButton : HeaderButtonComponent;
	public get helpButton() : HeaderButtonComponent {
		return this._helpButton;
	}
	public set helpButton(v : HeaderButtonComponent) {
		this._helpButton = v;
	}


	private _list: OverviewControl;
	public get list(): OverviewControl {
		return this._list;
	}



	private _footer : OverviewFooterControl;
	public get footer() : OverviewFooterControl {
		return this._footer;
	}



	public constructor(parent: Component) {
		super(parent);
		this._header = new HeaderControl(this);
		this._helpButton = this._header.addButton();
		this._helpButton.setContent('help', '', 'help');
		this._list = new OverviewControl(this);
		this._footer = new OverviewFooterControl(this);

	}


}


class OverviewControl extends ListControl {

	public static readonly style: ComponentStyle = {
		default: {
			margin: '0px 12px',
			overflow: 'auto',
			height: 'calc(100% - 96px)',
		}
	}


}


class OverviewFooterControl extends NavigationControl {

	public static readonly style: ComponentStyle = {
		default: {
			margin: '0px 12px 12px 12px',
			height: '46px',
			backgroundColor: '#dbe1e6',
			borderBottomLeftRadius: '2px',
			borderBottomRightRadius: '2px',
		}
	}

}