import { Component, ComponentEventHandler, ComponentStyle } from "Bent";
import TextComponent from "./TextComponent";
import LabelComponent from "./LabelComponent";
import ButtonAbility from "lib/abilities/ButtonAbility";

export default class ToastComponent extends Component {

	private _text: LabelComponent;

	private _buttonAbility: ButtonAbility;
	protected get buttonAbility(): ButtonAbility {
		return this._buttonAbility;
	}

	constructor(parent: Component, text: string, iconPath: string) {
		super(parent);

		this.setStyle({
			opacity:"0",
			minWidth:"250px",
			maxWidth: '250px',
			backgroundColor:"#333",
			color:"#fff",
			textAlign:"center",
			borderRadius:"2px",
			padding:"16px",
			position:"fixed",
			zIndex:"1",
			left:"1%",
			bottom:"30px",
			fontSize:"17px",
			transition: 'opacity 0.3s, margin-bottom 0.4s, max-height 0.4s',
		})

		this._text = new LabelComponent(this, 'div');
		this._text.text = text;
		
		this._text.setStyle({
			whiteSpace: "pre-wrap",
			fontSize: '15px',
		});

		
		this._buttonAbility = this.createAbility(ButtonAbility);
		
		this.listen(this._buttonAbility.onActivate, this.hide);

		this.show();	
		setTimeout(this.hide.bind(this), 7000);

	}


	private show() {
		this.setStyle({
			opacity:"1",
		})	
	}


	private hide() {
		this.setStyle({
			opacity:"0",
		})	

		setTimeout(()=>{
			this.destroy();
		}, 500);
	}


}