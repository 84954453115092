import { View } from "Bent";
import UnderContructionForm from "./UnderContructionForm";


export default class UnderContructionView extends View<UnderContructionForm> {


	private _location : string;
	public get location() : string {
		return this._location;
	}
	public set location(v : string) {
		this._location = v;
	}


	public populate(): void {
		this.form.text = 'Sorry. ' + this.location + ' is under construction.';

	}


}