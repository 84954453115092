import { ControllerClass } from "Bent";
import ProfileListCommunication from "lib/communication/ProfileListCommunication";
import ListPageController from "lib/pages/ListPageController";
import UserListForm from "./UserListForm";
import UserListView from "./UserListView";
import UserSearchController from "./UserSearchController";


export default class UserListController extends ListPageController<UserListView, UserListForm> {

	public constructor(parent: ControllerClass) {
		super(parent, UserListView, UserListForm, new ProfileListCommunication());

	}


	public doActivate() {
		this.communication.request.filter = UserSearchController.searchFilter;
		super.doActivate();

	}


	protected doRefresh() {
		this.communication.request.filter = UserSearchController.searchFilter;
		super.doRefresh();

	}


}