export enum ValueType {
	check = 0,
	date = 1,
	dateSpan = 2,
	dateTime = 3,
	dateTimeSpan = 4,
	eMail = 5,
	float = 6,
	image = 7,
	int = 8,
	phone = 9,
	password = 10,
	currency = 11,
	ssn = 12,
	text = 13,
	select = 14,
	selectMultiple = 15,
	string = 16,
	textHtml = 17,
	time = 18,
	timeSpan = 19,
	url = 20,
	yesNo = 21,
	translateableString = 22,
}