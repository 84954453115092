import { Component, ComponentStyle } from "Bent";
import ActionComponent, { ActionComponentStyle, ActionIconComponent, ActionLabelComponent } from "lib/components/ActionComponent";


export default class NavigationControl extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			display: 'grid',
			gridTemplateColumns: 'auto minmax(0px, min-content)',
			marginTop: '4px',
		}
	}


	private _buttonsPanel: NavigationButtonPanelComponent;
	public get buttonsPanel(): NavigationButtonPanelComponent {
		return this._buttonsPanel;
	}


	public constructor(parent: Component) {
		super(parent);
		let client = new Component(this);
		this._buttonsPanel = new NavigationButtonPanelComponent(this);
		this.client = client;

	}


	public addButton(): NavigationButtonComponent {
		return new NavigationButtonComponent(this._buttonsPanel);

	}


	public addEmpty(): NavigationEmptyComponent {
		return new NavigationEmptyComponent(this._buttonsPanel);

	}
}


export class NavigationButtonPanelComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			display: 'flex',
			alignItems: 'center',
			padding: "0px 8px",
		}
	}


}


export class NavigationButtonComponent extends ActionComponent {
	
	private _labelComponent : NavigationLabelComponent;
	public get labelComponent() : NavigationLabelComponent {
		return this._labelComponent;
	}
	public set labelComponent(v : NavigationLabelComponent) {
		this._labelComponent = v;
	}
	

	public static readonly style: ActionComponentStyle = {
		default: {
			display: 'flex',
			alignItems: 'center',
			borderRadius: '2px',
			whiteSpace: 'nowrap',
			background: '#0c5783',
			padding: '0px 12px',
			transition: 'background-color 0.2s',
			height: '24px',
			marginLeft: '4px',
		},
		'.hover': {
			backgroundColor: '#307caf',
		},
		'.pressed': {
			backgroundColor: '#599cce',
		},
		'.focused': {
			backgroundColor: '#307caf',
		}
	}

	protected createIcon(): ActionIconComponent {
		return new NavigationIconComponent(this);

	};


	public createLabel(): ActionLabelComponent {
		this._labelComponent = new NavigationLabelComponent(this)
		return this._labelComponent;

	};


}

class NavigationLabelComponent extends ActionLabelComponent {

	public static readonly style: ComponentStyle = {
		default: {
			color: 'white',
		}
	}


}


class NavigationIconComponent extends ActionIconComponent {

	public static readonly style: ComponentStyle = {
		default: {
			display: 'inline-block',
			fill: 'white',
			transform: 'scale(.6)',
			width: '24px',
			height: '24px',
			marginLeft: '-8px',
		}
	}

}


export class NavigationEmptyComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			width: '100px',
			marginLeft: '4px',
		}
	}

}
