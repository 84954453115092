import { Component } from "Bent";
import { IValueField, IValueFields } from "lib/communication/staffz/IValueField";
import PropertyGridControl, { PropertyCellComponent, PropertyCellLabelComponent } from "./PropertyGridControl";


export interface EditPopulator {
	populateFieldCaption: (fieldName: string, labelCell: PropertyCellLabelComponent) => void;
}

export default class EditControl extends PropertyGridControl {

	private _editFields: { [id: string]: PropertyCellComponent } = {};
	public get editFields(): { [id: string]: PropertyCellComponent } {
		return this._editFields;
	}


	public constructor(parent: Component, rowCount: number = 0) {
		super(parent, rowCount);

	}


	public populate(valueFields: IValueFields, rowCount: number, populator: EditPopulator) {
		if (!valueFields) {
			this.reset(0);
			return;
		}
		this.reset(rowCount);
		for (let fieldName in valueFields) {
			let field: IValueField = valueFields[fieldName];

			if (field.readonly && (field.value == undefined)) {
				continue;
			}


			let cell = this.newCell();
			populator.populateFieldCaption(fieldName, cell.label);
			if (field.readonly) {
				cell.value.readonly = true;
			}
			cell.value.type = field.valueType;
			if (field.options) {
				cell.value.choises = valueFields[fieldName].options;
			}
			if (field.value) {
				cell.value.content = valueFields[fieldName].value;
			}
			this._editFields[fieldName] = cell;
		}

	}


	public updateValueFields(valueFields: IValueFields) {
		if (valueFields) {
			for (let fieldName in valueFields) {
				if (this._editFields[fieldName]) {
					valueFields[fieldName].value = this._editFields[fieldName].value.content;
				}
			}
		}

	}


	public focusFirst() {
		for (let fieldName in this.editFields) {
			let field = this.editFields[fieldName];
			if (!field.value.readonly) {
				field.value.focus();
				return;
			}
		}

	}


	public clearFieldValues() {
		for (let filterName in this.editFields) {
			this._editFields[filterName].value.content = null;
			this.focusFirst();
		}

	}



}