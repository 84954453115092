import { ControllerClass } from "Bent";
import LanguageOverviewController from "./language/LanguageOverviewController";
import SkillEditController from "./skills/SkillEditController";
import SkillListController from "./skills/SkillListController";
import SkillSearchController from "./skills/SkillSearchController";
import EminiPiController from "./eminiPi/EminiPiController";


export default class UsersControllerFactory {

	public static getController(parent: ControllerClass, mainMenu: string, menu: string): ControllerClass {
		if (mainMenu == 'language') {
			if (menu == 'overview') {
				return new LanguageOverviewController(parent);
			}
		}
		if (mainMenu == 'skills') {
			if (menu == 'search') {
				return new SkillSearchController(parent);
			}
			if (menu == 'list') {
				return new SkillListController(parent);
			}
		}
		if (mainMenu == 'eminiPi') {
			if (menu == 'eminiPiData') {
				return new EminiPiController(parent);
			}

		}
		return null;

	}


	public static activateDialog(parent: ControllerClass, mainMenu: string, menu: string) {
		if (mainMenu == 'skills') {
			if (menu == 'new') {
				let c = new SkillEditController(parent, null);
				c.active = true;
			}
		}

	}


}