import { Destructable } from "./Destructable";

export class DestructableCollection<T extends Destructable> extends Destructable {

	private _objects: T[];


	public get length(): number {
		return this._objects.length;
	}

	public item(index: number): T {
		return this._objects[index];
	}


	public add(o: T): T {
		if (!(this._objects)) {
			this._objects = [];
		}
		this._objects.push(o);
		return o;

	}


	public forEach(f: (o: T) => void) {
		if (this._objects) {
			for (let o of this._objects) {
				f(o);
			}
		}

	}


	public remove(o: T) {
		if (this._objects) {
			let index = this._objects.indexOf(o);
			if (index >= 0) {
				this._objects.splice(index, 1);
			}
			if (this._objects.length == 0) {
				delete this._objects;
			}
		}

	}


	public destroy() {
		if (this._objects) {
			let objects = this._objects;
			delete this._objects;
			let o: T;
			while (o = objects.pop()) {
				o.destroy();
			}
		}
		super.destroy();

	}


}