import ListPageForm from "lib/pages/ListPageForm";
import FlagIconComponent from "lib/components/FlagIconComponent";
import { Component, ComponentStyle } from "lib/_bent/Component";


export default class LanguageOverviewForm extends ListPageForm {

	public makeFlag(parent: Component, langId: string): CellFlagIconComponent {
		let result = new CellFlagIconComponent(parent);
		result.iconId = langId;
		return result;
	}


}

class CellFlagIconComponent extends FlagIconComponent {

	public static readonly style: ComponentStyle = {
		default: {
			display: 'inherit',
			width: '26px',
			height: '19px',
		}
	};


}