import FocusAbility from "lib/abilities/FocusAbility";
import ActionComponent, { ActionComponentStyle, ActionIconComponent } from "./ActionComponent";
import ButtonComponent from "./ButtonComponent";
import LabelComponent from "./LabelComponent";
import { ButtonState } from "lib/abilities/ButtonAbility";
import { ComponentStyle } from "Bent";
import ValueComponent from "./ValueComponent";
import { ValueType } from "lib/communication/staffz/ValueType";
import { Component } from "lib/_bent/Component";
import { IOptions } from "lib/communication/staffz/IField";
import EscapableAbility from "lib/abilities/EscapableAbility";


export default class DropdownComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			position: 'absolute',
			top: '100%',
			height: 'fit-content',
			maxHeight: '120px',
			overflowY: 'scroll',
			right: '0px',
			width: 'fit-content',
			minWidth: 'calc(100% - 2px)',
			minHeight: '6px',
			overflowX: 'hidden',
			border: '1px solid #B1C5D3',
			borderBottom: 'none',
			backgroundColor: '#F5FBFC',
			zIndex: '1'
		},
		'.goUp': {
			top: 'unset',
			bottom: '100%',
		}
	}

	private _escapeAbility: EscapableAbility;

	private _items: DropdownItemComponent[];

	private _valueComponent: ValueComponent;


	private _highlightedId: string;
	public get highlightedId(): string {
		return this._highlightedId;
	}
	public set highlightedId(v: string) {
		this._highlightedId = null;
		for (let item of this._items) {
			item.active = (item.id == v);
			if (item.active) {
				this._highlightedId = v;
			}
		}
		if (this._highlightedId == null) {
			if (this._items.length > 0) {
				this._items[0].active = true;
				this._highlightedId = this._items[0].id;
			}
		}

	}


	public get goUp(): boolean {
		return this.hasClass('goUp');
	}
	public set goUp(v: boolean) {
		this.setClass('goUp', v);
	}


	constructor(parent: ValueComponent) {
		super(parent, 'div');
		this._valueComponent = parent;
		this.buildItems(parent.choises);
		this.highlightedId = this._valueComponent.content;

		this._escapeAbility = this.createAbility(EscapableAbility);
		this._escapeAbility.containerLevel = 1;
		this.listen(this._escapeAbility.onEscape, this.doEscape);

	}


	private buildItems(options: IOptions) {
		this._items = [];
		for (let c of this.getIdListSorted(options)) {
			let selectItem = new DropdownItemComponent(this, c, options[c]);
			this.listen(selectItem.onActivate, this.doSelectItem);
			this._items.push(selectItem);
		}

	}


	private getIdListSorted(options: IOptions): string[] {
		let result:string[] = Object.keys(options);
		result.sort((a: string, b: string): number=>{
			return options[a].localeCompare(options[b]);
		});
		return result;

	}



	public destroy() {
		delete this._items;
		delete this._valueComponent;
		super.destroy();

	}

	private doSelectItem(item: DropdownItemComponent) {
		if (this._valueComponent.type == ValueType.select) {
			this._valueComponent.content = item.id;
			this._valueComponent.droppingDown = false;

		} else if (this._valueComponent.type == ValueType.selectMultiple) {
			throw "Not implemented";

		}

	}


	private doEscape() {
		let v = this._valueComponent;
		this._valueComponent.droppingDown = false;
		v.focus();

	}


	public highlightNext(reverse: boolean = false) {
		// this.highlightedId = this.nextId(this.highlightedId, reverse);
		this.highlightedId = this.nextString(this.highlightedId, reverse);
	}


	private itemById(id: string): DropdownItemComponent {
		for (let item of this._items) {
			if (item.id == id) {
				return item;
			}
		}
		return null;
	}

	private nextString(id: string, reverse: boolean):string{
		let result = this.getIdListSorted(this._valueComponent.choises);
		let items = [];
		result.forEach(element => {
			let item = this.itemById(element);
			if(!item.filteredOut){
				items.push(element)
			}
		});

		let index = items.indexOf(id);

		let ofset = 1;
		if(reverse){
			ofset = -1;
		}

		let returnIndex = index+ofset;
		if(returnIndex < 0){
			returnIndex = items.length-1;
		}
		if(returnIndex >= items.length){
			returnIndex = 0;
		}

		return items[returnIndex];
	}

	private nextId(id: string, reverse: boolean): string {
		let ids = [];
		for (let id of Object.keys(this._valueComponent.choises)) {
			let item = this.itemById(id);
			if (!item.filteredOut) {
				ids.push(id);
			}
		}
		if ((!ids) || (ids.length == 0)) {
			return null;
		}
		if (reverse) {
			ids.reverse();
		}
		let index = ids.indexOf(id);
		return ids[(index < ids.length - 1) ? index + 1 : 0];
	}


	public setFilter(filter: string) {
		for (let item of this._items) {
			item.filteredOut = !item.caption.toUpperCase().startsWith(filter.toUpperCase());
		}

	}


}


export class DropdownButtonComponent extends ActionComponent {

	public static readonly style: ActionComponentStyle = {
		default: {
			display: 'flex',
			width: "20px",
			transition: 'background-color 0.3s',
			background: 'white',
			userSelect: 'none',
		},
		'.hover': {
			backgroundColor: '#ddd'
		},
		'.pressed': {
			backgroundColor: '#888'
		},
		'.focused': {
			backgroundColor: '#ddd'
		},

	}


	public constructor(parent: Component) {
		super(parent);
		this.focusable = false;
		this.listen(this.focusAbility.onFocusChanged, this.test);
	}


	protected createIcon(): ActionIconComponent {
		return new DropdownButtonIconComponent(this);

	}

	public test(f: FocusAbility) {
		console.log('focused:');
		console.log(f);
	}


}


class DropdownButtonIconComponent extends ActionIconComponent {

	public static readonly style: ComponentStyle = {
		default: {
			fill: '#444',
			transform: 'scale(.4)',
		}
	}


}


class DropdownItemComponent extends ButtonComponent {

	public static readonly style: ComponentStyle = {
		default: {
			padding: '2px',
			borderBottom: '1px solid #B1C5D3',
		},
		'.active': {
			backgroundColor: '#FFA'
		},
		'.off': {
			display: 'none',
		}
	}


	public get active(): boolean {
		return this.hasClass('active');
	}
	public set active(v: boolean) {
		this.setClass('active', v);
		if (v) {
			this.focusAbility.scrollIntoView();
		}
	}


	public get filteredOut(): boolean {
		return this.hasClass('off');
	}
	public set filteredOut(v: boolean) {
		this.setClass('off', v);
	}


	private _id: string;
	public get id(): string {
		return this._id;
	}

	private _labelComponent: DropdownLabelComponent;
	public get caption(): string {
		return this._labelComponent.text;
	}


	private _dropdownComponent: DropdownComponent;


	constructor(parent: DropdownComponent, id: string, caption: string) {
		super(parent, 'div');
		this._dropdownComponent = parent;
		this._id = id;
		this._labelComponent = new DropdownLabelComponent(this, caption);
		this.listen(this.buttonAbility.onStateChanged, this.doStateChanged);
		this.focusable = false;

	}


	public destroy() {
		delete this._dropdownComponent;
		super.destroy();

	}


	private doStateChanged() {
		if (this.buttonAbility.state == ButtonState.hover) {
			this._dropdownComponent.highlightedId = this.id;
		}
	}


}


class DropdownLabelComponent extends LabelComponent {

	public static readonly style: ComponentStyle = {
		default: {
			textTransform: 'none',
			userSelect: 'none',
		}

	}


	constructor(parent: Component, caption: string) {
		super(parent);
		this.text = caption;
	}


}