import { Component, ComponentStyle } from "Bent";


export default class LayerComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			pointerEvents: 'none',
			position: 'absolute',
			top: '0px',
			left: '0px',
			right: '0px',
			bottom: '0px',
			overflow: 'hidden',
			transition: 'filter .2s',
		},
		'.disabled': {
			filter: 'blur(1px) grayscale(.7) brightness(.9)',
			userSelect:'none',
		}
	}


}