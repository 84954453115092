import { Controller, ControllerClass } from "Bent";
import SkillCommunication from "lib/communication/SkillCommunication";
import SkillEditForm from "./SkillEditForm";
import SkillEditView from "./SkillEditView";


export default class SkillEditController extends Controller<SkillEditView, SkillEditForm> {


	private _communication: SkillCommunication;
	protected get communication(): SkillCommunication {
		return this._communication;
	}


	public constructor(parent: ControllerClass, id: string) {
		super(parent, SkillEditView, SkillEditForm);
		this._communication = new SkillCommunication();
		if (id) {
			this._communication.action = "read";
			this._communication.skillId = id;
		}

		this.listen(this._communication.onSucces, this.handleNewContext);
		this.listen(this.view.form.dialogCloseButton.onActivate, this.destroy);
		this.listen(this.view.form.saveButton.onActivate, this.doSave);
		this.listen(this.view.form.saveCloseButton.onActivate, this.doSaveClose);

	}


	public doActivate() {
		super.doActivate();
		this._communication.call();
	}

	protected handleNewContext(sender: SkillCommunication) {
		if (this.view) {
			this.view.fields= sender.response.fields;
		}
		this.parent.refresh();

	}


	private doSave() {
		this.view.form.editFields.updateValueFields(this.view.fields);
		this._communication.request.fields = this.view.fields;
		this._communication.request.action = (this._communication.skillId == null) ? "create": "update";
		this._communication.call();
	}


	private doSaveClose() {
		this.listen(this._communication.onSucces, this.destroy);
		this.doSave();
	}



}