import { ControllerClass } from "Bent";
import UserListController from "./search/UserListController";
import UserSearchController from "./search/UserSearchController";


export default class UsersControllerFactory {


	public static getController(parent: ControllerClass, mainMenu: string, menu: string): ControllerClass {
		if (mainMenu == 'search') {
			if (menu=='search') {
				return new UserSearchController(parent);
			}
			if (menu=='result') {
				return new UserListController(parent);
			}
		}
		return null;

	}


	public static activateDialog(parent: ControllerClass, mainMenu: string, menu: string) {
	}

}