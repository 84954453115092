import { Controller, ControllerClass } from "Bent";
import ListCommunication from "lib/communication/staffz/ListCommunication";
import { ListHeaderCell } from "lib/controls/ListControl";
import { PagerNavigatorButtonComponent, PagerStatusPageSizeInputComponent } from "lib/controls/PagerControl";
import { IFormConstructor, IViewConstructor } from "lib/_bent/Controller";
import ListPageForm from "./ListPageForm";
import ListPageView from "./ListPageView";

export default abstract class ListPageController<ViewClass extends ListPageView<FormClass>, FormClass extends ListPageForm> extends Controller<ViewClass, FormClass> {

	private _communication: ListCommunication;
	protected get communication(): ListCommunication {
		return this._communication;
	}


	public constructor(parent: ControllerClass, viewClass: IViewConstructor<ViewClass>, formClass: IFormConstructor<FormClass>, communication: ListCommunication) {
		super(parent, viewClass, formClass);
		this._communication = communication;
		this.listen(this._communication.onSucces, this.handleNewContext);
		this.listen(this.view.form.pager.onGotoPage, this.doGotoPage);
		this.listen(this.view.form.pager.onRefresh, this.doRefresh);
		this.listen(this.view.form.pager.onChangePageSize, this.doChangePageSize);
		this.listen(this.view.form.list.onHeaderClick, this.doHeaderClick);

	}


	public refresh() {
		this._communication.call();
		super.refresh();
	}


	protected handleNewContext(sender: ListCommunication) {
		this.view.context = sender.response;

	}


	private doGotoPage(sender: PagerNavigatorButtonComponent) {
		let view = this.view.context.view;
		view.pageNumber = sender.pageNumber;
		this.doRefresh();

	}


	private doChangePageSize(sender: PagerStatusPageSizeInputComponent) {
		let view = this.view.context.view;
		view.pageSize = parseInt(sender.content);
		this.doRefresh();

	}


	private doHeaderClick(sender: ListHeaderCell) {
		let view = this.view.context.view;
		let sortField = (asc: Boolean) => {
			return (asc ? '+' : '-') + sender.id;
		};
		if (sender.ctrlDown) {
			if (sender.sortIndex >= 0) {
				view.sortColumns[sender.sortIndex] = sortField(sender.descending);
			} else {
				view.sortColumns.push(sortField(true));
			}
		} else {
			view.sortColumns = [sortField((sender.sortIndex == -1 || sender.descending) || (view.sortColumns.length != 1))];
		}
		this.doRefresh();

	}


	protected doRefresh() {
		this.view.form.pager.status.refreshButton.updating = true;
		this._communication.request.view = this.view.context.view;
		this._communication.call();

	}

}