import StaffzPortal from "./StaffzPortal";
import { IListContractRequest, IListContractResponse } from "../staffz/IListContract";


export default class ListCommunication extends StaffzPortal {


	public get request(): IListContractRequest {
		return super.request;
	}
	public set request(v: IListContractRequest) {
		super.request = v;
	}

	public get response(): IListContractResponse {
		return super.response;
	}


}