import { Component, ComponentStyle } from "Bent";
import HtmlContentAbility from "lib/abilities/HtmlContentAbility";
import TextComponent from "./TextComponent";

export default class LabelComponent extends TextComponent {

	public static readonly style: ComponentStyle = {
		default: {
			textTransform: 'uppercase',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
		}
	}


	private _htmlContentAbility: HtmlContentAbility;


	public get text(): string {
		return this._htmlContentAbility.htmlContent;
	}
	public set text(v: string) {
		this._htmlContentAbility.htmlContent = v;
	}


	public constructor(parent: Component, tagname: string = 'span') {
		super(parent, tagname);

		this._htmlContentAbility = this.createAbility(HtmlContentAbility);

	}


}