export class Destructable {

	// private static _instanceCount: number = 0;


	public constructor() {
		// console.log('CREATING: ' + this.constructor.name +' (' + ++Destructable._instanceCount + ')');

	}


	public destroy() {
		// console.log('DESTROYING: ' + this.constructor.name +' ('+ --Destructable._instanceCount +')');

		for(let p in this) {
			// console.log('  DELETING: ' + this.constructor.name + '.' + p);
			if (this[p] instanceof Destructable) {
				let o = this[p];
				if (Object.keys(o).length !== 0) {
					//@ts-ignore
					o.destroy();
				}
			}
			delete this[p];

		}

	}


}