import { Communication, CommunicationEventHandler } from "Bent";

export enum ServerLocation {
	'prod' = 'https://api.staffz.dk',
	'dev' = 'https://dev.staffz.dk',
	'bnLocal' = 'http://10.0.0.139:8090',
	'skjLocal' = 'http://10.0.0.68:8090',
}


export default class StaffzPortal extends Communication {

	private static _location: ServerLocation;
	public get location(): ServerLocation {
		return StaffzPortal._location;
	}
	public set location(v: ServerLocation) {
		if (v != StaffzPortal._location) {
			StaffzPortal._location = v;
			StaffzPortal.onLoginChanged.fire(this);
		}
	}

	public static get loggedIn(): boolean {
		return this._location != undefined;
	}


	private static _onLoginChanged: CommunicationEventHandler;
	public static get onLoginChanged(): CommunicationEventHandler {
		if (!this._onLoginChanged) {
			this._onLoginChanged = this.createStaticEventHandler();
		}
		return this._onLoginChanged;
	}


	private static _restKey: string;
	public static get restKey(): string {
		return this._restKey;
	}
	public static set restKey(v: string) {
		this._restKey = v;
	}

	protected get request(): any {
		return super.request["params"];
	}
	protected set request(v: any) {
		let r = {
			"action": "index",
			"params": v,
		};
		if (StaffzPortal.restKey) {
			r.params.key = StaffzPortal.restKey;
		}
		super.request = r;
	}


	public constructor(location?: ServerLocation) {
		if (!location) {
			location = StaffzPortal._location;
		}
		super(location + '/admin/api');

	}

	protected done() {
		if ((!this.error) && this.response.error) {
			let e = this.response.error;
			this._error = e;
			delete this._response;
			if (e == 'not authorised') {
				this.logoff();
				return;
			}
		}
		if (this.response && this.response['key']) {
			StaffzPortal._restKey = this.response.key;
		}
		super.done();

	}


	public logoff() {
		StaffzPortal._restKey = undefined;
		this.location = undefined;

	}




}