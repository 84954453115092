// rewritten from https://stackoverflow.com/questions/18638900/javascript-crc32

export default class CRC32 {

	private static _CRCTable: number[];


	private static makeCRCTable() {
		let c: number;
		let crcTable: number[] = [];
		for (let n: number = 0; n < 256; n++) {
			c = n;
			for (var k = 0; k < 8; k++) {
				c = ((c & 1) ? (0xEDB88320 ^ (c >>> 1)) : (c >>> 1));
			}
			crcTable[n] = c;
		}
		return crcTable;

	}


	public static fromString(str: string): number {
		var crcTable: number[] = this._CRCTable ? this._CRCTable : (this._CRCTable = this.makeCRCTable());
		var crc = 0 ^ (-1);
		for (var i = 0; i < str.length; i++ ) {
			crc = (crc >>> 8) ^ crcTable[(crc ^ str.charCodeAt(i)) & 0xFF];
		}
		return (crc ^ (-1)) >>> 0;

	}


}