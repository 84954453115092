import { DestructableCollection } from "Bent";
import { Event } from "./Event";
import { EventHandler } from "./EventHandler";
import { EventListener } from "./EventListener";

export type CommunicationEvent = Event<Communication>;

export type CommunicationEventHandler = EventHandler<CommunicationEvent>;


export abstract class Communication extends EventListener {

	private _url: string;
	public get url(): string {
		return this._url;
	}

	private _eventHandlers: DestructableCollection<EventHandler<CommunicationEvent>>;

	private _timeoutId: number;

	private _requestMutex: boolean;


	private _request: any;
	protected get request(): any {
		return this._request;
	}
	protected set request(v: any) {
		this._request = v;
	}


	protected _response: any;
	public get response(): any {
		return this._response;
	}


	private _onSucces: CommunicationEventHandler;
	public get onSucces(): CommunicationEventHandler {
		return this._onSucces;
	}
	public set onSucces(v: CommunicationEventHandler) {
		this._onSucces = v;
	}


	protected _error: string;
	public get error(): string {
		return this._error;
	}


	private static _onAllError : CommunicationEventHandler;
	public static get onAllError() : CommunicationEventHandler {
		if (!this._onAllError) {
			this._onAllError = this.createStaticEventHandler();
		}
		return this._onAllError;
	}
	public static set onAllError(v : CommunicationEventHandler) {
		this._onAllError = v;
	}



	private _onError: CommunicationEventHandler;
	public get onError(): CommunicationEventHandler {
		return this._onError;
	}
	public set onError(v: CommunicationEventHandler) {
		this._onError = v;
	}



	private _onSlow: CommunicationEventHandler;
	public get onSlow(): CommunicationEventHandler {
		return this._onSlow;
	}
	public set onSlow(v: CommunicationEventHandler) {
		this._onSlow = v;
	}


	public get xdebug(): boolean {
		return window.hasOwnProperty('xdebug');
	}


	public constructor(url: string) {
		super();
		this._url = url;
		this._eventHandlers = new DestructableCollection<EventHandler<CommunicationEvent>>();
		this.onSucces = this.createEventHandler();
		this.onError = this.createEventHandler();
		this.onSlow = this.createEventHandler();

	}


	public destroy() {
		if (this._requestMutex) {
			delete this._requestMutex;
			clearTimeout(this._timeoutId);
		}
		super.destroy();
	}


	protected createEventHandler(): EventHandler<CommunicationEvent> {
		return this._eventHandlers.add(new EventHandler<CommunicationEvent>());

	}


	protected static createStaticEventHandler(): EventHandler<CommunicationEvent> {
		return new EventHandler<CommunicationEvent>();

	}


	public call() {
		if (this._requestMutex) {
			return;
		}
		this._requestMutex = true;
		this._timeoutId = window.setTimeout(() => {
			this.onSlow.fire(this);
		}, 1000);

		(async () => {
			try {
				let url = this._url;
				if (this.xdebug) {
					console.log(this._request);
					url+= '?XDEBUG_SESSION_START=vscode';
				}
				const rawResponse = await fetch(url, {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(this._request)
				});
				this._response = await rawResponse.json();
				if (this.xdebug) {
					console.log(this._response);
				}
				if (this._requestMutex) {
					delete this._error;
					this.done();
				}

			}
			catch (error) {
				if (this.xdebug) {
					console.log(error);
				}
				if (this._requestMutex) {
					delete this._response;
					this._error = error;
					this.done();
				}
			}
		})();
	}


	protected done() {
		delete this._requestMutex;
		clearTimeout(this._timeoutId);
		if (this._error) {
			this.onError.fire(this);
			Communication.onAllError.fire(this);
		} else {
			this.onSucces.fire(this);
		}
	}


}