import { Controller, ControllerClass } from "Bent";
import UnderContructionForm from "./UnderContructionForm";
import UnderContructionView from "./UnderContructionView";

export default class UnderContructionController extends Controller<UnderContructionView, UnderContructionForm> {


	public get location() : string {
		return this.view.location;
	}
	public set location(v : string) {
		this.view.location = v;
		this.update();
	}


	public constructor(parent: ControllerClass) {
		super(parent, UnderContructionView, UnderContructionForm);

	}


}