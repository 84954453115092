import { Component, ComponentStyle } from "Bent";
import HintAbility, { IHint } from "lib/abilities/HintAbility";
import FlagIconComponent from "./FlagIconComponent";
import LabelComponent from "./LabelComponent";


export default class FlagLabelComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			display: 'inline-block',
			whiteSpace: 'nowrap',
			height: '20px',
		}
	}


	public constructor(parent: Component, languageId: string, text: string) {
		super(parent);
		let icon = new FlagLabelFlagComponent(this);
		icon.iconId = languageId;

		switch(languageId) {
			case 'de':
				icon.hint = 'Tysk';
				break;
			case 'da':
				icon.hint = 'Dansk';
			  	break;
			case 'en':
				icon.hint = 'Engelsk';
			  	break;
			case 'ro':
				icon.hint = 'Romænsk';
			  	break;
			case 'pl':
				icon.hint = 'Polsk';
			  	break;
			default:
			  	break;
		  }
		  
		new FlagLabelLabelComponent(this, text);

	}


}


class FlagLabelFlagComponent extends FlagIconComponent {

	private _hintAbility: HintAbility;
	public get hint(): string {
		return this._hintAbility ? this._hintAbility.hint : '';
	}
	public set hint(v: string) {
		if (!this._hintAbility) {
			this._hintAbility = this.createAbility(HintAbility);
		}
		this._hintAbility.hint = v;
	}

	public static readonly style: ComponentStyle = {
		default: {
			verticalAlign: 'middle',
			width: '',
		}
	}


}


class FlagLabelLabelComponent extends LabelComponent {

	public static readonly style: ComponentStyle = {
		default: {
			textTransform: 'none',
			paddingLeft: '2px',
			paddingRight: '12px',
		}
	}


	public constructor(parent: Component, text: string) {
		super(parent);
		this.text = text;

	}


}
