import { ValueType } from "lib/communication/staffz/ValueType";
import { ListCell, ListHeaderCell } from "lib/controls/ListControl";
import ListPageView from "lib/pages/ListPageView";
import LanguageOverviewForm from "./LanguageOverviewForm";


export default class LanguageOverviewView extends ListPageView<LanguageOverviewForm> {

	public populate(): void {
		this.form.header.caption.text = 'Liste over sprog';

	}


	public populateHeader(fieldName: string, headerCell: ListHeaderCell) {
		let t = {
			'flag': 'Flag',
			'id': 'id (ISO 639-1)',
			'englishName': 'Engelsk navn',
			'active': 'Aktiv',
		}
		headerCell.text = t[fieldName];

		let w = {
			'flag': '10%',
			'id': '10%',
			'englishName': '',
			'active': '10%',
		}
		headerCell.setStyle({
			width: w[fieldName],
		});


		super.populateHeader(fieldName, headerCell);

	}


	public populateCell(fieldName: string, rowIndex: number, cell: ListCell) {
		if (fieldName == 'flag') {
			let row = this.context.content[rowIndex];
			this.form.makeFlag(cell, row[this.context.fields['id'].contentIndex]);
			return;
		}
		super.populateCell(fieldName, rowIndex, cell);
	}


	protected getColumns(): string[] {
		let columns = super.getColumns();
		if (columns.indexOf('flag')<0) {
			columns.unshift('flag');
		}
		return columns;

	}



}