import { Ability, AbilityEventHandler, DOMListener } from "Bent";

export default class EscapableAbility extends Ability {

	protected _mouseOverHandler: DOMListener;

	protected _mouseOutHandler: DOMListener;

	protected _mouseDownHandler: DOMListener;

	private _keyDownHandler: DOMListener;


	private _isOutside: boolean = true;


	private _onEscape: AbilityEventHandler;
	public get onEscape(): AbilityEventHandler {
		return this._onEscape;
	}


	
	private _containerLevel : number = -1;
	public get containerLevel() : number {
		return this._containerLevel;
	}
	public set containerLevel(v : number) {
		this._containerLevel = v;
	}
	



	public constructor(element: HTMLElement) {
		super(element);
		this._onEscape = this.createEventHandler();
		this._mouseDownHandler = this.createGlobalDOMListener("mousedown", this.doMouseDown);
		this._mouseOverHandler = this.createDOMListener("mouseover", this.doMouseOver, true);
		this._mouseOutHandler = this.createDOMListener("mouseout", this.doMouseOut, true);
		this._keyDownHandler = this.createGlobalDOMListener("keydown", this.doKeyDown);


	}


	protected doEnable(): void {
		this._mouseDownHandler.enabled = true;
		this._mouseOverHandler.enabled = true;
		this._mouseOutHandler.enabled = true;
		this._keyDownHandler.enabled = true;

	}


	protected doMouseDown(e: MouseEvent) {
		if (this._isOutside) {
			let el = this.element;
			let cl = this._containerLevel;
			while(cl-- > 0) {
				el = el.parentNode as HTMLElement;
			}
			if (!el.contains(e.target as Node)) {
				this.onEscape.fire(this);
			}
		}

	}

	protected doMouseOver(e: MouseEvent) {
		this._isOutside = false;

	}


	protected doMouseOut(e: MouseEvent) {
		this._isOutside = true;

	}


	private doKeyDown(e: KeyboardEvent) {
		if (e.code == 'Escape') {
			this.onEscape.fire(this);
		}
	}
}