import { Component, ComponentEventHandler, ComponentStyle, Form } from "Bent";
import HtmlContentAbility from "lib/abilities/HtmlContentAbility";
import KeyboardClickAbility from "lib/abilities/KeyboardClickAbility";
import FadeInOutForm from "lib/forms/FadeInOutForm";


export default class PublicForm extends FadeInOutForm {

	public static readonly style: ComponentStyle = {
		default: {
			display: 'flex',
			padding: '5%',
			flexWrap: 'wrap',
			justifyContent: 'center',
			alignItems: 'center',
			font: '100% arial,sans-serif',
			overflowY: 'auto'
		}
	}


	private _staffzWelcomeTextComponent: StaffzWelcomeTextComponent;
	public get welcomeText(): string {
		return this._staffzWelcomeTextComponent.Text;
	}
	public set welcomeText(v: string) {
		this._staffzWelcomeTextComponent.Text = v;
	}


	private _counter = 0;


	private _onLoginTrigger: ComponentEventHandler;
	public get onLoginTrigger(): ComponentEventHandler {
		return this._onLoginTrigger;
	}


	public constructor(parent: Component) {
		super(parent);
		new StaffzLogoComponent(new FlexTopComponent(this));

		this._staffzWelcomeTextComponent = new StaffzWelcomeTextComponent(new FlexTopComponent(this));

		new FlexBreakComponent(this);

		new ScreenshotComponent(new FlexBottomComponent(this), '001');
		new ScreenshotComponent(new FlexBottomComponent(this), '002');
		new ScreenshotComponent(new FlexBottomComponent(this), '003');
		new ScreenshotComponent(new FlexBottomComponent(this), '004');
		new ScreenshotComponent(new FlexBottomComponent(this), '005');

		let ability = this.createAbility(KeyboardClickAbility);
		ability.enabled = true;
		this.listen(ability.onClick, this.doHandleKeyboardClick);
		this._onLoginTrigger = this.createEventHandler();


		// this._testButtonA = new TestButtonComponent(this);

	}


	private doHandleKeyboardClick(ability: KeyboardClickAbility) {
		this._counter += ability.key == '9' ? 1 : -this._counter;
		if (this._counter == 3) {
			this._onLoginTrigger.fire(this);
			this._counter = 0;
		}

	}


}


class StaffzLogoComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			maxHeight: '400px',
		}
	}


	public constructor(parent: Component) {
		super(parent, 'img');
		this.setAttribute('src', 'gfx/public/staffzLogo.svg');

	}


}


class StaffzWelcomeTextComponent extends Component {

	private _contentEditorAbility: HtmlContentAbility;

	public get Text(): string {
		return this._contentEditorAbility.htmlContent;
	}
	public set Text(v: string) {
		this._contentEditorAbility.htmlContent = v;
	}


	public constructor(parent: Component) {
		super(parent);
		this._contentEditorAbility = this.createAbility(HtmlContentAbility);
	}


}


class ScreenshotComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			display: 'block',
			maxWidth: '100%',
			height: 'auto',
		}
	}


	public constructor(parent: Component, number: string) {
		super(parent, 'img');
		this.setAttribute('src', 'gfx/public/' + number + '.png');

	}


}


class FlexBasisComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			flex: 'auto',
			margin: '2%',
		}
	}


}


class FlexTopComponent extends FlexBasisComponent {

	public static readonly style: ComponentStyle = {
		default: {
			width: '300px',
		}
	}


}


class FlexBreakComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			flexBasis: '100%',
			height: '0px',
		}
	}


}


class FlexBottomComponent extends FlexBasisComponent {

	public static readonly style: ComponentStyle = {
		default: {
			width: '150px',
			maxWidth: '60%',
		}
	}


}