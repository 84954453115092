import { Component, ComponentStyle } from "Bent";
import ActionComponent, { ActionComponentStyle, ActionLabelComponent } from "lib/components/ActionComponent";
import MenuComponent, { MenuGroupComponent } from "lib/components/MenuComponent";


export default class MenuControl extends MenuComponent {

	public static readonly style: ComponentStyle = {
		default: {
			display: 'grid',
			gridTemplateColumns: 'auto minmax(0px, min-content)',
			backgroundColor: '#dbe1e6',
			alignItems: 'self-end',
			paddingLeft: '6px',
		}
	}


	protected createGroup(groupNumber: number): MenuGroupComponent {
		return new MenuGroupControl(this);

	}


	protected createItem(group: MenuGroupComponent): ActionComponent {
		return new MenuItemControl(group);

	}


}


class MenuGroupControl extends MenuGroupComponent {

	public static readonly style: ComponentStyle = {
		default: {
			display: 'flex',
			alignItems: 'center',
			padding: '0px 2px',
		}
	}


}


class MenuItemControl extends ActionComponent {

	public static readonly style: ActionComponentStyle = {
		default: {
			display: 'flex',
			alignItems: 'center',
			borderTopLeftRadius: '2px',
			borderTopRightRadius: '2px',
			whiteSpace: 'nowrap',
			padding: '0px 4px',
			transition: 'color 0.2s, background-color 0.2s',
			height: '20px',
			margin: '0px 6px',
			color: '#8395A3',
		},
		'.hover': {
			backgroundColor: '',
			color: 'white',

		},
		'.pressed': {
			backgroundColor: '',
			color: '#0c5783',
		},
		'.down': {
			backgroundColor: '#FCFCFC',
			color: '#526A7B',
		}
	}


	public constructor(parent: Component) {
		super(parent);
		this.focusable = false;
	}



	protected createLabel(): ActionLabelComponent {
		return new MenuLabelControl(this);

	}


}


class MenuLabelControl extends ActionLabelComponent {

	public static readonly style: ComponentStyle = {
		default: {
			fontSize: '10px',
		}
	}


}

