import { Ability } from "Bent";

export default class HtmlContentAbility extends Ability {

	public get htmlContent(): string {
		return this.element.innerHTML;
	}
	public set htmlContent(v: string) {
		this.element.innerHTML = v;
	}


}