import { ControllerClass } from "Bent";
import SkillListCommunication from "lib/communication/SkillListCommunication";
import { IValueFields } from "lib/communication/staffz/IValueField";
import SearchPageController from "lib/pages/SearchPageController";
import AdminController from "module/admin/AdminController";
import SkillSearchForm from "./SkillSearchForm";
import SkillSearchView from "./SkillSearchView";


export default class SkillSearchController extends SearchPageController<SkillSearchView, SkillSearchForm> {

	private static _filter: IValueFields;

	public static get searchFilter(): IValueFields {
		return SkillSearchController._filter;
	}

	protected get filter() {
		return SkillSearchController._filter;
	}
	protected set filter(v: IValueFields) {
		SkillSearchController._filter = v;
	}


	public constructor(parent: ControllerClass) {
		super(parent, SkillSearchView, SkillSearchForm, new SkillListCommunication());

	}


	protected doSearch() {
		super.doSearch();
		(this.parent as AdminController).menuPath = 'staffz/skills/list';

	}


}