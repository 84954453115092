import { IHint } from "lib/abilities/HintAbility";
import { ListCell, ListHeaderCell } from "lib/controls/ListControl";
import ListPageView from "lib/pages/ListPageView";
import UserListForm from "./UserListForm";
import ProfileHintCommunication from "lib/communication/ProfileHintCommunication";


export default class UserListView extends ListPageView<UserListForm> {

	public populate(): void {
		this.form.header.caption.text = 'Brugere af Staffz mobile app';

	}


	public populateHeader(fieldName: string, headerCell: ListHeaderCell) {
		let t = {
			'id': 'Nummer',
			'name': 'Navn',
			'email': 'E-Mail',
			'phone': 'Telefon',
			'partners': 'Antal partnere',
			'lastLogin': 'Sidst logget ind',
			'appVersion': 'App version',
			'deviceName': 'Telefon type',
			'devicePlatform': 'Telefon OS',
			'pin': 'Pin kode',
		}
		headerCell.text = t[fieldName];

		let w = {
			'id': '5%',
			'name': '7%',
			'email': '',
			'phone': '5%',
			'partners': '50px',
			'lastLogin': '7%',
			'appVersion': '50px',
			'deviceName': '15%',
			'devicePlatform': '15%',
			'pin': '70px',
		}
		headerCell.setStyle({
			width: w[fieldName],
		});


		super.populateHeader(fieldName, headerCell);

	}


	public populateCell(fieldName: string, rowIndex: number, cell: ListCell) {
		let field = this.context.fields[fieldName];
		let row = this.context.content[rowIndex];
		cell.type = field.valueType;
		cell.content = row[field.contentIndex];

		if (fieldName == 'partners') {
			this.definePartnerHint(cell, row[this.context.fields['id'].contentIndex]);
		}

	}



	private definePartnerHint(cell: ListCell, id: string) {
		if (parseInt(cell.content) > 0) {
			cell.setHintLookup(id, (hint: IHint) => {
				ProfileHintCommunication.getPartners(hint.id, (partnere: string) => {
					hint.htmlText = partnere;
				});
			});
		} else {
			cell.hint = '<i>Ingen tilknyttede partnere</i>';
		}

	}


}