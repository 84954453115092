import { ValueType } from "lib/communication/staffz/ValueType";
import { ListCell, ListHeaderCell } from "lib/controls/ListControl";
import ListPageView from "lib/pages/ListPageView";
import DateUtils from "lib/utilities/DateUtils";
import EminiPiForm from "./EminiPiForm";


export default class EminiPiView extends ListPageView<EminiPiForm> {

	public destroy() {
		super.destroy();
	}

	public populate(): void {
		super.populate();

		this.form.header.caption.text = 'Emini pi ';

	}

	public populateHeader(fieldName: string, headerCell: ListHeaderCell) {

		let dates = this.getLatest7days();

		let t = {
			'systemName': 'Kunde',
			'company': 'Virksomhed',
			'companyLocation': 'Lokation',
			'deviceName': 'Enhedsnavn',
			'mode': 'Tilstand',
			'registrationMode': 'Registrerings tilstand',
			'eminiPiStatLogId': 'Sidste ping',
			'today': dates[0],
			'day1_ago': dates[1],
			'day2_ago': dates[2],
			'day3_ago': dates[3],
			'day4_ago': dates[4],
			'day5_ago': dates[5],
			'day6_ago': dates[6]
		}
		headerCell.text = t[fieldName];

		let w = {
			'systemName': '10px',
			'company': '10px',
			'companyLocation': '10px',
			'deviceName': '10px',
			'mode': '85px',
			'registrationMode': '10px',
			'eminiPiStatLogId': '10px',
			'today': '10px',
			'day1_ago': '10px',
			'day2_ago': '10px',
			'day3_ago': '10px',
			'day4_ago': '10px',
			'day5_ago': '10px',
			'day6_ago': '10px',
		}
		headerCell.setStyle({
			width: w[fieldName],
		});


		super.populateHeader(fieldName, headerCell);

	}

	private getLatest7days() {

		let d = new Date();
		let dates = [];
		for (let i = 0; i < 7; i++) {
			dates.push(d.getDate() + " / " + (d.getMonth() + 1));
			d = DateUtils.addDays(d, -1);
		}

		return dates;
	}

	public populateCell(fieldName: string, rowIndex: number, cell: ListCell) {
		if (fieldName == 'eminiPiStatLogId') {
			let field = this.context.fields[fieldName];
			let row = this.context.content[rowIndex];
			if (row[field.contentIndex] != '1900-01-01') {
				cell.type = field.valueType;
				cell.content = row[field.contentIndex];
			}
			return;
		}
		super.populateCell(fieldName, rowIndex, cell);

	}


}