import { Component, ComponentStyle } from "Bent";
import HtmlContentAbility from "lib/abilities/HtmlContentAbility";

export default class IconComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			width: '100%',
			height: '100%',
		}
	};

	protected get namespace(): string {
		return 'http://www.w3.org/2000/svg';
	};


	private _contentAbility: HtmlContentAbility;
	public get contentAbility():HtmlContentAbility{
		return this._contentAbility;
	}
	public set contentAbility(v: HtmlContentAbility){
		this._contentAbility = v;
	}

	private _filename: String;
	public get filename():String{
		return this._filename;
	}
	public set filename(v: String){
		this._filename = v;
	}

	private _iconId: string;
	public get iconId(): string {
		return this._iconId;
	}
	public set iconId(v: string) {
		if (v != this._iconId) {
			this._iconId = v;
			if (v != '') {
				this._contentAbility.htmlContent = '<use xlink:href="' + this._filename + '#' + v + '"></use>';
				// this.setStyle({ display: '' });
			} else {
				this._contentAbility.htmlContent = '';
				// this.setStyle({ display: 'none' });
			}
		}
	}


	public constructor(parent: Component, filename: string) {
		super(parent, 'svg');
		this._filename = filename;
		this.setAttribute('viewBox', '0 0 999 999');
		this._contentAbility = this.createAbility(HtmlContentAbility);
		this.iconId = '';

	}


}