import { Component, ComponentStyle } from "Bent";
import ActionComponent, { ActionComponentStyle, ActionIconComponent, ActionLabelComponent } from "lib/components/ActionComponent";
import MenuComponent, { MenuGroupComponent } from "lib/components/MenuComponent";


export default class ModuleMenuControl extends MenuComponent {

	public static readonly style: ComponentStyle = {
		default: {
			display: 'grid',
			gridTemplateColumns: 'auto minmax(0px, min-content)',
			backgroundColor: '#333',
		}
	}


	protected createGroup(groupNumber: number): MenuGroupComponent {
		return new ModuleMenuGroupControl(this);

	}


	protected createItem(group: MenuGroupComponent): ActionComponent {
		return new ModuleMenuItemControl(group);

	}


}


class ModuleMenuGroupControl extends MenuGroupComponent {

	public static readonly style: ComponentStyle = {
		default: {
			display: 'flex',
			alignItems: 'center',
			padding: '6px',
		}
	}


}


class ModuleMenuItemControl extends ActionComponent {

	public static readonly style: ActionComponentStyle = {
		default: {
			display: 'flex',
			alignItems: 'center',
			borderRadius: '2px',
			whiteSpace: 'nowrap',
			background: '#444',
			paddingLeft: '2px',
			paddingRight: '6px',
			transition: 'color 0.2s, background-color 0.2s',
			height: '30px',
			margin: '0px 6px',
			color: '#CCC',
			fill: '#777',
		},
		'.hover': {
			backgroundColor: '#777',
			color: '',
			fill: '#CCC',

		},
		'.pressed': {
			backgroundColor: '#AAA',
			color: '',
			fill: '#CCC',
		},
		'.down': {
			backgroundColor: '#0c5783',
			color: 'white',
			fill: 'white',
		},

	}


	public constructor(parent: Component) {
		super(parent);
		this.focusable = false;
	}


	protected createIcon(): ActionIconComponent {
		return new ModuleMenuIconControl(this);

	}


	protected createLabel(): ActionLabelComponent {
		return new ModuleMenuLabelControl(this);

	}


}


class ModuleMenuLabelControl extends ActionLabelComponent {

	public static readonly style: ComponentStyle = {
		default: {
			marginLeft: '4px',
			fontWeight: 'lighter',
		}
	}


}


class ModuleMenuIconControl extends ActionIconComponent {

	public static readonly style: ComponentStyle = {
		default: {
			display: 'inline-block',
			width: '16px',
			height: '30px',
			marginLeft: '4px',
		}
	}


}
