import { PropertyCellLabelComponent } from "lib/controls/PropertyGridControl";
import UserSearchForm from "./UserSearchForm";
import SearchPageView from "lib/pages/SearchPageView";


export default class UserSearchView extends SearchPageView<UserSearchForm> {

	public populate(): void {
		this.form.header.caption.text = 'Søgekriterier';
		this.form.resetButton.setContent(null, 'Nulstil');
		this.form.searchButton.setContent(null, 'Søg');

	}


	public populateFieldCaption(fieldName: string, labelCell: PropertyCellLabelComponent) {
		let t = {
			'name': 'Navn',
			'email': 'E-Mail',
			'phone': 'Telefon',
			'partner': 'Partnere',
		}
		labelCell.text = t[fieldName];

	}


}