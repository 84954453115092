import { Component, ComponentStyle, Form } from "Bent";
import HtmlContentAbility from "lib/abilities/HtmlContentAbility";
import NavigationControl, { NavigationButtonComponent } from "lib/controls/NavigationControl";
import PropertyGridControl, { PropertyCellComponent } from "lib/controls/PropertyGridControl";


export default class ConfirmDeleteProfileForm extends Form {

	public static readonly style: ComponentStyle = {
		default: {
			display: 'flex',
			userSelect: 'none',
			padding: '5%',
			flexWrap: 'wrap',
			justifyContent: 'center',
			alignItems: 'center',
			overflowY: 'auto',
			margin: '0',			
		}
	}

	private _deleteProfileText: DeleteProfileText;
	public get deleteProfileText(): string {
		return this._deleteProfileText.Text;
	}
	public set deleteProfileText(v: string) {
		this._deleteProfileText.Text = v;
	}

	public constructor(parent: Component) {
		super(parent);
		let ftc = new FlexTopComponent(this);

		ftc.setStyle({
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		});

		new StaffzLogoComponent(ftc);
	}

}

class DeleteProfileText extends Component {

	private _contentEditorAbility: HtmlContentAbility;

	public get Text(): string {
		return this._contentEditorAbility.htmlContent;
	}
	public set Text(v: string) {
		this._contentEditorAbility.htmlContent = v;
	}


	public constructor(parent: Component) {
		super(parent);
		this._contentEditorAbility = this.createAbility(HtmlContentAbility);

	}


}

class FlexBasisComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			flex: 'auto',
			margin: '2%',
		}
	}


}


class FlexTopComponent extends FlexBasisComponent {

	public static readonly style: ComponentStyle = {
		default: {
			width: '300px',
		}
	}


}


class StaffzLogoComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			maxHeight: '400px',
		}
	}


	public constructor(parent: Component) {
		super(parent, 'img');
		this.setAttribute('src', 'gfx/public/staffzLogo.svg');

	}


}

class FlexBreakComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			flexBasis: '100%',
			height: '0px',
		}
	}


}