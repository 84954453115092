import { View } from "Bent";
import MainForm from "./MainForm";

export default class MainView extends View<MainForm> {


	public populate(): void {

	}


}