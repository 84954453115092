import { Component, ComponentStyle } from "Bent";

export default class TextComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			fontFamily: 'Arial, Helvetica Neue, Helvetica, sans-serif',
			fontWeight: 'bold',
			fontSize: '11px',

		}
	}


}