import { Ability, DOMListener } from "Bent";

export default abstract class PointerAbility extends Ability {

	protected _mouseOverHandler: DOMListener;
	protected _mouseOutHandler: DOMListener;
	protected _mouseDownHandler: DOMListener;
	protected _mouseMoveHandler: DOMListener;
	protected _mouseUpHandler: DOMListener;


	private _ctrlDown: boolean;
	public get ctrlDown(): boolean {
		return this._ctrlDown == true;
	}


	public constructor(element: HTMLElement) {
		super(element);
		this._mouseOverHandler = this.createDOMListener("mouseover", this.doMouseOver);
		this._mouseOutHandler = this.createDOMListener("mouseout", this.doMouseOut);
		this._mouseDownHandler = this.createDOMListener("mousedown", this.doMouseDown);
		this._mouseMoveHandler = this.createGlobalDOMListener("mousemove", this.doMouseMove);
		this._mouseUpHandler = this.createGlobalDOMListener("mouseup", this.doMouseUp);

	}


	protected doEnable(): void {
		this._mouseOverHandler.enabled = true;
		this._mouseDownHandler.enabled = true;

	}


	protected doDisable(): void {
		super.doDisable();
	}


	protected doMouseOver(e: MouseEvent) {
		this.updateSpecialKeys(e);
		this._mouseOverHandler.enabled = false;
		this._mouseOutHandler.enabled = true;

	}


	protected doMouseOut(e: MouseEvent) {
		this.updateSpecialKeys(e);
		this._mouseOutHandler.enabled = false;
		this._mouseOverHandler.enabled = true;

	}


	protected doMouseDown(e: MouseEvent) {
		this.updateSpecialKeys(e);
		this._mouseDownHandler.enabled = false;
		this._mouseMoveHandler.enabled = true;
		this._mouseUpHandler.enabled = true;

	}


	protected doMouseMove(e: MouseEvent) {
		this.updateSpecialKeys(e);

	}


	protected doMouseUp(e: MouseEvent) {
		this.updateSpecialKeys(e);
		this._mouseMoveHandler.enabled = false;
		this._mouseUpHandler.enabled = false;
		this._mouseDownHandler.enabled = true;
	}


	public updateSpecialKeys(e: MouseEvent) {
		if (e.ctrlKey) {
			if (!this._ctrlDown) {
				this._ctrlDown = true;
			}
		} else {
			if (this._ctrlDown) {
				delete this._ctrlDown;
			}
		}
	}


}