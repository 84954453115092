import { Component, ComponentStyle } from "Bent";
import ActionComponent, { ActionComponentStyle, ActionLabelComponent } from "lib/components/ActionComponent";
import MenuComponent, { MenuGroupComponent } from "lib/components/MenuComponent";

export default class MainMenuControl extends MenuComponent {

	public static readonly style: ComponentStyle = {
		default: {
			display: 'grid',
			gridTemplateColumns: 'auto minmax(0px, min-content)',
			backgroundColor: '#0c5783',
			alignItems: 'self-end',
		}
	}


	protected createGroup(groupNumber: number): MenuGroupComponent {
		return new MainMenuGroupControl(this);

	}


	protected createItem(group: MenuGroupComponent): ActionComponent {
		return new MainMenuItemControl(group);

	}


}


class MainMenuGroupControl extends MenuGroupComponent {

	public static readonly style: ComponentStyle = {
		default: {
			display: 'flex',
			alignItems: 'center',
			padding: '0px 2px',
		}
	}


}


class MainMenuItemControl extends ActionComponent {

	public static readonly style: ActionComponentStyle = {
		default: {
			display: 'flex',
			alignItems: 'center',
			borderTopLeftRadius: '2px',
			borderTopRightRadius: '2px',
			whiteSpace: 'nowrap',
			padding: '0px 4px',
			transition: 'color 0.2s, background-color 0.2s',
			height: '25px',
			margin: '0px 6px',
			color: '#DBE6ED',
		},
		'.hover': {
			backgroundColor: '',
			color: 'white',

		},
		'.pressed': {
			backgroundColor: '',
			color: '#FFFFCC',
		},
		'.down': {
			backgroundColor: '#dbe1e6',
			color: '#526A7B',
		},
	};


	public constructor(parent: Component) {
		super(parent);
		this.focusable = false;
	}


	protected createLabel(): ActionLabelComponent {
		return new MainMenuLabelControl(this);

	}


}


class MainMenuLabelControl extends ActionLabelComponent {

	public static readonly style: ComponentStyle = {
		default: {
			fontSize: '10px',
		}
	};


}

