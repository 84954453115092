import { View } from "Bent";
import { MenuDefinition } from "lib/components/MenuComponent";
import AdminForm from "./AdminForm";


export default class AdminView extends View<AdminForm> {

	private static readonly ADMIN_MENU: MenuDefinition = [{
		'sysmenu': {
			iconId: 'system', radio: false
		},
		'staffz': {
			caption: 'Staffz', iconId: 'staffz',
			subMenu: [{
				'status': {
					caption: 'Status',
					subMenu: [{
						'job': { caption: 'Cron job' },
						'sysevent': { caption: 'Systemhandlinger' },
						'syslog': { caption: 'System Log' },
					}]
				},
				'statistic': {
					caption: 'Statistik',
					subMenu: [{
						'users': { caption: 'Brugere' },
						'os': { caption: 'Android vs IOS' },
						'workshiftoffers': { caption: 'Udbudte vagter' },
						'disseminations': { caption: 'Vagtformidlinger' },
						'signeddocs': { caption: 'Signeringer' },
					}]
				},
				'skills': {
					caption: 'Kvalifikationer',
					subMenu: [{
						'search': { caption: 'Søg' },
						'list': { caption: 'Resultat' },
						'new': { caption: 'Ny', radio: false }
					}]
				},
				'language': {
					caption: 'Sprog',
					subMenu: [{
						'overview': { caption: 'Oversigt' },
						'translation': { caption: 'Oversættelser' },
					}]
				},
				'eminiPi': {
					caption: 'Emini pi\'es',
					subMenu: [{
						'eminiPiData': { caption: 'Emini pi' }
					}]
				}
			}, { // left
				'admin': {
					caption: 'Administration',
					subMenu: [{
						'registry': { caption: 'Nøgler' }
					}]
				},
			}]
		},
		'users': {
			caption: 'Brugere', iconId: 'user',
			subMenu: [{
				'search': {
					caption: 'Søg',
					subMenu: [{
						'search': { caption: 'Søg' },
						'result': { caption: 'Resultat' },
						'history': { caption: 'Historik' },
					}]
				},
				'lists': {
					caption: 'Lister & Profiler',
					subMenu: [{
						'focuslist': { caption: 'Fokuslister' },
						'searchprofiles': { caption: 'Søgeprofiler' },
					}]
				}
			}, {
				'calendar': {
					caption: 'Kalender',
					subMenu: [{
						'day': { caption: 'Dag' },
						'month': { caption: 'Måned' },
					}]
				},
				'identityCard': {
					caption: 'Stamkort',
					subMenu: [{
						'identity': { caption: 'Identitet' },
					}]
				},
				'workshiftoffers': {
					caption: 'Vagttilbud',
					subMenu: [{
						'open': { caption: 'Åbne' },
						'accepted': { caption: 'Accepterede' },
						'denied': { caption: 'Afviste' },
					}]
				},
				'documents': {
					caption: 'Dokumenter',
					subMenu: [{
						'documents': { caption: 'Dokumenter' },
						'payslips': { caption: 'Lønsedler' },
					}]
				},
				'statistic': {
					caption: 'Statistik',
					subMenu: [{
						'usage': { caption: 'Brug' },
						'devices': { caption: 'Enheder' },
						'log': { caption: 'Log' },
					}]
				},

			}]
		},
		'agencies': {
			caption: 'Bureauer', iconId: 'agency',
			subMenu: [{
				'overview': {
					caption: 'Oversigt',
					subMenu: [{
						'overview': { caption: 'Oversigt' },
						'history': { caption: 'Historik' },
					}]
				},
				'new': {
					caption: 'Ny',
					subMenu: [{
						'Ny': { caption: 'Ny', radio: false }
					}]
				},
			}, {
				'calendar': {
					caption: 'Kalender',
					subMenu: [{
						'day': { caption: 'Dag' },
						'month': { caption: 'Måned' },
					}]
				},
				'identityCard': {
					caption: 'Stamkort',
					subMenu: [{
						'identity': { caption: 'Identitet' },
					}]
				},
				'statistic': {
					caption: 'Statistik',
					subMenu: [{
						'usage': { caption: 'Brug' },
						'devices': { caption: 'Enheder' },
						'log': { caption: 'Log' },
					}]
				},
			}]
		},
	}];


	public populate(): void {
		let moduleMenu = AdminView.ADMIN_MENU;
		this.makeRadio(moduleMenu);
		this.form.moduleMenu.setMenuItems(moduleMenu);

	}


	public updateMainMenu(moduleId: string) {
		let moduleMenu = AdminView.ADMIN_MENU;
		let mainMenu = this.subMenuById(moduleMenu, moduleId);
		if (mainMenu) {
			this.makeRadio(mainMenu);
			this.form.mainMenu.setMenuItems(mainMenu);
		}

	}


	public updateMenu(moduleId: string, mainMenuId: string) {
		let moduleMenu = AdminView.ADMIN_MENU;
		let mainMenu = this.subMenuById(moduleMenu, moduleId);
		if (mainMenu) {
			let menu = this.subMenuById(mainMenu, mainMenuId);
			if (menu) {
				this.makeRadio(menu);
				this.form.menu.setMenuItems(menu);
			}
		}

	}


	private makeRadio(menu: MenuDefinition): MenuDefinition {
		for (let group in menu) {
			for (let id in menu[group]) {
				let item = menu[group][id];
				if (item.radio == undefined) {
					item.radio = true;
				}
			}
		};
		return null;

	}


	private subMenuById(menu: MenuDefinition, menuId: string): MenuDefinition {
		for (let group in menu) {
			for (let id in menu[group]) {
				if (id == menuId) {
					return menu[group][id].subMenu;
				}
			}
		}
		return null;
	}


}