import { Component, ComponentStyle } from "Bent";
import AdminPageForm from "lib/forms/AdminPageForm";
import ListControl from "lib/controls/ListControl";
import HeaderControl, { HeaderButtonComponent } from "lib/controls/HeaderControl";
import NavigationControl from "lib/controls/NavigationControl";
import PagerControl from "lib/controls/PagerControl";

export default class ListPageForm extends AdminPageForm {

	public static readonly style: ComponentStyle = {
		default: {
			width: 'calc(100%-24px)',
			height: 'calc(100%-24px)',
			padding: '12px'
		}
	}


	private _header: HeaderControl;
	public get header(): HeaderControl {
		return this._header;
	}


	private _helpButton: HeaderButtonComponent;
	public get helpButton(): HeaderButtonComponent {
		return this._helpButton;
	}
	public set helpButton(v: HeaderButtonComponent) {
		this._helpButton = v;
	}


	private _list: ListPageControl;
	public get list(): ListPageControl {
		return this._list;
	}



	private _footer: ListPageFooterControl;
	public get footer(): ListPageFooterControl {
		return this._footer;
	}


	private _pager: PagerControl;
	public get pager(): PagerControl {
		return this._pager;
	}



	public constructor(parent: Component) {
		super(parent);
		this._header = new HeaderControl(this);
		this._helpButton = this._header.addButton();
		this._helpButton.setContent('help', '', 'help');
		this._list = new ListPageControl(this);
		this._footer = new ListPageFooterControl(this);
		this._pager = new PagerControl(this._footer);
	}


}


class ListPageControl extends ListControl {

	public static readonly style: ComponentStyle = {
		default: {
			overflow: 'auto',
			height: 'calc(100% - 75px)',
		}

	}


}


class ListPageFooterControl extends NavigationControl {

	public static readonly style: ComponentStyle = {
		default: {
			height: '46px',
			backgroundColor: '#dbe1e6',
			borderBottomLeftRadius: '2px',
			borderBottomRightRadius: '2px',
			textAlign: 'center',
		}
	}

}

