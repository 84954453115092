import { Controller, ControllerClass } from "Bent";
import MenuComponent from "lib/components/MenuComponent";
import AdminForm from "./AdminForm";
import AdminView from "./AdminView";
import UnderContructionController from "./UnderContructionController";
import UsersControllerFactory from "module/users/UsersControllerFactory";
import AgenciesControllerFactory from "module/agencies/AgenciesControllerFactory";
import StaffzControllerFactory from "module/staffz/StaffzControllerFactory";

export default class AdminController extends Controller<AdminView, AdminForm> {

	private _lastOpen: AdminNavigationMemory;


	private _activeModule: string;
	public get activeModule(): string {
		return this._activeModule;
	}
	public set activeModule(v: string) {
		if (v != this._activeModule) {
			this._activeModule = v;
			this.view.updateMainMenu(this._activeModule);
		}
	}


	private _activeMainMenu: string;
	public get activeMainMenu(): string {
		return this._activeMainMenu;
	}
	public set activeMainMenu(v: string) {
		if (v != this._activeMainMenu) {
			this._activeMainMenu = v;
			this._lastOpen.setMainId(this.activeModule, this.activeMainMenu);
			this.view.updateMenu(this._activeModule, this._activeMainMenu);
		}
	}


	private _activeMenu: string;
	public get activeMenu(): string {
		return this._activeMenu;
	}
	public set activeMenu(v: string) {
		if (v != this._activeMenu) {
			this._activeMenu = v;
			this._lastOpen.setMenuId(this.activeModule, this.activeMainMenu, this.activeMenu);
			this.doMenuActivate();
		}
	}



	public get menuPath(): string {
		return [this.activeModule, this.activeMainMenu, this.activeMenu].join('/');
	}
	public set menuPath(v: string) {
		let m = v.split('/');
		this.activeModule = m[0];
		this.activeMainMenu = m[1];
		// this.activeMenu = m[2];
		this.view.form.menu.select(m[2]);

	}



	private _page: ControllerClass;
	public get page(): ControllerClass {
		return this._page;
	}
	public set page(v: ControllerClass) {
		if (this._page) {
			this._page.destroy();
			delete this._page;
		}
		if (v) {
			this._page = v;
			this.page.active = true;
		}
	}


	public constructor(parent: ControllerClass) {
		super(parent, AdminView, AdminForm);
		this._lastOpen = new AdminNavigationMemory();
		this.listen(this.view.form.moduleMenu.onActivate, this.doModuleMenuSelect);
		this.listen(this.view.form.mainMenu.onActivate, this.doMainMenuSelect);
		this.listen(this.view.form.menu.onActivate, this.doMenuSelect);
	}


	protected doActivate() {
		super.doActivate();
		this.view.form.moduleMenu.selectFirstRadioButton();

	}


	public refresh() {
		super.refresh();
		if (this.page) {
			this.page.refresh();
		}
	}



	private doModuleMenuSelect(sender: MenuComponent) {
		this.activeModule = sender.radioDownId;

		if (this._lastOpen.hasMainId(this.activeModule)) {
			this.view.form.mainMenu.select(this._lastOpen.getMainId(this.activeModule));
		} else {
			this.view.form.mainMenu.selectFirstRadioButton();
		}

	}


	private doMainMenuSelect(sender: MenuComponent) {
		if (this.activeMainMenu == sender.radioDownId) {
			return;
		}
		this.activeMainMenu = sender.radioDownId;
		this._activeMenu = '';
		if (this._lastOpen.hasMenuId(this.activeModule, this.activeMainMenu)) {
			this.view.form.menu.select(this._lastOpen.getMenuId(this.activeModule, this.activeMainMenu));
		} else {
			this.view.form.menu.selectFirstRadioButton();
		}

	}


	private doMenuSelect(sender: MenuComponent) {
		if (this.activeMenu != sender.radioDownId) {
			this.activeMenu = sender.radioDownId;

		} else if (sender.radioDownId != sender.activatedId) {
			if (this.activeModule == 'users') {
				UsersControllerFactory.activateDialog(this, this.activeMainMenu, sender.activatedId);
			} else if (this.activeModule == 'agencies') {
				AgenciesControllerFactory.activateDialog(this, this.activeMainMenu, sender.activatedId);
			} else if (this.activeModule == 'staffz') {
				StaffzControllerFactory.activateDialog(this, this.activeMainMenu, sender.activatedId);
			}
		}
	}


	private doMenuActivate() {
		let c: ControllerClass = null;

		if (this.activeModule == 'users') {
			c = UsersControllerFactory.getController(this, this.activeMainMenu, this.activeMenu);
		} else if (this.activeModule == 'agencies') {
			c = AgenciesControllerFactory.getController(this, this.activeMainMenu, this.activeMenu);
		} else if (this.activeModule == 'staffz') {
			c = StaffzControllerFactory.getController(this, this.activeMainMenu, this.activeMenu);
		}

		if (c == null) {
			c = new UnderContructionController(this);
			(c as UnderContructionController).location = this.menuPath;
		}
		this.page = c;
	}


}


interface LastOpenMenuIds {
	[mainId: string]: string;
}


interface LastOpenMainIds {
	lastOpenMainId: string;
	lastOpenMenuIds: LastOpenMenuIds;
}


interface LastOpenModuleIds {
	[moduleId: string]: LastOpenMainIds;
}


class AdminNavigationMemory {

	private _lastActiveSubMenu: LastOpenModuleIds = {};


	public getMainId(moduleId: string): string {
		if (this.hasMainId(moduleId)) {
			return this._lastActiveSubMenu[moduleId].lastOpenMainId;
		}
		return '';
	}


	public setMainId(moduleId: string, mainId: string) {
		this.ensureModule(moduleId);
		this._lastActiveSubMenu[moduleId].lastOpenMainId = mainId;

	}


	public hasMainId(moduleId: string): boolean {
		return (Object.keys(this._lastActiveSubMenu).indexOf(moduleId) != -1)
			&& (this._lastActiveSubMenu[moduleId].lastOpenMainId != '');

	}


	public getMenuId(moduleId: string, mainId: string): string {
		if (this.hasMenuId(moduleId, mainId)) {
			return this._lastActiveSubMenu[moduleId].lastOpenMenuIds[mainId];
		}
		return '';

	}


	public setMenuId(moduleId: string, mainId: string, menuId: string) {
		this.ensureMain(moduleId, mainId);
		this._lastActiveSubMenu[moduleId].lastOpenMenuIds[mainId] = menuId;

	}


	public hasMenuId(moduleId: string, mainId: string): boolean {
		return this.hasMainId(moduleId)
			&& (Object.keys(this._lastActiveSubMenu[moduleId].lastOpenMenuIds).indexOf(mainId) != -1)
			&& (this._lastActiveSubMenu[moduleId].lastOpenMenuIds[mainId] != '');

	}


	private ensureModule(moduleId: string) {
		if (!this._lastActiveSubMenu[moduleId]) {
			this._lastActiveSubMenu[moduleId] = { lastOpenMainId: '', lastOpenMenuIds: {} };
		}

	}


	private ensureMain(moduleId: string, mainId: string) {
		this.ensureModule(moduleId);
		if (!this._lastActiveSubMenu[moduleId].lastOpenMenuIds[mainId]) {
			this._lastActiveSubMenu[moduleId].lastOpenMenuIds[mainId] = '';
		}

	}


}