import { Ability } from "Bent";
import Vector from "lib/utilities/Vector";

export default class CenterCoordAbility extends Ability {

	public get toCenterTranslation(): Vector {
		let c: DOMRect = this.element.getBoundingClientRect();
		let cCenter = Vector.div(new Vector(c.width, c.height), 2);
		return Vector.sub(this.parentCenter, cCenter);

	}


	public get parentCenter(): Vector {
		let p: DOMRect = this.element.parentElement.getBoundingClientRect();
		return Vector.div(new Vector(p.width, p.height), 2);

	}

}