import StaffzPortal from "./staffz/StaffzPortal";
import { IContractRequest, IContractResponse } from "./staffz/IContract";


export interface IProfileHintContractRequest extends IContractRequest {
	contract: 'ProfileHint',
	id: string;
	what: 'partners' | 'unknown';
}


export interface IProfileHintContractResponse extends IContractResponse {
	hint: string;
}


export default class ProfileHintCommunication extends StaffzPortal {


	public get request(): IProfileHintContractRequest {
		return super.request;
	}
	public set request(v: IProfileHintContractRequest) {
		super.request = v;
	}

	public get response(): IProfileHintContractResponse {
		return super.response;
	}


	private execute(contract: IProfileHintContractRequest, onGotHint: (hint: string) => void) {
		this.request = contract;
		this.listen(this.onSucces, () => {
			onGotHint(this.response.hint);
			this.destroy();
		});
		this.call();
	}


	static getPartners(profileId: string, onGotPartners: (partners: string) => void) {
		let instance = new ProfileHintCommunication;
		instance.execute({
			contract: 'ProfileHint',
			id: profileId,
			what: 'partners'
		}, onGotPartners);
	}



}