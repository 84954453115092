import { Component, ComponentStyle, Form } from "Bent";
import DialogForm from "lib/forms/DialogForm";
import LayerComponent from "lib/components/LayerComponent";


export default class MainForm extends Form {

	public static readonly style: ComponentStyle = {
		default: {
			userSelect: 'none',
			borderCollapse: 'collapse',
			margin: '0px',
			padding: '0px',
			width: '100vw',
			height: '100vh',
		}
	}


	public constructor(parent: Component) {
		super(parent);
		let client = new ClientLayer(this);
		Form.modalPush(client);
		DialogForm.defaultParent = new DialogLayer(this);
		let toastLayer = new ToastLayer(this);
		this.client = client;

	}


}




class ClientLayer extends LayerComponent { }


class DialogLayer extends LayerComponent { }


class ToastLayer extends LayerComponent { }
