import HintControl from "lib/controls/HintControl";
import PointerAbility from "./PointerAbility";


export interface IHint {
	id: string;
	htmlText: string;
}


export default class HintAbility extends PointerAbility {

	private _mouseX: number;

	private _mouseY: number;


	private _id: string;
	public get id(): string {
		return this._id;
	}
	public set id(v: string) {
		this._id = v;
	}


	private _hint: string;
	public get hint(): string {
		return this._hint;
	}
	public set hint(v: string) {
		this._hint = v;
		if (this._hintControl) {
			this._hintControl.htmlText = v;
		}
	}


	private _hintTimer: NodeJS.Timeout;
	protected get hintTimer(): boolean {
		return this._hintTimer != null;
	}
	protected set hintTimer(v: boolean) {
		if (v != this.hintTimer) {
			if (v) {
				this._hintTimer = setTimeout(() => {
					delete this._hintTimer;
					this.hintShowning = true;
				}, 500);
			} else {
				clearTimeout(this._hintTimer);
				delete this._hintTimer;
			}
		}
		if (!v) {
			this.hintShowning = false;
		}
	}


	private _hintControl: HintControl;
	public get hintShowning(): boolean {
		return (this._hintControl != null);
	}
	public set hintShowning(v: boolean) {
		if (v != this.hintShowning) {
			if (v) {
				if ((!this.hint) && this.onLookupHint) {
					this._hintControl = new HintControl(this.id, this._mouseX + 12, this._mouseY + 12);
					this.onLookupHint(this._hintControl);

				} else if (this._hint != '') {
					this._hintControl = new HintControl(this.id, this._mouseX + 12, this._mouseY + 12);
					this._hintControl.htmlText = this.hint;

				} else {
					this._mouseOverHandler.enabled = false;
					this._mouseOutHandler.enabled = false;
					this._mouseMoveHandler.enabled = false;
					this.hintTimer = false;
				}
			} else {
				if (!this.hint) {
					this.hint = this._hintControl.htmlText;
				}
				this._hintControl.destroy();
				delete this._hintControl;
			}
		}
	}


	private _onLookupHint: (hintLookup: IHint)=>void;
	public get onLookupHint(): (hintLookup: IHint)=>void {
		return this._onLookupHint;
	}
	public set onLookupHint(v: (hintLookup: IHint)=>void) {
		this._onLookupHint = v;
	}


	public constructor(element: HTMLElement) {
		super(element);
		super.doEnable();
	}


	public destroy() {
		this.hintTimer = false;
		this.hintShowning = false;
		super.destroy();
	}

	protected doDisable(): void {
		super.doDisable();
		this.hintTimer = false;

	}


	protected doMouseOver(e: MouseEvent) {
		super.doMouseOver(e);
		this.hintTimer = true;
		this._mouseMoveHandler.enabled = true;
	}


	protected doMouseOut(e: MouseEvent) {
		super.doMouseOut(e);
		this.hintTimer = false;
		this._mouseMoveHandler.enabled = false;

	}


	protected doMouseDown(e: MouseEvent) {
		super.doMouseDown(e);
		this.hintTimer = false;

	}


	protected doMouseMove(e: MouseEvent) {
		super.doMouseMove(e);
		this._mouseX = e.pageX;
		this._mouseY = e.pageY;
		this.hintShowning = false;
	}


	protected doMouseUp(e: MouseEvent) {
		super.doMouseUp(e);
		this.hintTimer = false;

	}




}