import { View } from "Bent";
import { IValueFields } from "lib/communication/staffz/IValueField";
import { EditPopulator } from "lib/controls/EditControl";
import { PropertyCellLabelComponent } from "lib/controls/PropertyGridControl";
import SkillEditForm from "./SkillEditForm";


export default class SkillEditView extends View<SkillEditForm> implements EditPopulator {


	private _fields: IValueFields;
	public get fields(): IValueFields {
		return this._fields;
	}
	public set fields(v: IValueFields) {
		this._fields = v;
		this.form.editFields.populate(v, Object.keys(v).length, this);
		this.form.editFields.focusFirst();
	}


	public populateFieldCaption(fieldName: string, labelCell: PropertyCellLabelComponent) {
		if (fieldName.startsWith('lang_')) {
			let lang = fieldName.substr(5);
			this.form.makeFlag(labelCell, lang);
			return;
		}

		let t = {
			'skillId': 'Nummer',
			'createdBy': 'oprettet af',
			'lastEditedBy': 'Sidst rettet af',
			'created': 'Oprettet den',
			'modified': 'Sidst rettet',
			'status': 'Status',
			'skillCategoryId': 'Kategori',
		}
		labelCell.text = t[fieldName];

	}


	public populate(): void {
		this.form.title = 'Rediger Kvalifikation';
		this.form.saveButton.setContent(null, 'Gem');
		this.form.saveCloseButton.setContent(null, 'Gem og luk');

	}



}