import EntityCommunication from "./staffz/EntityCommunication";
import { EntityActionType } from "./staffz/IEntityContract";


export default class SkillCommunication extends EntityCommunication {

	public get action(): EntityActionType {
		return this.request.action;
	}
	public set action(v: EntityActionType) {
		this.request.action = v;
	}


	public get skillId(): string {
		return this.request.fields && this.request.fields['skillId'] ? this.request.fields['skillId'].value : null;
	}
	public set skillId(v: string) {
		if (!this.request.fields) {
			this.request.fields = {};
		}
		if (!this.request.fields['skillId']) {
			this.request.fields['skillId'] = {};
		}
		this.request.fields['skillId'].value = v;
	}


	public constructor() {
		super();
		this.request = { contract: 'Skill' };

	}


}