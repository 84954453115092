import { ControllerClass } from "Bent";
import OverviewController from "./overview/OverviewController";


export default class AgenciesControllerFactory {

	public static getController(parent: ControllerClass, mainMenu: string, menu: string): ControllerClass {
		if (mainMenu == 'overview') {
			if (menu=='overview') {
				return new OverviewController(parent);
			}
		}
		return null;

	}


	public static activateDialog(parent: ControllerClass, mainMenu: string, menu: string) {
	}

}