import { View } from "Bent";
import { ValueType } from "lib/communication/staffz/ValueType";
import { ServerLocation } from "lib/communication/staffz/StaffzPortal";
import LoginForm from "./LoginForm";


export default class LoginView extends View<LoginForm> {

	public populate(): void {
		this.form.title = 'Adgang til administration';

		this.form.login.value.type = ValueType.string;
		this.form.login.caption = 'Brugernavn';
		this.form.login.value.focus();

		this.form.password.caption = 'Adgangskode';
		this.form.password.value.type = ValueType.password;

		this.form.server.caption = 'server';
		this.form.server.value.type = ValueType.select;

		let choises: { [key in ServerLocation]?: string } = {
			[ServerLocation.prod]: 'Drift',
			[ServerLocation.dev]: 'Test - Udviklingserver',
			[ServerLocation.bnLocal]: 'Test - Lokalmaskine(BN)',
			[ServerLocation.skjLocal]: 'Test - Lokalmaskine(SKJ)'
		};

		this.form.server.value.choises = choises;

		this.form.loginButton.setContent(null, 'Log på');
		this.form.cancelButton.setContent(null, 'Annuller');

		this.form.server.value.content = ServerLocation.prod;

		if (window.hasOwnProperty('defaultCredits')) {
			//@ts-ignore
			this.form.login.value.content = window.defaultCredits.login;
			//@ts-ignore
			this.form.password.value.content = window.defaultCredits.password;
			this.form.password.value.focus();
			this.form.server.value.content = ServerLocation.skjLocal;
			setTimeout(()=>{
				this.form.loginButton.activate();
			}, 500);
		}

	}


}