import { Controller, ControllerClass } from "Bent";
import AuthentificationCommunication from "lib/communication/AuthentificationCommunication";
import LoginForm from "./LoginForm";
import LoginView from "./LoginView";
import CRC32 from "lib/utilities/CRC32";

export default class LoginController extends Controller<LoginView, LoginForm> {

	public constructor(parent: ControllerClass) {
		super(parent, LoginView, LoginForm);

		this.listen(this.view.form.loginButton.onActivate, this.doLogin);
		this.listen(this.view.form.cancelButton.onActivate, this.destroy);
		this.listen(this.view.form.dialogCloseButton.onActivate, this.destroy);

	}


	private doLogin() {
		let location = this.view.form.server.value.content;
		console.log(location);
		let login = this.view.form.login.value.content;
		let passwordHash = CRC32.fromString(this.view.form.password.value.content);
		this.destroy();
		new AuthentificationCommunication(location, login, passwordHash);

	}


}