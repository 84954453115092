import { Controller } from "Bent";
import MainController from "module/main/MainController";
import DeleteProfileController from "module/DeleteProfile/DeleteProfileController";
import ConfirmDeleteProfileController from "module/DeleteProfile/ConfirmDeleteProfileController";

let file = document.location;
if(file.pathname == '/deleteProfile.html'){
	Controller.run(DeleteProfileController);
}else if(file.pathname == '/confirmDeleteProfile.html'){
	Controller.run(ConfirmDeleteProfileController);
}else{
	Controller.run(MainController);
}