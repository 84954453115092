import { Destructable } from "./Destructable";

export class Event<T extends object> extends Destructable {

	private _listener: (sender: T) => void;


	public constructor(listener: (sender: T) => void) {
		super();
		this._listener = listener;
	}


	public fire(sender: T): void {
		this._listener(sender);
	}


}