import { Component, ComponentEventHandler, ComponentStyle } from "Bent";
import HtmlContentAbility from "lib/abilities/HtmlContentAbility";
import { IListView } from "lib/communication/staffz/IListContract";
import { ValueType } from "lib/communication/staffz/ValueType";
import ActionComponent, { ActionComponentStyle, ActionIconComponent } from "lib/components/ActionComponent";
import ButtonComponent from "lib/components/ButtonComponent";
import LabelComponent from "lib/components/LabelComponent";
import ValueComponent from "lib/components/ValueComponent";

export default class PagerControl extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			fontFamily: 'Arial, Helvetica Neue, Helvetica, sans-serif',
			fontWeight: 'bold',
			fontSize: '11px',
			textTransform: 'uppercase',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			display: 'inline-block',
			color: '#99A5AD',
			fill: '#99A5AD',
		}

	}

	private _navigator: PagerNavigatorComponent;

	private _status: PagerStatusComponent;
	public get status(): PagerStatusComponent {
		return this._status;
	}


	private _onGotoPage: ComponentEventHandler;
	public get onGotoPage(): ComponentEventHandler {
		return this._onGotoPage;
	}


	private _onRefresh: ComponentEventHandler;
	public get onRefresh(): ComponentEventHandler {
		return this._onRefresh;
	}

	private _onChangePageSize: ComponentEventHandler;
	public get onChangePageSize(): ComponentEventHandler {
		return this._onChangePageSize;
	}


	constructor(parent: Component) {
		super(parent);
		this._onGotoPage = this.createEventHandler();
		this._onRefresh = this.createEventHandler();
		this._onChangePageSize = this.createEventHandler();

	}


	public populate(view: IListView) {
		if (this._navigator) {
			this._navigator.destroy();
			delete this._navigator;
		}
		this._navigator = new PagerNavigatorComponent(this, view, this._onGotoPage);

		if (this._status) {
			this._status.destroy();
			delete this._status;
		}
		this._status = new PagerStatusComponent(this, view);
		this.listen(this.status.refreshButton.onActivate, this.doRefresh);
		this.listen(this.status.pageSizeInput.onDefault, this.doPageSize);
	}


	private doRefresh() {
		this.onRefresh.fire(this);

	}


	private doPageSize(sender: PagerStatusPageSizeInputComponent) {
		this.onChangePageSize.fire(sender);

	}

}


class PagerNavigatorComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			lineHeight: '22px',
		}
	}

	private _GotoPageEvent: ComponentEventHandler;


	constructor(parent: Component, view: IListView, onGotoPageEvent: ComponentEventHandler) {
		super(parent);
		this._GotoPageEvent = onGotoPageEvent;

		let pageNumber = view.pageNumber;
		let pageSize = view.pageSize;
		let totalRowCount = view.totalRowCount;

		let prevButton = new PagerNavigatorPrevButtonComponent(this, pageNumber - 1);
		prevButton.caption = 'Forrige';
		prevButton.enabled = pageNumber > 0;
		if (prevButton.enabled) {
			this.listen(prevButton.onActivate, this.doGotoPage);
		}

		let maxPageCount = Math.ceil(totalRowCount / pageSize);

		for (let pi = 0; pi < maxPageCount; pi++) {
			if (pi == pageNumber) {
				new PagerNavigatorActivePageComponent(this, pageNumber + 1);
				continue;
			} else if (pi > 0 && pi < pageNumber - 2) {
				new PagerNavigatorSkipComponent(this);
				pi = pageNumber - 2;
			} else if (pi > pageNumber + 2 && pi > 3) {
				new PagerNavigatorSkipComponent(this);
				pi = maxPageCount - 1;
			}
			let pb = new PagerNavigatorButtonComponent(this, pi);
			pb.caption = (pi + 1).toString();
			this.listen(pb.onActivate, this.doGotoPage);
		}

		let nextButton = new PagerNavigatorNextButtonComponent(this, pageNumber + 1);
		nextButton.caption = 'Næste';
		nextButton.enabled = pageNumber < maxPageCount - 1;
		if (nextButton.enabled) {
			this.listen(nextButton.onActivate, this.doGotoPage);
		}


	}


	public destroy() {
		delete this._GotoPageEvent;
		super.destroy();

	}


	public doGotoPage(sender: PagerNavigatorButtonComponent) {
		this._GotoPageEvent.fire(sender);

	}


}


export class PagerNavigatorButtonComponent extends ButtonComponent {

	public static readonly style: ComponentStyle = {
		default: {
			padding: '0px 2px',
			margin: '2px',
			minWidth: '16px',
		},
		'.disabled': {
			color: '#bfc5ca',
			fill: '#bfc5ca',
		},
		'.hover': {
			color: '#557287',
			fill: '#557287',
		}

	}


	private _contentAbility: HtmlContentAbility;


	public get caption(): string {
		return this._contentAbility.htmlContent;
	}
	public set caption(v: string) {
		this._contentAbility.htmlContent = v;
	}


	private _pageNumber: number;
	public get pageNumber(): number {
		return this._pageNumber;
	}


	public constructor(parent: Component, pageNumber: number) {
		super(parent, 'span');
		this._contentAbility = this.createAbility(HtmlContentAbility);
		this._pageNumber = pageNumber;

	}

}


class PagerNavigatorPrevButtonComponent extends PagerNavigatorButtonComponent {

	private _caption: LabelComponent;
	public get caption(): string {
		return this._caption.text;
	}
	public set caption(v: string) {
		this._caption.text = v;
	}


	public constructor(parent: Component, pageNumber: number) {
		super(parent, pageNumber);
		new PagerNavigatorIconComponent(this, 'pagePrev');
		this._caption = new LabelComponent(this);

	}


}


class PagerNavigatorNextButtonComponent extends PagerNavigatorButtonComponent {


	private _caption: LabelComponent;
	public get caption(): string {
		return this._caption.text;
	}
	public set caption(v: string) {
		this._caption.text = v;
	}


	public constructor(parent: Component, pageNumber: number) {
		super(parent, pageNumber);
		this._caption = new LabelComponent(this);
		new PagerNavigatorIconComponent(this, 'pageNext');

	}


}


class PagerNavigatorIconComponent extends ActionIconComponent {

	public static readonly style: ComponentStyle = {
		default: {
			height: '8px',
			width: '16px',
		}
	}

	public constructor(parent: Component, iconId: string) {
		super(parent);
		this.iconId = iconId;
	}
}



class PagerNavigatorActivePageComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			backgroundColor: "white",
			borderRadius: '2px',
			border: '1px solid ',
			padding: '0px 2px',
			margin: '2px',
			minWidth: '16px',
		}
	}


	public constructor(parent: Component, pageNumber: number) {
		super(parent, 'span');
		let ca = this.createAbility(HtmlContentAbility);
		ca.htmlContent = pageNumber.toString();
	}

}


class PagerNavigatorSkipComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			padding: '0px 2px',
			margin: '2px',
		}
	}


	public constructor(parent: Component) {
		super(parent, 'span');
		let ca = this.createAbility(HtmlContentAbility);
		ca.htmlContent = '&middot;&middot;&middot;';
	}


}


class PagerStatusComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			whiteSpace: 'nowrap',
		}
	}


	private _refreshButton: PagerStatusRefreshButton;
	public get refreshButton(): PagerStatusRefreshButton {
		return this._refreshButton;
	}



	private _pageSizeInput : PagerStatusPageSizeInputComponent;
	public get pageSizeInput() : PagerStatusPageSizeInputComponent {
		return this._pageSizeInput;
	}



	constructor(parent: Component, view: IListView) {
		super(parent);

		let fromItem = (1 + view.pageNumber * view.pageSize);
		let toItem = ((1 + view.pageNumber) * view.pageSize);
		if (toItem > view.totalRowCount) {
			toItem = view.totalRowCount;
		}

		let itemStatus = new LabelComponent(this, 'span');
		itemStatus.text = fromItem.toString() + ' til ' + toItem.toString() + ' af ' + view.totalRowCount.toString() + ' resultater';

		this._refreshButton = new PagerStatusRefreshButton(this);
		this._refreshButton.setContent('refresh', null, 'refresh');


		this._pageSizeInput = new PagerStatusPageSizeInputComponent(this, view.pageSize);


		let itemStatus2 = new LabelComponent(this, 'span');
		itemStatus2.text = ' rækker/side';


		// let a = this.createAbility(HtmlContentAbility);
		// a.htmlContent =
		// 	'<span class="refresh fas fa-sync-alt">&#9851;</span>' +
		// 	'<input id="pageSizeInput" type="text" class="pageSizeInput" size="3" maxlength="3" title="Gyldig type af værdier (2-250)">' +
		// 	'<span id="pageSizeLabel" class="pageSizeLabel">rækker/side</span>';




	}

}


class PagerStatusRefreshButton extends ActionComponent {

	public static readonly style: ActionComponentStyle = {
		default: {
			display: 'inline-block',
			width: "9px",
			height: "9px",
			transition: 'background-color 0.3s',
			alignItems: 'center',
			padding: '0px 4px',
		},
		'.hover': {
			fill: '#557287',
		},
		'.updating': {
			animation: 'rotation 2s infinite linear',
		}

	}


	public get updating(): boolean {
		return this.hasClass('updating');
	}
	public set updating(v: boolean) {
		this.setClass('updating', v);
	}

	public constructor(parent: Component) {
		super(parent, 'span');

	}


	protected createIcon(): ActionIconComponent {
		return new ActionIconComponent(this);

	}


}


export class PagerStatusPageSizeInputComponent extends ValueComponent {

	public static readonly style: ActionComponentStyle = {
		default: {
			display: 'inline-block',
			height: '12px',
			lineHeight: '',
			width: "30px",
			border: '1px solid #99A5AD',
			textIndent: '',
		},
		' input': {
			width: 'calc(100% - 4px)',
			height: 'calc(100% - 2px)',
			textAlign: 'center',
			color: '#99A5AD',
			background: 'none'
		},
		'.vtr': {
			paddingRight: '',
		}
	}


	public constructor(parent: Component, value: number) {
		super(parent);
		this.type = ValueType.int;
		this.content = value;

	}


}
