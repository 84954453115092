import { Component, ComponentEventHandler, DestructableCollection } from "Bent";
import ActionComponent from "lib/components/ActionComponent";

export interface IMenuItem {
	caption?: string;
	iconId?: string;
	radio?: boolean;
	subMenu?: MenuDefinition;
}

export interface IMenuGroup {
	[id: string]: IMenuItem;
}

export type MenuDefinition = IMenuGroup[];


export default abstract class MenuComponent extends Component {

	protected _groups: DestructableCollection<MenuGroupComponent>;;
	protected _buttons: ActionComponent[];


	private _onActivate: ComponentEventHandler;
	public get onActivate(): ComponentEventHandler {
		return this._onActivate;
	}


	private _radioDownId: any;
	public get radioDownId(): any {
		return this._radioDownId;
	}


	private _activatedId: any;
	public get activatedId(): any {
		return this._activatedId;
	}


	public constructor(parent: Component) {
		super(parent);
		this._onActivate = this.createEventHandler();
		this.listen(this.onActivate, this.doUpdateRadioButtons);

	}


	public setMenuItems(def: MenuDefinition) {
		this.enabled = false;
		if (this._groups) {
			this._groups.destroy();
			delete this._groups;
		}
		this._groups = new DestructableCollection<MenuGroupComponent>();

		if (this._buttons) {
			while (this._buttons.length>0) {
				this._buttons.pop();
			};
			delete this._buttons;
		}
		this._buttons = [];

		let groupNo = 0;
		if (def) {
			def.forEach((items: IMenuGroup) => {
				let group = this.createGroup(groupNo);
				this._groups.add(group);
				group.groupNumber = groupNo;
				groupNo++;
				for(let id in items) {
					let item = items[id];
					let btn = this.createItem(group);
					btn.setContent(id, item.caption, item.iconId, item.radio);
					this.listen(btn.onActivate, this.doItemActivate);
					this._buttons.push(btn);
				}
			});
		}
		this.enabled = true;
	}


	protected abstract createGroup(groupNumber: number): MenuGroupComponent;

	protected abstract createItem(group: MenuGroupComponent): ActionComponent;


	public select(id: string) {
		this._radioDownId = id;
		this._onActivate.fire(this);
	}


	public selectFirstRadioButton() {
		for(let btn of this._buttons) {
			if (btn.radio) {
				this.select(btn.id);
				return;
			}
		}

	}


	private doItemActivate(sender: ActionComponent) {
		this._activatedId = sender.id;
		if (sender.radio) {
			this.select(sender.id);
		} else {
			this._onActivate.fire(this);
		}

	}


	private doUpdateRadioButtons() {
		for(let btn of this._buttons) {
			btn.down = btn.radio && (btn.id == this.radioDownId);
		}

	}


}


export class MenuGroupComponent extends Component {

	private _groupNumber: number;
	public get groupNumber(): number {
		return this._groupNumber;
	}
	public set groupNumber(v: number) {
		this._groupNumber = v;
	}


}