import { ControllerClass } from "Bent";
import SkillListForm from "./SkillListForm";
import SkillListView from "./SkillListView";
import SkillListCommunication from "lib/communication/SkillListCommunication";
import ListPageController from "lib/pages/ListPageController";
import SkillEditController from "./SkillEditController";
import SkillSearchController from "./SkillSearchController";
import ListCommunication from "lib/communication/staffz/ListCommunication";


export default class SkillListController extends ListPageController<SkillListView, SkillListForm> {

	private _skillEdit: SkillEditController;

	public get communication(): SkillListCommunication {
		return super.communication;
	}


	public constructor(parent: ControllerClass) {
		super(parent, SkillListView, SkillListForm, new SkillListCommunication());
		this.view.onEditSkill = this.doEditSkill.bind(this);
		this.listen(this.view.form.btnDelete.onActivate, this.doDelete);
		this.listen(this.view.form.btnMerge.onActivate, this.doMerge);
		this.listen(this.view.form.btnChangeStatus.onActivate, this.doChangeStatus);

	}


	public doActivate() {
		this.communication.request.filter = SkillSearchController.searchFilter;
		super.doActivate();

	}


	protected doRefresh() {
		this.communication.request.filter = SkillSearchController.searchFilter;
		super.doRefresh();

	}


	protected handleNewContext(sender: ListCommunication) {
		super.handleNewContext(sender);
		delete this.communication.request.command;
		delete this.communication.request.commandIds;
		delete this.communication.request.newStatusId;

	}


	private doEditSkill(id: string) {
		this._skillEdit = new SkillEditController(this, id);
		this.listen(this._skillEdit.onDestroy, this.doEditSkillDone);
		this._skillEdit.active = true;
	}


	private doEditSkillDone() {
		delete this._skillEdit;
		this.refresh();

	}


	private doDelete() {
		this.communication.request.command = 'delete';
		this.communication.request.commandIds = this.view.getCheckedIds();
		this.communication.call();
	}


	private doMerge() {
		this.communication.request.command = 'merge';
		this.communication.request.commandIds = this.view.getCheckedIds();
		this.communication.call();
	}


	private doChangeStatus() {
		this.communication.request.command = 'setStatus';
		this.communication.request.commandIds = this.view.getCheckedIds();
		this.communication.request.newStatusId = this.view.form.statusChangeTo.content;
		this.communication.call();
	}




}