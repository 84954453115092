import { Controller, ControllerClass } from "Bent";
import OverviewForm from "./OverviewForm";
import OverviewView from "./OverviewView";


export default class OverviewController extends Controller<OverviewView, OverviewForm> {


	public constructor(parent: ControllerClass) {
		super(parent, OverviewView, OverviewForm);

	}


}