import { Component, ComponentEventHandler, ComponentStyle } from "Bent";
import { ButtonState } from "lib/abilities/ButtonAbility";
import { ActionIconComponent } from "./ActionComponent";
import ButtonComponent from "./ButtonComponent";


export default class CheckComponent extends ButtonComponent {

	public static readonly style: ComponentStyle = {
		default: {
			width: '12px',
			height: '12px',
			backgroundColor: '#FFF',
		}
	}


	private _icon: ActionIconComponent;

	private _checked : boolean;
	public get checked(): boolean {
		return this._checked !== undefined;
	}
	public set checked(v: boolean) {
		if (v != this.checked) {
			if (v) {
				this._checked = true;
			} else {
				delete this._checked;
			}
			this._icon.iconId = v ? 'checkboxChecked': 'checkbox';
		}
	}

	private _onChange: ComponentEventHandler;
	public get onChange(): ComponentEventHandler {
		return this._onChange;
	}


	constructor (parent: Component) {
		super(parent);
		this._onChange = this.createEventHandler();
		this._icon = new ActionIconComponent(this);
		this._icon.iconId = 'checkbox';
		this.focusable = true;
		this.listen(this.buttonAbility.onStateChanged, this.doStateChanged);

	}


	private doStateChanged() {
		if (this.buttonAbility.state == ButtonState.pressed) {
			this.checked = !this.checked;
			this.doChanged();
		}
	}


	protected doChanged() {
		this.onChange.fire(this);
	}



}