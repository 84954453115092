import { Destructable } from "./Destructable";
import { Event } from "./Event";
import { EventHandlerClass } from "./EventHandler";

export class EventListener extends Destructable {


	protected listen(handler: EventHandlerClass, listener: (sender: object) => void): void {
		handler.add(new Event<Event<object>>(listener.bind(this)));

	}


}


