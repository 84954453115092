import { AbilityEventHandler, DOMListener } from "Bent";
import Vector from "lib/utilities/Vector";
import PointerAbility from "./PointerAbility";

export enum ButtonState {
	normal = 0,
	hover = 1,
	pressed = 2,
	down = 3,
};


export default class ButtonAbility extends PointerAbility {

	private _keyDownHandler: DOMListener;

	private _keyUpHandler: DOMListener;


	private _state: ButtonState = ButtonState.normal;
	public get state(): ButtonState {
		return this._state;
	}

	private _pressed: boolean;

	private _hover: boolean;


	private _down: boolean;
	public get down(): boolean {
		return this._down == true;
	}
	public set down(v: boolean) {
		if (v != this.down) {
			if (v) {
				this._down = true;
			} else {
				delete this._down;
			}
			this.updateState();
		}
	}


	private _mouseDownAt: Vector;


	private _onActivate: AbilityEventHandler;
	public get onActivate(): AbilityEventHandler {
		return this._onActivate;
	}


	private _onStateChanged: AbilityEventHandler;
	public get onStateChanged(): AbilityEventHandler {
		return this._onStateChanged;
	}


	public constructor(element: HTMLElement) {
		super(element);
		this._keyDownHandler = this.createDOMListener("keydown", this.doKeyDown);
		this._keyUpHandler = this.createDOMListener("keyup", this.doKeyUp);


		this._onActivate = this.createEventHandler();
		this._onStateChanged = this.createEventHandler();

		this._mouseDownAt = new Vector();

	}


	protected doEnable() {
		super.doEnable();
		this._keyDownHandler.enabled = true;

	}


	protected doDisable(): void {
		super.doDisable();
		delete this._hover;
		delete this._pressed;
		this.updateState();

	}


	private updateState() {
		let newState = ButtonState.normal;
		if (this.down) {
			newState = ButtonState.down;
		} else if (this.enabled) {
			if (this._pressed) {
				newState = ButtonState.pressed;
			} else if (this._hover) {
				newState = ButtonState.hover;
			}
		}

		if (newState != this._state) {
			this._state = newState;
			this.setClass('down', newState == ButtonState.down);
			this.setClass('hover', newState == ButtonState.hover);
			this.setClass('pressed', newState == ButtonState.pressed);

			this.onStateChanged.fire(this);
			document.getSelection().removeAllRanges();
		}

	}


	protected doMouseOver(e: MouseEvent) {
		super.doMouseOver(e);
		this._hover = true;
		this.updateState();

	}


	protected doMouseOut(e: MouseEvent) {
		super.doMouseOut(e);
		delete this._hover;
		this.updateState();

	}


	protected doMouseDown(e: MouseEvent) {
		super.doMouseDown(e);
		this._mouseDownAt.setValues(e.x, e.y);
		this._pressed = true;
		this.updateState();

	}


	protected doMouseMove(e: MouseEvent) {
		super.doMouseMove(e);
		let p = new Vector(e.x, e.y);
		if (Vector.distance(this._mouseDownAt, p) > 8) {
			delete this._pressed;
			this.updateState();
		}

	}


	protected doMouseUp(e: MouseEvent) {
		super.doMouseUp(e);
		let fire = this._pressed;
		delete this._pressed;
		this.updateState();
		if (fire) {
			this._onActivate.fire(this);
		}

	}


	protected doKeyDown(e: KeyboardEvent) {
		this._keyDownHandler.enabled = false;
		this._keyUpHandler.enabled = true;
		if (e.code == 'Enter' || e.code == 'NumpadEnter' || e.code == 'Space') {
			this._pressed = true;
			this.updateState();
		}

	}


	protected doKeyUp(e: KeyboardEvent) {
		this._keyUpHandler.enabled = false;
		this._keyDownHandler.enabled = true;
		let fire = this._pressed;
		delete this._pressed;
		this.updateState();
		if (fire) {
			this._onActivate.fire(this);
		}

	}


}