import { DestructableCollection } from "./DestructableCollection";
import { Event } from "./Event";

export type EventHandlerClass = EventHandler<Event<object>>;

export class EventHandler<T extends Event<Object>> extends DestructableCollection<T>{

	public fire(sender: object): void {
		this.forEach((o: T) => {
			o.fire(sender);
		});

	}


}