import { View } from "Bent";
import PublicForm from "./PublicForm";

export default class PublicView extends View<PublicForm> {


	public populate(): void {
		this.form.welcomeText =
			'<h1>Staffz</h1>'+
			'<h2>Din vikar App</h2>'+
			'<p>Staffz er en App til vikaransatte, hvor du kan få adgang til arbejdsplaner, dokumenter, og lønsedler fra de forskellige vikar bureauer, som du måtte arbejde for.</p>'+
			'<p>Det er din App og det er dig der bestemmer, hvilke vikar bureauer, som du ønsker at få informationer fra.</p>'+
			'<p>Start med at installere Staffz og opret dig som bruger. Derefter kan du kontakte dit vikar bureau for oplysninger om, hvdan du bliver koblet op hos dem</p>'+
			'<p>Hvis du arbejder for flere vikar bureauer, så får du altså samlet alle informationer samme sted</p>'+
			'<p>Det er derfor, at Staffz er din App...</p>';


		// this.form.testButtonA.Text = '(A) Toggle B enable';
		// this.form.testButtonB.Text = '(B) ' + (this.form.testButtonA.visible ? 'Hide' : 'Show') + ' A';
		// this.form.testButtonC.Text = '(C) close all';

	}


}