import { Component, ComponentEventHandler, ComponentStyle } from "Bent";
import HintAbility, { IHint } from "lib/abilities/HintAbility";
import HtmlContentAbility from "lib/abilities/HtmlContentAbility";
import { IOptions } from "lib/communication/staffz/IField";
import { ValueType } from "lib/communication/staffz/ValueType";
import DateUtils from "lib/utilities/DateUtils";
import ButtonComponent from "./ButtonComponent";
import CheckComponent from "./CheckComponent";
import DropdownComponent, { DropdownButtonComponent } from "./DropdownComponent";
import DropDownMultipleComponent from "./DropDownMultipleComponent";
import InputComponent from "./InputComponent";
import TextComponent from "./TextComponent";
import { __ } from "lib/lang/TranslatetionControl";


export default class ValueComponent extends TextComponent {

	public static readonly style: ComponentStyle = {
		default: {
			position: 'relative',
			display: 'grid',
			gridTemplateColumns: 'auto repeat(2, minmax(0px, min-content))',
			borderRadius: '2px',
			lineHeight: '16px',
			color: '#526A7B',
			textIndent: '2px',
			userSelect: 'text',
		},
		'.vtr': {
			textAlign: 'right',
			whiteSpace: 'nowrap'
		},
		'.vtc': {
			textAlign: 'center',
			whiteSpace: 'nowrap'
		},
		'.disabled': {
			userSelect: 'none',
		}

	}

	private _input: ValueInputComponent;

	private _checkComponent: ValueCheckComponent;

	private _dropdownButton: DropdownButtonComponent;

	private _readonlyContent: HtmlContentAbility;

	private _readonlyButton: ValueReadonlyButtonComponent;

	private _hintAbility: HintAbility;
	public get hint(): string {
		return this._hintAbility ? this._hintAbility.hint : '';
	}
	public set hint(v: string) {
		if (!this._hintAbility) {
			this._hintAbility = this.createAbility(HintAbility);
		}
		this._hintAbility.hint = v;
	}


	public get input(): ValueInputComponent{
		return this._input;
	}

	private _placeholder : string;
	public get placeholder() : string {
		return this._placeholder;
	}
	public set placeholder(v : string) {
		if (this._placeholder != v) {
			this._input.valueComponent._input._element.setAttribute('placeholder', v); 
		}
	}

	private _type: ValueType;
	public get type(): ValueType {
		return this._type;
	}
	public set type(v: ValueType) {
		if (this._type != v) {
			this._type = v;
			this.setClass('vtc', [ValueType.check, ValueType.yesNo].indexOf(v) >= 0);
			this.setClass('vtr', [ValueType.date, ValueType.time, ValueType.dateTime, ValueType.int, ValueType.float, ValueType.currency].indexOf(v) >= 0);
			this.update();
		}
	}


	private _readonly: boolean = false;
	public get readonly(): boolean {
		return this._readonly;
	}
	public set readonly(v: boolean) {
		if (this._readonly != v) {
			this._readonly = v;
			this.update();
		}
	}



	private _readonlyIsButton: boolean;
	public get readonlyIsButton(): boolean {
		return this._readonlyIsButton;
	}
	public set readonlyIsButton(v: boolean) {
		if (this._readonlyIsButton != v) {
			this._readonlyIsButton = v;
			this.update();
		}
	}


	public addContent(id: string) {
		if (!Array.isArray(this._content)) {
			this._content = [];
		}
		if (this._content.indexOf(id) >= 0) {
			this._content.splice(this._content.indexOf(id), 1);

			this.update();
			return;
		}
		this._content[this._content.length] = id;
		this.update();
	}

	private _content: any;
	public get content(): any {
		return this._content;
	}
	public set content(v: any) {
		if (this._content != v) {
			this._content = v;
			this.update();
		}
	}


	private _choises: IOptions;
	public get choises(): IOptions {
		return this._choises;
	}
	public set choises(v: IOptions) {
		this._choises = v;
	}


	private _dropdownComponent: DropdownComponent;
	public get dropdownComponent(): DropdownComponent {
		return this._dropdownComponent;
	}

	private _dropDownMultipleComponent: DropDownMultipleComponent;
	public get dropDownMultipleComponent(): DropDownMultipleComponent {
		return this._dropDownMultipleComponent;
	}

	public get droppingDown(): boolean {
		if (this.type == ValueType.selectMultiple) {
			return this._dropDownMultipleComponent != undefined;
		}
		return this._dropdownComponent != undefined;
	}
	public set droppingDown(v: boolean) {
		if (v != this.droppingDown) {
			if (this.type != ValueType.select && this.type != ValueType.selectMultiple) {
				return;
			}
			if (v) {
				this.doCreateDropdown();
			} else {
				if (this.type == ValueType.selectMultiple) {
					this._dropDownMultipleComponent.destroy();
					delete this._dropDownMultipleComponent;
					this._input.focused = true;
					this._input.dropdownBehavior = false;
					if (this._input.content == '') {
						this.content = '';
					}
					this.update();

				} else {
					this._dropdownComponent.destroy();
					delete this._dropdownComponent;
					this._input.focused = true;
					this._input.dropdownBehavior = false;
					if (this._input.content == '') {
						this.content = '';
					}
					this.update();
				}
			}
		}
	}


	private _onDefault: ComponentEventHandler;

	public get onDefault(): ComponentEventHandler {
		return this._onDefault;
	}



	private _onReadOnlyClick: ComponentEventHandler;
	public get onReadOnlyClick(): ComponentEventHandler {
		return this._onReadOnlyClick;
	}



	public constructor(parent: Component, tagName: string = 'div') {
		super(parent, tagName);

		this._onDefault = this.createEventHandler();
		this._onReadOnlyClick = this.createEventHandler();
		this._readonlyContent = this.createAbility(HtmlContentAbility);

	}


	protected doEnable() {
		super.doEnable();
		this.update();

	}


	protected doDisable() {
		super.doDisable();
		this.update();

	}


	private update() {
		if (this._type == undefined) {
			return;
		}


		if (this.type == ValueType.check) {
			if (this._checkComponent == undefined) {
				this._checkComponent = new ValueCheckComponent(this);
			}
			this._checkComponent.checked = (this.content === true) || (this.content === 1);
		} else {
			if (this._checkComponent !== undefined) {
				this._checkComponent.destroy();
				delete this._checkComponent;
			}
		}


		let isNormalInput = ([
			ValueType.date,
			ValueType.dateTime,
			ValueType.eMail,
			ValueType.float,
			ValueType.int,
			ValueType.phone,
			ValueType.password,
			ValueType.currency,
			ValueType.ssn,
			ValueType.select,
			ValueType.selectMultiple,
			ValueType.string,
			ValueType.translateableString,
			ValueType.time,
			ValueType.url,
		].indexOf(this._type) >= 0);

		let useInput = isNormalInput && !this.readonly;

		if (useInput) {
			if (!this._input) {
				this._input = new ValueInputComponent(this);
				this.listen(this._input.onFocusChanged, this.doInputFocusChanged);
				this.listen(this._input.onDefault, this.doDefault);
			}
			let showDropdownButton = ([ValueType.select, ValueType.selectMultiple].indexOf(this._type) >= 0);
			if (showDropdownButton && !this._dropdownButton) {
				this._dropdownButton = new DropdownButtonComponent(this);
				this._dropdownButton.setContent(null, '', 'select');
				this.listen(this._dropdownButton.onActivate, this.toggleDropdown);
			}
			this._input.password = this._type == ValueType.password;

		} else {
			if (this._input) {
				this._input.destroy();
				delete this._input;
			}
			if (this._dropdownButton) {
				this._dropdownButton.destroy();
				delete this._dropdownButton;
			}
		}

		let useReadonlyButton = this.readonlyIsButton && this.readonly && isNormalInput;

		if (useReadonlyButton) {
			if (!this._readonlyButton) {
				this._readonlyButton = new ValueReadonlyButtonComponent(this);
				this.listen(this._readonlyButton.onActivate, this.doReadOnlyClick);
			}
		} else {
			if (this._readonlyButton) {
				this._readonlyButton.destroy();
				delete this._readonlyButton;
			}
		}


		if (isNormalInput) {
			let textContent: string = '';

			switch (this._type) {
				case ValueType.int:
					textContent = this.content ? this.content : '0';
					break;

				case ValueType.dateTime:
					if (this.content) {
						let d = DateUtils.createDateFromMySQLDate(this.content);
						textContent = DateUtils.formatPeopletrustDate(d) + ' ' + DateUtils.formatPeopletrustTime(d);
					}
					break;


				case ValueType.string:
					if (this.content) {
						textContent = this.content;
					}
					break;

				case ValueType.translateableString:
					if (this.content) {
						textContent = __(this.content);
					}
					break;

				case ValueType.password:
					if (this.content) {
						textContent = this.readonly ? '&bull;'.repeat(this.content.length) : this.content;
					}
					break;

				case ValueType.select:
					textContent = (this._content && this._choises.hasOwnProperty(this._content)) ? this.choises[this._content] : '';
					break;

				case ValueType.selectMultiple:
					let names: string[] = [];
					if (this._choises) {
						Object.keys(this._choises).forEach(key => {
							if (this._content && this._content.indexOf(key) >= 0) {
								names.push(this._choises[key]);
							}
						});
					}
					textContent = names.join(', ');
					break;

				default:
					textContent = this.content + ':type' + this.type;
			}
			if (this._input) {
				this._input.content = textContent;

			} else if (this._readonlyButton) {
				this._readonlyButton.text = textContent;

			} else {
				this._readonlyContent.htmlContent = textContent;
			}

		}


		if (this.type == ValueType.yesNo) {
			this._readonlyContent.htmlContent = this.content == 1 ? 'Ja' : 'Nej';

		}


	}


	public focus() {
		if (this._input) {
			this._input.focused = true;
		}

	}


	private doInputFocusChanged(sender: Component) {
		switch (this._type) {
			case ValueType.select:
				break;

			case ValueType.selectMultiple:
				break;

			default:
				this._content = this._input.content;
		}
		// if(this.selectPopup && !this._input.focused /*(!document.activeElement.classList.contains('ValueInputComponent'))*/ ) {
		// 	this.selectPopup = false;
		// }

	}

	protected doCreateDropdown() {
		if (this.type == ValueType.selectMultiple) {
			this._dropDownMultipleComponent = new DropDownMultipleComponent(this);
		} else {
			this._dropdownComponent = new DropdownComponent(this);
		}
		this._input.dropdownBehavior = true;
	}


	private doDefault() {
		this.doInputFocusChanged(this);
		this.onDefault.fire(this);

	}


	public toggleDropdown() {
		if (this._type == ValueType.select || this._type == ValueType.selectMultiple) {
			if (!this._input.focused) {
				this.focus();
			}
			this.droppingDown = !this.droppingDown;
		}

	}


	public setHintLookup(id: string, lookupFunc: (hint: IHint) => void) {
		this.hint = null;
		this._hintAbility.id = id;
		this._hintAbility.onLookupHint = lookupFunc;

	}


	private doReadOnlyClick() {
		this.onReadOnlyClick.fire(this);
	}


}


class ValueInputComponent extends InputComponent {

	public static readonly style: ComponentStyle = {
		default: {
			color: '#526A7B',
			height: '18px',
			backgroundColor: 'white',
		},
		'.disabled': {
			color: '#999'
		}
	}


	private _valueComponent: ValueComponent;
	public get valueComponent(): ValueComponent {
		return this._valueComponent;
	}


	public get dropdownBehavior(): boolean {
		return this.inputAbility.dropdownBehavior;
	}
	public set dropdownBehavior(v: boolean) {
		this.inputAbility.dropdownBehavior = v;
	}



	public constructor(parent: ValueComponent) {
		super(parent);
		this._valueComponent = parent;
		this.inputAbility.focusNextOnReturn = true;

	}


	public destroy() {
		this._valueComponent = null;
		super.destroy();

	}


	protected doKeyDown() {
		super.doKeyDown();
		let key = this.inputAbility.key;
		if (key == 'ArrowDown') {
			if (this._valueComponent.droppingDown) {
				if (this._valueComponent.type == ValueType.select) {
					this._valueComponent.dropdownComponent.highlightNext();
					this._valueComponent.content = this._valueComponent.dropdownComponent.highlightedId;
				}
				if (this._valueComponent.type == ValueType.selectMultiple) {
					this._valueComponent.dropDownMultipleComponent.highlightNext();
					// this._valueComponent.addContent(this._valueComponent.dropDownMultipleComponent.highlightedId);
				}
			} else {
				this._valueComponent.droppingDown = true;
			}
		} else if (key == 'ArrowUp') {
			if (this._valueComponent.droppingDown) {
				if (this._valueComponent.type == ValueType.select) {
					this._valueComponent.dropdownComponent.highlightNext(true);
					this._valueComponent.content = this._valueComponent.dropdownComponent.highlightedId;
				}
				if (this._valueComponent.type == ValueType.selectMultiple) {
					this._valueComponent.dropDownMultipleComponent.highlightNext(true);
					// this._valueComponent.addContent(this._valueComponent.dropDownMultipleComponent.highlightedId);
				}
			}
		} else if (key.startsWith('Tab')) {
			this._valueComponent.droppingDown = false;
			let forward = !key.includes('Shift');
			this.inputAbility.focusNext(forward);
		} else if (key.startsWith('Enter') || key.startsWith('NumpadEnter')) {
			if (this._valueComponent.type == ValueType.selectMultiple) {
				this._valueComponent.addContent(this._valueComponent.dropDownMultipleComponent.highlightedId);
				return;
			}
			this._valueComponent.droppingDown = false;
		}

	}


	protected doKeyUp() {
		super.doKeyUp();
		let key = this.inputAbility.key;
		if (key.startsWith('Arrow') || key.startsWith('Tab') || key.startsWith('Enter') ) {
			return;
		}
		if (this.dropdownBehavior) {
			this._valueComponent.droppingDown = true;
			if(this._valueComponent.type == ValueType.select){
				this._valueComponent.dropdownComponent.setFilter(this.inputAbility.content);
			}else if(this._valueComponent.type == ValueType.selectMultiple){
				this._valueComponent.dropDownMultipleComponent.setFilter(this.inputAbility.content);
			}
		}

	}


}


class ValueCheckComponent extends CheckComponent {

	public static readonly style: ComponentStyle = {
		default: {
			textIndent: '0px',
		},
		'.focused': {
			outline: '1px dotted #307caf',
			outlineOffset: '1px',
		},
		':focus': {
			outline: ''
		}
	}

	private _valueComponent: ValueComponent;

	constructor(parent: ValueComponent) {
		super(parent);
		this._valueComponent = parent;
		this.focusable = true;

	}


	public destroy() {
		delete this._valueComponent;
		super.destroy();

	}


	protected doChanged() {
		(this._valueComponent as ValueComponent).content = this.checked;
		super.doChanged();

	}


}


class ValueReadonlyButtonComponent extends ButtonComponent {

	public static readonly style: ComponentStyle = {
		default: {
			textDecoration: 'underline',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
		}
	}

	private _htmlContentAbility: HtmlContentAbility;


	public get text(): string {
		return this._htmlContentAbility.htmlContent;
	}
	public set text(v: string) {
		this._htmlContentAbility.htmlContent = v;
	}


	public constructor(parent: Component, tagname: string = 'span') {
		super(parent, tagname);
		this._htmlContentAbility = this.createAbility(HtmlContentAbility);


	}


}