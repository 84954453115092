import { Component, ComponentStyle, Form } from "Bent";
import HtmlContentAbility from "lib/abilities/HtmlContentAbility";
import NavigationControl, { NavigationButtonComponent } from "lib/controls/NavigationControl";
import PropertyGridControl, { PropertyCellComponent } from "lib/controls/PropertyGridControl";


export default class DeleteProfileForm extends Form {

	public static readonly style: ComponentStyle = {
		default: {
			display: 'flex',
			userSelect: 'none',
			padding: '5%',
			flexWrap: 'wrap',
			justifyContent: 'center',
			alignItems: 'center',
			overflowY: 'auto',
			margin: '0',			
		}
	}


	private _email: PropertyCellComponent;
	public get email(): PropertyCellComponent {
		return this._email;
	}

	private _phoneNumber: PropertyCellComponent;
	public get phoneNumber(): PropertyCellComponent {
		return this._phoneNumber;
	}

	private _pinCode: PropertyCellComponent;
	public get pinCode(): PropertyCellComponent {
		return this._pinCode;
	}

	private _deleteButton: NavigationButtonComponent;
	public get deleteButton(): NavigationButtonComponent {
		return this._deleteButton;
	}

	private _deleteProfileText: DeleteProfileText;
	public get deleteProfileText(): string {
		return this._deleteProfileText.Text;
	}
	public set deleteProfileText(v: string) {
		this._deleteProfileText.Text = v;
	}

	public constructor(parent: Component) {
		super(parent);
		
		new StaffzLogoComponent(new FlexTopComponent(this));

		this._deleteProfileText = new DeleteProfileText(new FlexTopComponent(this));

		new FlexBreakComponent(this);

		let gridParent = new Component(this, 'div');
		gridParent.setStyle({
			backgroundColor: '#eff4f8',
			padding: '20px'
		});

		let grid = new PropertyGridControl(gridParent, 3);
		this._email = grid.newCell();
		this._phoneNumber = grid.newCell();
		this._pinCode = grid.newCell();


		let nc = new NavigationControl(gridParent);
		nc.setStyle({
			textAlign: 'center',
			gridTemplateColumns: 'none'
		});

		this._deleteButton = nc.addButton();
		this._deleteButton.setStyle({
			textAlign: "center",
			width: "40%",
			margin: "0 auto",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			height: "85px",
		});

		this.setGridStyles();
	}

	setGridStyles(){
		this._email.setStyle({
			gridTemplateColumns: "minmax(20px, 1fr) minmax(20px, 1fr)",
			height: '85px'
		});
		this._email.label.setStyle({
			fontSize: '45px'
		});


		this._phoneNumber.setStyle({
			gridTemplateColumns: "minmax(20px, 1fr) minmax(20px, 1fr)",
			height: '85px'
		});
		this._phoneNumber.label.setStyle({
			fontSize: '45px',
		});


		this._pinCode.setStyle({
			gridTemplateColumns: "minmax(20px, 1fr) minmax(20px, 1fr)",
			height: '85px'
		});
		this._pinCode.label.setStyle({
			fontSize: '45px'
		});

		this._deleteProfileText.setStyle({
			fontSize: '45px',
			fontFamily: 'Roboto, serif'
		})
	}

}

class DeleteProfileText extends Component {

	private _contentEditorAbility: HtmlContentAbility;

	public get Text(): string {
		return this._contentEditorAbility.htmlContent;
	}
	public set Text(v: string) {
		this._contentEditorAbility.htmlContent = v;
	}


	public constructor(parent: Component) {
		super(parent);
		this._contentEditorAbility = this.createAbility(HtmlContentAbility);

	}


}

class FlexBasisComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			flex: 'auto',
			margin: '2%',
		}
	}


}


class FlexTopComponent extends FlexBasisComponent {

	public static readonly style: ComponentStyle = {
		default: {
			width: '300px',
		}
	}


}


class StaffzLogoComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			maxHeight: '400px',
		}
	}


	public constructor(parent: Component) {
		super(parent, 'img');
		this.setAttribute('src', 'gfx/public/staffzLogo.svg');

	}


}

class FlexBreakComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			flexBasis: '100%',
			height: '0px',
		}
	}


}