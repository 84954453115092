import { Communication, CommunicationEventHandler } from "Bent";

export enum ServerLocation {
	'prod' = 'https://api.staffz.dk',
	'dev' = 'https://dev.staffz.dk',
	'bnLocal' = 'http://10.0.0.139:8090',
	'skjLocal' = 'http://10.0.0.68:8090',
}


export default class PublicStaffzPortal extends Communication {

	protected get request(): any {
		return super.request["params"];
	}
	protected set request(v: any) {
		let r = {
			"action": v.action,
			"params" : ''
		}
		delete v['action'];

		r.params = v;

		super.request = r;
	}


	public constructor() {
		let location = 'https://dev.staffz.dk';
		
		super(location + '/admin/api');

	}

	protected done() {
		if ((!this.error) && this.response.error) {
			let e = this.response.error;
			this._error = e;
			delete this._response;
			
		}
		
		super.done();

	}

	
}