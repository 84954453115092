import { Component } from "./Component";

export interface CSSStyles extends Partial<CSSStyleDeclaration> { };

export interface ElementStyles {
	default?: CSSStyles;
	[name: string]: CSSStyles;
}

interface StyleDef {
	semaphore: number;
	hasStyles: boolean;
	selectors: string[];
}

interface StyleDefs {
	[name: string]: StyleDef;
}

interface CssSelectorTable {
	[name: string]: string;
}

export class StyleHandler {

	private static styles: StyleDefs = {};

	private static get styleSheet(): CSSStyleSheet {
		if (document.styleSheets.length == 0) {
			document.head.appendChild(document.createElement('style'));
		}
		return document.styleSheets[0];
	}


	public static addElementStyles(e: HTMLElement, c: typeof Component): void {
		let name = this.validSelectorName(c);

		if (name in StyleHandler.styles) {
			StyleHandler.styles[name].semaphore++;
		} else {
			StyleHandler.styles[name] = { semaphore: 1, hasStyles: false, selectors: [] };
			let styles: ElementStyles = this.mergeInheritedStyle(c);
			for (let sn in styles) {
				let style = styles[sn];
				if ((Object.keys(style).length > 0)) {
					StyleHandler.styles[name].hasStyles = true;
					let selector = '.' + name + (sn != 'default' ? sn : '');
					StyleHandler.styles[name].selectors.push(selector);
					StyleHandler.createCSSSelector(selector, style);
				}
			}
		}
		if (StyleHandler.styles[name].hasStyles) {
			e.classList.add(name);
		}

	}


	public static removeElementStyles(c: typeof Component): void {
		let name = this.validSelectorName(c);
		if (name in StyleHandler.styles) {
			StyleHandler.styles[name].semaphore--;
			if (StyleHandler.styles[name].semaphore == 0) {
				while (StyleHandler.styles[name].selectors.length > 0) {
					this.removeCSSSelector(StyleHandler.styles[name].selectors.pop());
				}
				delete StyleHandler.styles[name].semaphore;
				delete StyleHandler.styles[name].hasStyles;
				delete StyleHandler.styles[name].selectors;
				delete StyleHandler.styles[name];
			}
		}

	}


	private static mergeInheritedStyle(c: typeof Component): ElementStyles {
		if (c == Component) {
			return 'style' in c ? Object.assign({}, c['style']) : {};
		}
		let result = this.mergeInheritedStyle(Object.getPrototypeOf(c));

		if (c.hasOwnProperty('style')) {
			let style: object = c['style'];
			for (let selector in style) {
				if (!result[selector]) {
					result[selector] = { };
				}
				Object.assign(result[selector], style[selector]);
			}
		}
		return result;
	}

	private static _selectorTranslations: CssSelectorTable = {};
	private static _selectorTranslationIndex: number = 0;

	private static validSelectorName(c: typeof Component): string {
		if (c.name.match(/^[^_<>/\$]+$/g)) {
			return c.name;
		};
		if (!this._selectorTranslations[c.name]) {
			this._selectorTranslations[c.name] = 'c' + (this._selectorTranslationIndex++).toString();
		}
		return this._selectorTranslations[c.name];
	}


	// private static _CSSSelectorCount: number = 0;


	public static createCSSSelector(selector: string, style: CSSStyles) {
		// console.log('createCSSSelector: ' + selector + ' (' +  ++this._CSSSelectorCount + ')' );
		let stylesheet = StyleHandler.styleSheet;
		try {
			let ruleIndex = stylesheet.insertRule(selector + '{}');
			let rule = (stylesheet.cssRules[ruleIndex] as CSSStyleRule);
			Object.assign(rule.style, style);
		}
		catch(e)
		{
			console.log(e);
			console.log(selector);
		}

	}


	public static removeCSSSelector(selector: string) {
		// console.log('removeCSSSelector: ' + selector + ' (' +  --this._CSSSelectorCount + ')' );
		let stylesheet = StyleHandler.styleSheet;
		let ruleIndex = stylesheet.cssRules.length;
		while (ruleIndex-- > 0) {
			if ((stylesheet.cssRules[ruleIndex] as CSSStyleRule).selectorText == selector) {
				stylesheet.deleteRule(ruleIndex);
				return;
			}
		}

	}


	public static createCSSSelectorString(selector: string) {
		let stylesheet = StyleHandler.styleSheet;
		try {
			let ruleIndex = stylesheet.insertRule(selector);
		}
		catch(e)
		{
			console.log(e);
			console.log(selector);
		}

	}

}