import { Destructable } from "./Destructable";
import { DestructableCollection } from "./DestructableCollection";
import { DOMListener } from "./DOMListener";
import { Event } from "./Event";
import { EventHandler } from "./EventHandler";
import { CSSStyles } from "./StyleHandler";

export type AbilityEvent = Event<Ability>;


export type AbilityEventHandler = EventHandler<AbilityEvent>;


export abstract class Ability extends Destructable {

	private _enabled: boolean;
	public get enabled(): boolean {
		return this._enabled;
	}
	public set enabled(v: boolean) {
		if (this._enabled != v) {
			this._enabled = v;
			if (v) {
				this.doEnable();
			} else {
				this.doDisable();
			}
		}
	}


	private _element: HTMLElement;
	protected get element(): HTMLElement {
		return this._element;
	}


	private _DOMListeners: DestructableCollection<DOMListener>;

	private _eventHandlers: DestructableCollection<AbilityEventHandler>;


	public constructor(element: HTMLElement) {
		super();
		this._element = element;

	}


	public destroy() {
		this.enabled = false;
		super.destroy();

	}


	protected setStyle(styleObject: CSSStyles) {
		Object.assign(this._element.style, styleObject);

	}


	protected setClass(className: string, enable: boolean) {
		let hasClass = this._element.classList.contains(className);
		if (enable && !hasClass) {
			this._element.classList.add(className);
		}
		if (!enable && hasClass)		{
			this._element.classList.remove(className);
		}
	};


	protected createDOMListener<K extends keyof HTMLElementEventMap>(type: K, listener: (this: HTMLEmbedElement, ev: HTMLElementEventMap[K]) => any, options?: boolean | AddEventListenerOptions): DOMListener {
		if (!this._DOMListeners) {
			this._DOMListeners = new DestructableCollection<DOMListener>();
		}
		return this._DOMListeners.add(new DOMListener(type, listener.bind(this), this._element, options));

	}


	protected createGlobalDOMListener<K extends keyof HTMLElementEventMap>(type: K, listener: (this: HTMLEmbedElement, ev: HTMLElementEventMap[K]) => any, options?: boolean | AddEventListenerOptions): DOMListener {
		if (!this._DOMListeners) {
			this._DOMListeners = new DestructableCollection<DOMListener>();
		}
		return this._DOMListeners.add(new DOMListener(type, listener.bind(this), null, options));

	}


	protected createEventHandler(): EventHandler<AbilityEvent> {
		if (!this._eventHandlers) {
			this._eventHandlers = new DestructableCollection<EventHandler<AbilityEvent>>();
		}
		return this._eventHandlers.add(new EventHandler<AbilityEvent>());

	}


	protected doEnable(): void {

	};


	protected doDisable(): void {
		if (this._DOMListeners) {
			this._DOMListeners.forEach((o: DOMListener) => {
				o.enabled = false;
			});
		}
	};


}