import { AbilityEventHandler, DOMListener } from "Bent";
import FocusAbility from "./FocusAbility";

export default class InputAbility extends FocusAbility {

	private _keyDownHandler: DOMListener;
	private _keyUpHandler: DOMListener;


	public get content(): string {
		return (this.element as HTMLInputElement).value;
	}
	public set content(v: string) {
		(this.element as HTMLInputElement).value = v;
	}


	public get readOnly(): boolean {
		return (this.element as HTMLInputElement).readOnly;
	}
	public set readOnly(v: boolean) {
		(this.element as HTMLInputElement).readOnly = v;
	}


	public get disabled(): boolean {
		return (this.element as HTMLInputElement).disabled;
	}
	public set disabled(v: boolean) {
		(this.element as HTMLInputElement).disabled = v;
	}


	public get password(): boolean {
		return (this.element as HTMLInputElement).type == 'password';
	}
	public set password(v: boolean) {
		(this.element as HTMLInputElement).type = v ? 'password' : 'text';
	}


	private _onKeyDown: AbilityEventHandler;
	public get onKeyDown(): AbilityEventHandler {
		return this._onKeyDown;
	}


	private _onKeyUp: AbilityEventHandler;
	public get onKeyUp(): AbilityEventHandler {
		return this._onKeyUp;
	}


	private _key: string;
	public get key(): string {
		return this._key;
	}


	private _focusNextOnReturn: boolean;
	public get focusNextOnReturn(): boolean {
		return (this._focusNextOnReturn == true);
	}
	public set focusNextOnReturn(v: boolean) {
		if (v != this.focusNextOnReturn) {
			if (v) {
				this._focusNextOnReturn = true;
			} else {
				delete this._focusNextOnReturn;
			}
			this.update();
		}
	}



	private _dropdownBehavior: boolean;
	public get dropdownBehavior(): boolean {
		return this._dropdownBehavior == true;
	}
	public set dropdownBehavior(v: boolean) {
		if (v != this.dropdownBehavior) {
			if (v) {
				this._dropdownBehavior = true;
			} else {
				delete this._dropdownBehavior;
			}
		}
	}


	private _onDefault: AbilityEventHandler;
	public get onDefault(): AbilityEventHandler {
		return this._onDefault;
	}



	public constructor(element: HTMLElement) {
		super(element);
		this._keyDownHandler = this.createDOMListener("keydown", this.doKeyDown);
		this._keyUpHandler = this.createDOMListener("keyup", this.doKeyUp);
		this._onKeyDown = this.createEventHandler();
		this._onKeyUp = this.createEventHandler();
		this._onDefault = this.createEventHandler();

	}


	protected doEnable(): void {
		super.doEnable();
		this._keyDownHandler.enabled = true;

	};


	private doKeyDown(e: KeyboardEvent) {
		this._keyUpHandler.enabled = true;
		this._key = e.key;
		if (this._key.length > 1) {
			this._key += (e.ctrlKey ? 'Ctrl' : '') + (e.shiftKey ? 'Shift' : '');
		}
		if (e.code == 'Enter' || e.code == 'NumpadEnter') {
			if (this.dropdownBehavior) {
				this.onKeyDown.fire(this);
				e.preventDefault();
			} else {
				if (this._focusNextOnReturn) {
					this.focusNext();
				}
				this.onDefault.fire(this);
			}

		} else {
			this.onKeyDown.fire(this);

		}

		if (this.dropdownBehavior) {
			switch (e.code) {
				case "Tab":
				case "ArrowUp":
				case "ArrowDown":
					e.preventDefault();
			}

		}

	}


	private doKeyUp(e: KeyboardEvent) {
		this._keyUpHandler.enabled = false;
		this._key = e.key;
		this.onKeyUp.fire(this);

	}

	protected doFocus(e: FocusEvent) {
		super.doFocus(e);
		if (this.element.tagName.toLowerCase() == 'input') {
			(this.element as HTMLInputElement).select();
		}

	}


}