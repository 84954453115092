import { Component, ComponentStyle } from "Bent";
import ActionComponent, { ActionComponentStyle, ActionIconComponent, ActionLabelComponent } from "lib/components/ActionComponent";
import LabelComponent from "lib/components/LabelComponent";


export default class HeaderControl extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			display: 'grid',
			gridTemplateColumns: 'auto minmax(0px, min-content)',
			backgroundColor: '#dbe1e6',
			borderTopLeftRadius: '2px',
			borderTopRightRadius: '2px',
		}
	}


	private _caption : HeaderCaptionControl;
	public get caption() : HeaderCaptionControl {
		return this._caption;
	}


	private _buttonsPanel: HeaderButtonPanelComponent;
	public get buttonsPanel(): HeaderButtonPanelComponent {
		return this._buttonsPanel;
	}


	public constructor(parent: Component) {
		super(parent);
		this._caption = new HeaderCaptionControl(this);
		this._buttonsPanel = new HeaderButtonPanelComponent(this);

	}


	public addButton(): HeaderButtonComponent {
		return new HeaderButtonComponent(this._buttonsPanel);

	}


}


class HeaderCaptionControl extends LabelComponent {

	public static readonly style: ComponentStyle = {
		default: {
			color: '#8395A3',
			textIndent: '8px',
			lineHeight: '25px',
		}
	}

}


 export class HeaderButtonPanelComponent extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			display: 'flex',
		}
	}


}


export class HeaderButtonComponent extends ActionComponent {

	public static readonly style: ActionComponentStyle = {
		default: {
			display: 'flex',
			alignItems: 'center',
			borderRadius: '2px',
			whiteSpace: 'nowrap',
			transition: 'background-color 0.2s',
		},
		'.hover': {
			backgroundColor: '#307caf',
		},
		'.pressed': {
			backgroundColor: '#599cce',
		},
		'.focused': {
			backgroundColor: '#307caf',
		}
	}

	protected createIcon(): ActionIconComponent {
		return new HeaderIconComponent(this);

	};


	protected createLabel(): ActionLabelComponent {
		return new HeaderLabelComponent(this);

	};


}

class HeaderLabelComponent extends ActionLabelComponent {

	public static readonly style: ComponentStyle = {
		default: {
			color: '#99A5AD',
		}
	}


}


class HeaderIconComponent extends ActionIconComponent {

	public static readonly style: ComponentStyle = {
		default: {
			display: 'inline-block',
			fill: '#99A5AD',
			transform: 'scale(.65)',
			width: '22px',
		}
	}

}
