import { Component } from "lib/_bent/Component";
import IconComponent from "./IconComponent";


export default class FlagIconComponent extends IconComponent {

	public constructor(parent: Component) {
		super(parent, 'gfx/flags.svg');

	}


}