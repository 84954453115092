import { Ability, AbilityEventHandler, DOMListener } from "Bent";


export default class FocusAbility extends Ability {

	private _focusHandler: DOMListener;

	private _blurHandler: DOMListener;


	private _onFocusChanged: AbilityEventHandler;
	public get onFocusChanged(): AbilityEventHandler {
		return this._onFocusChanged;
	}


	private _notFocusable: boolean;
	public get focusable(): boolean {
		return !(this._notFocusable == true);
	}
	public set focusable(v: boolean) {
		if (v != this.focusable) {
			if (v) {
				delete this.focusable;
			} else {
				this._notFocusable = true;
			}
			this.update();
		}
	}


	public get focused(): boolean {
		return document.activeElement == this.element;
	}
	public set focused(v: boolean) {
		if (v != this.focused && this.focusable) {
			setTimeout(() => {
				if (v) {
					if (this.enabled) {
						this.element.focus();
					}
				} else {
					this.element.blur();
				}
			}, 1);
		}
	}


	public constructor(element: HTMLElement) {
		super(element);
		this._focusHandler = this.createDOMListener("focus", this.doFocus);
		this._blurHandler = this.createDOMListener("blur", this.doBlur);
		this._onFocusChanged = this.createEventHandler();

	}


	public focusNext(forward: boolean = true) {
		let e: HTMLElement = forward ? this.nextFocusableElement() : this.prevFocusableElement();
		if (e) {
			setTimeout(() => {
				e.focus();
			}, 1);
		} else {
			this.element.blur();
		}

	}


	public scrollIntoView() {
		this.element.scrollIntoView({block:'nearest'});

	}



	private doFocusChanged() {
		this.setClass('focused', this.focused);
		this.onFocusChanged.fire(this);
	}


	protected doFocus(e: FocusEvent) {
		this._focusHandler.enabled = false;
		this._blurHandler.enabled = true;
		this.doFocusChanged();

	}


	protected doBlur(e: FocusEvent) {
		this._blurHandler.enabled = false;
		this._focusHandler.enabled = true;
		this.doFocusChanged();

	}


	protected doEnable() {
		super.doEnable();
		this._focusHandler.enabled = true;
		this.update();


	}


	protected doDisable(): void {
		super.doDisable();
		this.update();

	}


	protected update() {
		if (this.enabled && this.focusable) {
			this.element.tabIndex = 0;
		} else {
			this.element.removeAttribute('tabIndex');
		}

	}


	private nextFocusableElement(): HTMLElement {
		let foundMe = false;
		let traverseDom = (e: Element): HTMLElement => {
			let result: HTMLElement = null;
			if (e == this.element) {
				foundMe = true;
			} else if (foundMe && e instanceof HTMLElement && e.tabIndex >= 0) {
				result = e;
			}
			if (!result && e.firstElementChild) {
				result = traverseDom(e.firstElementChild);
			}
			if (!result && e.nextElementSibling) {
				result = traverseDom(e.nextElementSibling);
			}
			return result;
		}
		return traverseDom(document.body);

	}


	private prevFocusableElement(): HTMLElement {
		let lastElement = null;
		let traverseDom = (e: Element): HTMLElement => {
			let result: HTMLElement = null;
			if (e == this.element) {
				result = lastElement;
			} else if (e instanceof HTMLElement && e.tabIndex >= 0) {
				lastElement = e;
			}
			if (!result && e.firstElementChild) {
				result = traverseDom(e.firstElementChild);
			}
			if (!result && e.nextElementSibling) {
				result = traverseDom(e.nextElementSibling);
			}
			return result;
		}
		return traverseDom(document.body);

	}


}