import FlagLabelComponent from "lib/components/FlagLabelComponent";
import ValueComponent from "lib/components/ValueComponent";
import { NavigationButtonComponent } from "lib/controls/NavigationControl";
import ListPageForm from "lib/pages/ListPageForm";
import { Component, ComponentStyle } from "lib/_bent/Component";


export default class EminiPiForm extends ListPageForm {

	public constructor(parent: Component) {
		super(parent);

	}
}