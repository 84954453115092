import PublicStaffzPortal, { ServerLocation } from "./staffz/PublicStaffzPortal";

export default class DeleteProfileCommunication extends PublicStaffzPortal {

	public constructor(email: string, phoneNumber: string, pinCode: string) {
		super();
		
		this.request = {
			action: 'deleteProfile',
			email: email,
			phoneNumber: phoneNumber,
			pinCode: pinCode
		}
		this.call();
	}

}