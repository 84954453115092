import { IListContractRequest } from "./staffz/IListContract";
import ListCommunication from "./staffz/ListCommunication";


export interface ISkillListContractRequest extends IListContractRequest {
	command?: "delete" | "merge" | "setStatus";
	commandIds?: string[];
	newStatusId?: "0" | "1" | "2";
}


export default class SkillListCommunication extends ListCommunication {


	public get request(): ISkillListContractRequest {
		return super.request as ISkillListContractRequest;
	}
	public set request(v: ISkillListContractRequest) {
		super.request = v;
	}

	public constructor() {
		super();
		this.request = { contract: 'SkillList' }

	}


}