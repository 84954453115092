import { Component, ComponentStyle } from "Bent";
import HtmlContentAbility from "lib/abilities/HtmlContentAbility";
import { ActionIconComponent } from "lib/components/ActionComponent";
import TextComponent from "lib/components/TextComponent";
import AdminPageForm from "lib/forms/AdminPageForm";


export default class UnderContructionForm extends AdminPageForm {

	public static readonly style: ComponentStyle = {
		default: {
			display: 'grid',
			gridTemplateColumns: 'auto',
			gridTemplateRows: 'auto auto',
			alignContent: 'center',
		}
	}


	private _label: UnderContructionLabel;


	public get text(): string {
		return this._label.text;
	}
	public set text(v: string) {
		this._label.text = v;
	}


	public constructor(parent: Component) {
		super(parent);
		new UnderContrustionIcon(this);
		this._label = new UnderContructionLabel(this);

	}


}


class UnderContructionLabel extends TextComponent {

	public static readonly style: ComponentStyle = {
		default: {
			fontSize: '22px',
			justifySelf: 'center',
			marginBottom: '150px',
		}
	}


	private _htmlContentAbility: HtmlContentAbility;

	public get text(): string {
		return this._htmlContentAbility.htmlContent;
	}
	public set text(v: string) {
		this._htmlContentAbility.htmlContent = v;
	}

	public constructor(parent: Component) {
		super(parent);
		this._htmlContentAbility = this.createAbility(HtmlContentAbility);

	}

}


class UnderContrustionIcon extends ActionIconComponent {

	public static readonly style: ComponentStyle = {
		default: {
			fill: '#444',
			width: '150px',
			height: '150px',
			justifySelf: 'center',
			marginBottom: '50px',
		}
	}

	public constructor(parent: Component) {
		super(parent);
		this.iconId = "underConstruction";
	}


}