import { View } from "Bent";
import { IListContractResponse } from "lib/communication/staffz/IListContract";
import { ValueType } from "lib/communication/staffz/ValueType";
import { ListCell, ListHeaderCell, ListPopulator } from "lib/controls/ListControl";
import ListPageForm from "./ListPageForm";

export default abstract class ListPageView<FormClass extends ListPageForm> extends View<FormClass> implements ListPopulator {

	private _context: IListContractResponse;
	public get context(): IListContractResponse {
		return this._context;
	}
	public set context(v: IListContractResponse) {
		this._context = v;
		this.form.pager.populate(v.view);
		this.form.list.populate(this.getColumns(), v.content.length, this);
	}


	public populate(): void {
		if (this.context) {
			this.form.list.populate(this.getColumns(), this.context.content.length, this);
		}
	}


	public populateHeader(fieldName: string, headerCell: ListHeaderCell)
	{
		let field = this.context.fields[fieldName];
		headerCell.sortable = field && field.sortable;
		if (headerCell.sortable) {
			let si = 0;
			for(let sc of this.context.view.sortColumns) {
				if (sc.substr(1) == fieldName) {
					headerCell.sortIndex = si;
					if (sc.substr(0, 1)=='-') {
						headerCell.descending = true;
					}
					break;
				}
				si++;
			}
		}

	}


	public populateCell(fieldName: string, rowIndex: number, cell: ListCell) {
		let field = this.context.fields[fieldName];
		let row = this.context.content[rowIndex];
		cell.type = field.valueType;
		if (cell.type == ValueType.select || cell.type == ValueType.selectMultiple) {
			cell.choises = field.options;
		}
		cell.content = row[field.contentIndex];
		if (cell.readonlyIsButton && cell.content == '') {
			cell.content = '« ikke angivet »';
		}

	}


	protected getColumns(): string[] {
		return this.context.view.viewColumns;

	}


}