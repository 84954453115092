import { EventListener } from "./EventListener";
import { Form } from "./Form";

export abstract class View<FormClass extends Form> extends EventListener {

	private _form: FormClass;
	public get form(): FormClass {
		return this._form;
	}


	public constructor(form: FormClass) {
		super();
		this._form = form;

	}


	public abstract populate(): void;


}


