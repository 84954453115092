import { Component } from "Bent";
import ButtonComponent, { ButtonComponentStyle } from "./ButtonComponent";
import IconComponent from "./IconComponent";
import LabelComponent from "./LabelComponent";

export type ActionComponentStyle = ButtonComponentStyle;


export default abstract class ActionComponent extends ButtonComponent {

	private _icon: IconComponent;

	private _label: LabelComponent;


	private _id : any;
	public get id() : any {
		return this._id;
	}


	public setContent(id: string, caption?: string, iconId?: string, radio?: boolean): ActionComponent  {
		this._id = id;

		if (this._label) {
			this._label.destroy();
			delete this._label;
		}
		if (this._icon) {
			this._icon.destroy();
			delete this._icon;
		}
		if (iconId && iconId != '') {
			this._icon = this.createIcon();
			if (this._icon) {
				this._icon.iconId = iconId;
			}
		}
		if (caption && caption != '') {
			this._label = this.createLabel();
			if (this._label) {
				this._label.text = caption;
			}
		}
		if (radio) {
			this.radio = radio;
		}

		return this;
	}


	protected createIcon(): ActionIconComponent {
		return null;

	};


	protected createLabel(): ActionLabelComponent {
		return null;

	};


}


export class ActionLabelComponent extends LabelComponent {

}


export class ActionIconComponent extends IconComponent {

	public constructor(parent: Component) {
		super(parent, 'gfx/icons.svg');

	}


}