import PublicStaffzPortal, { ServerLocation } from "./staffz/PublicStaffzPortal";

export default class ConfirmDeleteProfileCommunication extends PublicStaffzPortal {

	public constructor(key: string) {
		super();
		
		this.request = {
			action: 'confirmdeleteProfile',
			key: key,
		}
		this.call();
	}

}