import { View } from "Bent";
import OverviewForm from "./OverviewForm";


export default class OverviewView extends View<OverviewForm> {


	public populate(): void {
		this.form.header.caption.text = 'Liste over vikarbureaer der benytter Staffz';
	}


}