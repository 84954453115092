import { Ability } from "./Ability";
import { DestructableCollection } from "./DestructableCollection";
import { Event } from "./Event";
import { EventHandler } from "./EventHandler";
import { EventListener } from "./EventListener";
import { CSSStyles, ElementStyles, StyleHandler } from "./StyleHandler";
import { __ } from "../lang/TranslatetionControl";

export interface ComponentStyle extends ElementStyles {
	'.disabled'?: CSSStyles;
	'.hidden'?: CSSStyles;
}


export enum ComponentState {
	normal = 0,
	disabled = 1,
	hidden = 2
}


export type ComponentEvent = Event<Component>;


export type ComponentEventHandler = EventHandler<ComponentEvent>;


export class Component extends EventListener {

	public static readonly style: ComponentStyle = {
		'.hidden': {
			display: 'none'
		}
	};


	private _parent: Component;

	private _children: DestructableCollection<Component>;

	public _element: HTMLElement;

	private _eventHandlers: DestructableCollection<EventHandler<ComponentEvent>>;

	private _abilities: DestructableCollection<Ability>;


	private _client: Component;
	protected get client(): Component {
		return this._client ? this._client : this;
	}
	protected set client(v: Component) {
		this._client = v;
	}

	protected get namespace(): string {
		return '';
	};


	private _state: ComponentState;
	protected get state(): ComponentState {
		return this._state;
	}

	private _disabled: boolean;
	public get enabled(): boolean {
		return !(this._disabled == true);
	}
	public set enabled(v: boolean) {
		if (v != this.enabled) {
			if (v) {
				delete this._disabled;
			} else {
				this._disabled = true;
			}
			this.updateState();
		}
	}

	private _hidden: boolean;
	public get visible(): boolean {
		return !(this._hidden == true);
	}
	public set visible(v: boolean) {
		if (v != this.visible) {
			if (v) {
				delete this._hidden;
			} else {
				this._hidden = true;
			}
			this.updateState();
		}
	}


	public constructor(parent: Component, tagName: string = "div", createHidden: boolean = false) {
		super();
		if (parent) {
			parent = parent.client;
			this._parent = parent;
			if (!parent._children) {
				parent._children = new DestructableCollection<Component>();
			}
			parent._children.add(this);
			this._element = (this.namespace ? document.createElementNS(this.namespace, tagName) : document.createElement(tagName)) as HTMLElement;

			if (createHidden) {
				this._hidden = true;
				this.setClass('hidden', true);
				this._state = ComponentState.hidden;
			}
			if (parent._state == ComponentState.disabled) {
				this._state = ComponentState.disabled;
				this.setClass('disabled', true);
			}

			let p: Element = parent._element;
			p.appendChild(this._element);
		} else {
			this._element = document.body;
			delete this._hidden;
		}
		StyleHandler.addElementStyles(this._element, <typeof Component>this.constructor);

	}


	public destroy() {
		let element = this._element;
		delete this._element;
		if (this._parent) {
			if (this._parent._children) {
				this._parent._children.remove(this);
			}
			delete this._parent;
		}
		super.destroy();

		if (element && element.parentNode) {
			StyleHandler.removeElementStyles(<typeof Component>this.constructor);
			(element.parentNode as HTMLElement).removeChild(element);
		}

	}


	public clear() {
		if (this._children) {
			this._children.destroy();
			delete this._children;
		}

	}


	protected updateState() {
		let ts = ComponentState.normal;
		let v = this.visible;
		if (!v) {
			ts = ComponentState.hidden;
		} else {
			let e = this.enabled && (this._parent ? (this._parent._state != ComponentState.disabled) : true);
			if (!e) {
				ts = ComponentState.disabled;
			}
		}

		let fs = this._state;

		if (fs != ts) {
			this._state = ts;

			if (fs != ComponentState.disabled && ts == ComponentState.disabled) {
				this.doDisable();
			}

			if (fs != ComponentState.hidden && ts == ComponentState.hidden) {
				this.doHide();
			}

			if (fs == ComponentState.hidden && ts == ComponentState.normal) {
				this.doShow();
			}

			if (fs == ComponentState.disabled && ts == ComponentState.normal) {
				this.doEnable();
			}
			if (this._abilities) {
				let ae = (ts == ComponentState.normal) && !this.isHiddenByParent();
				this._abilities.forEach((ability: Ability) => {
					ability.enabled = ae;
				});
			}

			if (this._children) {
				this._children.forEach((o: Component) => {
					o.updateState();
				});
			}


		}

	}


	private isHiddenByParent() {
		return this._parent ? (this._parent._hidden || this._parent.isHiddenByParent()) : false;

	}


	protected doShow() {
		this.setClass('hidden', false, true);

	}


	protected doHide() {
		this.setClass('hidden', true, true);

	}


	protected doEnable() {
		this.setClass('disabled', false, true);

	}


	protected doDisable() {
		this.setClass('disabled', true, true);

	}


	public setStyle(styleObject: CSSStyles) {
		if (this._element) {
			Object.assign(this._element.style, styleObject);
			if (this._element.getAttribute('style') == '') {
				this._element.removeAttribute('style');
			};
		}

	}


	protected setAttribute(name: string, value: string) {
		if (this._element) {
			this._element.setAttribute(name, value);
		}

	}


	protected setAttributeNS(namespace: string, name: string, value: string) {
		if (this._element) {
			this._element.setAttributeNS(namespace, name, value);
		}

	}


	protected setClass(className: string, enable: boolean, delayed: boolean = false) {
		if (delayed) {
			setTimeout(() => {
				this.setClass(className, enable);
			}, 50);
			return;
		}
		if (this._element) {
			let hasClass = this.hasClass(className);
			if (enable && !hasClass) {
				this._element.classList.add(className);
			}
			if (!enable && hasClass) {
				this._element.classList.remove(className);
			}
		}

	};


	protected hasClass(className: string) {
		return this._element ? this._element.classList.contains(className) : false;

	};




	protected createEventHandler(): EventHandler<ComponentEvent> {
		if (!this._eventHandlers) {
			this._eventHandlers = new DestructableCollection<EventHandler<ComponentEvent>>();
		}
		return this._eventHandlers.add(new EventHandler<ComponentEvent>());

	}


	protected createAbility<T extends Ability>(c: { new(e: HTMLElement): T }): T {
		if (!this._abilities) {
			this._abilities = new DestructableCollection<Ability>();
		}
		let ability = new c(this._element);
		ability.enabled = (this.state == ComponentState.normal || this.state == undefined) && !this.isHiddenByParent();
		return this._abilities.add(ability) as T;

	}

}
