import { Component, ComponentStyle } from "Bent";
import NavigationControl, { NavigationButtonComponent } from "lib/controls/NavigationControl";
import PropertyGridControl, { PropertyCellComponent } from "lib/controls/PropertyGridControl";
import DialogForm from "lib/forms/DialogForm";

export default class LoginForm extends DialogForm {

	public static readonly style: ComponentStyle = {
		default: {
			width: '300px',
		}
	}


	private _login: PropertyCellComponent;
	public get login(): PropertyCellComponent {
		return this._login;
	}

	private _password: PropertyCellComponent;
	public get password(): PropertyCellComponent {
		return this._password;
	}

	private _server: PropertyCellComponent;
	public get server(): PropertyCellComponent {
		return this._server;
	}

	private _loginButton: NavigationButtonComponent;
	public get loginButton(): NavigationButtonComponent {
		return this._loginButton;
	}

	private _cancelButton: NavigationButtonComponent;
	public get cancelButton(): NavigationButtonComponent {
		return this._cancelButton;
	}


	public constructor(parent: Component) {
		super(parent);
		let grid = new PropertyGridControl(this, 3);
		this._login = grid.newCell();
		this._password = grid.newCell();
		this._server = grid.newCell();

		let nc = new NavigationControl(this);
		this._loginButton = nc.addButton();
		this._cancelButton = nc.addButton();

	}


}