import { Component, ComponentStyle } from "Bent";
import EditControl from "lib/controls/EditControl";
import HeaderControl from "lib/controls/HeaderControl";
import NavigationControl, { NavigationButtonComponent } from "lib/controls/NavigationControl";
import AdminPageForm from "lib/forms/AdminPageForm";

export default class SearchPageForm extends AdminPageForm {

	public static readonly style: ComponentStyle = {
		default: {
			width: 'calc(100%-24px)',
			padding: '12px'
		}
	}

	private _header: HeaderControl;
	public get header(): HeaderControl {
		return this._header;
	}



	private _filterEdit: EditControl;
	public get filterEdit(): EditControl {
		return this._filterEdit;
	}



	private _resetButton: NavigationButtonComponent;
	public get resetButton(): NavigationButtonComponent {
		return this._resetButton;
	}



	private _searchButton: NavigationButtonComponent;
	public get searchButton(): NavigationButtonComponent {
		return this._searchButton;
	}


	public constructor(parent: Component) {
		super(parent);

		this._header = new HeaderControl(this);
		this._filterEdit = new EditControl(this);

		let nc = new NavigationControl(this);
		this._resetButton = nc.addButton();
		this._searchButton = nc.addButton();

		this._filterEdit.defaultAction = this._searchButton;

	}

}