import { Controller, CSSStyles, StyleHandler } from "Bent";
import AdminController from "module/admin/AdminController";
import PublicController from "module/public/PublicController";
import MainForm from "./MainForm";
import MainView from "./MainView";
import StaffzPortal from "lib/communication/staffz/StaffzPortal";


export default class MainController extends Controller<MainView, MainForm> {

	private _publicController: PublicController;

	private _adminController: AdminController;


	public constructor() {
		super(null, MainView, MainForm);
		this.initCommonStyles();
		this.listen(StaffzPortal.onLoginChanged, this.refresh);
		this.doActivate();
	}


	public refresh() {
		if (StaffzPortal.loggedIn) {
			if (this._publicController) {
				this._publicController.destroy();
				delete this._publicController;
			}
			if (!this._adminController) {
				this._adminController = new AdminController(this);
				this._adminController.active = true;
			}
		} else {
			if (this._adminController) {
				this._adminController.destroy();
				delete this._adminController;
			}
			if (!this._publicController) {
				this._publicController = new PublicController(this);
				this._publicController.active = true;
			}
		}

	}


	private initCommonStyles() {
		StyleHandler.createCSSSelectorString('@keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); } }');

	}


}