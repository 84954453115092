import { Controller, ControllerClass } from "Bent";
import { IValueFields } from "lib/communication/staffz/IValueField";
import ListCommunication from "lib/communication/staffz/ListCommunication";
import { IFormConstructor, IViewConstructor } from "lib/_bent/Controller";
import SearchPageForm from "./SearchPageForm";
import SearchPageView from "./SearchPageView";


export default abstract class SearchPageController<ViewClass extends SearchPageView<FormClass>, FormClass extends SearchPageForm> extends Controller<ViewClass, FormClass> {


	protected abstract get filter() : IValueFields;
	protected abstract set filter(v : IValueFields);



	private _communication: ListCommunication;
	protected get communication(): ListCommunication {
		return this._communication;
	}


	public constructor(parent: ControllerClass, viewClass: IViewConstructor<ViewClass>, formClass: IFormConstructor<FormClass>, communication: ListCommunication) {
		super(parent, viewClass, formClass);
		this._communication = communication;
		this.listen(this._communication.onSucces, this.handleNewContent);
		this.listen(this.view.form.searchButton.onActivate, this.doSearch);
		this.listen(this.view.form.resetButton.onActivate, this.doReset);

	}


	public doActivate() {
		super.doActivate();
		if (!this.filter) {
			this._communication.call();
		} else {
			this.view.filter = this.filter;
		}

	}


	protected handleNewContent(sender: ListCommunication) {
		this.filter = sender.response.filter;
		this.view.filter = sender.response.filter;

	}


	protected doSearch() {
		this.view.form.filterEdit.updateValueFields(this.filter);

	}


	protected doReset() {
		this.view.form.filterEdit.clearFieldValues();
	}



}