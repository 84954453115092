import {Ability, AbilityEventHandler, DOMListener} from "Bent";
import Vector from "lib/utilities/Vector";
import PointerAbility from "./PointerAbility";

export default class DragAbility extends PointerAbility {

	private _mouseDownAt: Vector;


	private _dragDelta : Vector;
	public get dragDelta() : Vector {
		return this._dragDelta;
	}


	private _onStartDrag: AbilityEventHandler;
	public get onStartDrag(): AbilityEventHandler {
		return this._onStartDrag;
	}


	private _onDrag: AbilityEventHandler;
	public get onDrag(): AbilityEventHandler {
		return this._onDrag;
	}



	public constructor(element: HTMLElement) {
		super(element);
		this._onStartDrag = this.createEventHandler();
		this._onDrag = this.createEventHandler();

		this._mouseDownAt = new Vector();
		this._dragDelta = new Vector();

	}

	protected doMouseDown(e: MouseEvent) {
		super.doMouseDown(e);
		this._mouseDownAt.setValues(e.x, e.y);
		this._onStartDrag.fire(this);

	}


	protected doMouseMove(e: MouseEvent) {
		super.doMouseMove(e);
		let p = new Vector(e.x, e.y);
		this._dragDelta = Vector.sub(p, this._mouseDownAt);
		this.onDrag.fire(this);

	}


	protected doMouseUp(e: MouseEvent) {
		super.doMouseUp(e);

	}


}