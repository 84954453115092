import { DestructableCollection } from "./DestructableCollection";
import { Event } from "./Event";
import { EventHandler } from "./EventHandler";
import { EventListener } from "./EventListener";
import { Form } from "./Form";
import { View } from "./View";

export type ControllerClass = Controller<View<Form>, Form>;


export interface IFormConstructor<T extends Form> {
	new(parent: Form): T;
}


export interface IViewConstructor<T extends View<Form>> {
	new(form: Form): T;
}


export type ControllerEvent = Event<Controller<View<Form>, Form>>;


export type ControllerEventHandler = EventHandler<ControllerEvent>;


export abstract class Controller<ViewClass extends View<FormClass>, FormClass extends Form> extends EventListener {


	private _parent: ControllerClass;
	public get parent(): ControllerClass {
		return this._parent;
	}

	private _children: DestructableCollection<ControllerClass>;

	private _eventHandlers: DestructableCollection<EventHandler<ControllerEvent>>;

	private _view: ViewClass;
	public get view(): ViewClass {
		return this._view;
	}


	private _active: boolean;
	public get active(): boolean {
		return this._active;
	}
	public set active(v: boolean) {
		if (this._active != v) {
			this._active = v;
			if (v) {
				this.doActivate();
			} else {
				this.doDeactivate();
			}
		}
	}


	private _onDestroy: ControllerEventHandler;
	public get onDestroy(): ControllerEventHandler {
		return this._onDestroy;
	}


	public constructor(parent: ControllerClass, viewClass: IViewConstructor<ViewClass>, formClass: IFormConstructor<FormClass>) {
		super();
		if (parent) {
			this._parent = parent;
			if (!parent._children) {
				parent._children = new DestructableCollection<ControllerClass>();
			}
			parent._children.add(this);
		}
		this._onDestroy = this.createEventHandler();

		this._view = new viewClass(new formClass(this.parent ? this.parent.view.form : null));

	}


	public destroy() {
		this.onDestroy.fire(this);
		if (this._parent) {
			if (this._parent._children) {
				this._parent._children.remove(this);
			}
			delete this._parent; // deleting _parent avoids call to destroy
		}
		super.destroy();
	}


	protected doActivate() {
		this.view.form.visible = true;
		this.refresh();
	}


	protected doDeactivate() {
		this.view.form.visible = false;

	}


	public refresh() {
		this.update();

	}


	public update() {
		if (this.active) {
			this.view.populate();
			if (this._children) {
				this._children.forEach((o: ControllerClass) => {
					o.update();
				});
			}
		}

	}


	protected createEventHandler(): EventHandler<ControllerEvent> {
		if (!this._eventHandlers) {
			this._eventHandlers = new DestructableCollection<EventHandler<ControllerEvent>>();
		}
		return this._eventHandlers.add(new EventHandler<ControllerEvent>());

	}


	public static run<T extends ControllerClass>(controller: { new(): T }) {
		let f;
		addEventListener("load", f = () => {
			removeEventListener('load', f);
			new controller();
		});

	}


}
