import ListCommunication from "./staffz/ListCommunication";

export default class LanguageListCommunication extends ListCommunication {

	public constructor() {
		super();
		this.request = { contract: 'LanguageList' }

	}


}