import { Component, ComponentEventHandler, ComponentState, ComponentStyle, CSSStyles } from "Bent";
import ButtonAbility from "lib/abilities/ButtonAbility";
import FocusAbility from "lib/abilities/FocusAbility";

export interface ButtonComponentStyle extends ComponentStyle {
	down?: CSSStyles;
	hover?: CSSStyles;
	pressed?: CSSStyles;
	focused?: CSSStyles;
}


export default abstract class ButtonComponent extends Component {


	public static readonly style: ComponentStyle = {
		default: {
			cursor: 'pointer',
		},
		'.disabled': {
			cursor: 'default',
		},
		':focus': {
			outline: 'none',
			border: 'none',
		}

	}


	private _buttonAbility: ButtonAbility;
	protected get buttonAbility(): ButtonAbility {
		return this._buttonAbility;
	}

	public get down(): boolean {
		return this._buttonAbility.down;
	}
	public set down(v: boolean) {
		this._buttonAbility.down = v;
	}


	private _focusAbility: FocusAbility;
	protected get focusAbility(): FocusAbility {
		return this._focusAbility;
	}


	public get focusable(): boolean {
		return this._focusAbility.focusable;
	}
	public set focusable(v: boolean) {
		this._focusAbility.focusable = v;
	}


	private _onActivate: ComponentEventHandler;
	public get onActivate(): ComponentEventHandler {
		return this._onActivate;
	}

	private _radio: boolean;
	public get radio(): boolean {
		return this._radio == true;
	}
	public set radio(v: boolean) {
		if (v) {
			this._radio = true;
		} else {
			delete this._radio;
		}
	}


	public constructor(parent: Component, tagName: string = "div") {
		super(parent, tagName);
		this._onActivate = this.createEventHandler();
		this._buttonAbility = this.createAbility(ButtonAbility);
		this._focusAbility = this.createAbility(FocusAbility);
		this.listen(this._buttonAbility.onActivate, this.activate);

	}


	public activate() {
		this._onActivate.fire(this);

	}


}
