import FlagLabelComponent from "lib/components/FlagLabelComponent";
import ValueComponent from "lib/components/ValueComponent";
import { NavigationButtonComponent } from "lib/controls/NavigationControl";
import ListPageForm from "lib/pages/ListPageForm";
import { Component, ComponentStyle } from "lib/_bent/Component";


export default class SkillListForm extends ListPageForm {


	private _btnDelete: NavigationButtonComponent;
	public get btnDelete(): NavigationButtonComponent {
		return this._btnDelete;
	}


	private _btnMerge: NavigationButtonComponent;
	public get btnMerge(): NavigationButtonComponent {
		return this._btnMerge;
	}



	private _statusChangeTo: SkillSelectorComponent;
	public get statusChangeTo(): SkillSelectorComponent {
		return this._statusChangeTo;
	}



	private _btnChangeStatus: NavigationButtonComponent;
	public get btnChangeStatus(): NavigationButtonComponent {
		return this._btnChangeStatus;
	}




	public constructor(parent: Component) {
		super(parent);
		this._btnDelete = this.footer.addButton();
		this._btnMerge = this.footer.addButton();
		let empty = this.footer.addEmpty();
		this._statusChangeTo = new SkillSelectorComponent(empty);
		this._btnChangeStatus = this.footer.addButton();

	}


	public showTranslation(parent: Component, languageId: string, text: string) {
		new FlagLabelComponent(parent, languageId, text);

	}


}


export class SkillSelectorComponent extends ValueComponent {


	public static readonly style: ComponentStyle = {
		default: {

		}
	}


	protected doCreateDropdown() {
		super.doCreateDropdown();
		this.dropdownComponent.goUp = true;
	}



}