import { Component, ComponentStyle } from "Bent";
import ModuleMenuControl from "lib/controls/ModuleMenuControl";
import MainMenuControl from "lib/controls/MainMenuControl";
import MenuControl from "lib/controls/MenuControl";
import FadeInOutForm from "lib/forms/FadeInOutForm";


export default class AdminForm extends FadeInOutForm {

	public static readonly style: ComponentStyle = {
		default: {
			display: 'grid',
			gridTemplateColumns: 'auto',
			gridTemplateRows: '54px 30px 25px auto 35px',
			width: '100vw',
			height: '100vh',
		}
	}


	private _moduleMenu: ModuleMenuControl;
	public get moduleMenu(): ModuleMenuControl {
		return this._moduleMenu;
	}


	private _mainMenu: MainMenuControl;
	public get mainMenu(): MainMenuControl {
		return this._mainMenu;
	}


	private _menu: MenuControl;
	public get menu(): MenuControl {
		return this._menu;
	}


	private _bottom: AdminBottom;
	public get Bottom(): AdminBottom {
		return this._bottom;
	}


	public constructor(parent: Component) {
		super(parent);
		this._moduleMenu = new ModuleMenuControl(this);
		this._mainMenu = new MainMenuControl(this);
		this._menu = new MenuControl(this);
		let client = new AdminClient(this);
		this._bottom = new AdminBottom(this);

		this.client = client;
	}


}


class AdminClient extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			position: 'relative',
			backgroundColor: '#FCFCFC',
			overflow: 'auto',
		}
	}


}


class AdminBottom extends Component {

	public static readonly style: ComponentStyle = {
		default: {
			backgroundColor: '#0c5783',
			overflow: 'hidden',
		}
	}


}

